import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { buildCollectionNotePagePath, buildSupplierRootPath } from "../config/routes";
import { useGetNoteSearchModalGetCollectionNoteLazyQuery } from "../generated/graphql";
import { TextField } from "./form/TextField";
import { LoadingSpinner } from "./LoadingSpinner";

export const NOTE_SEARCH_MODAL_GET_NOTE = gql`
  query GetNoteSearchModalGetCollectionNote($referenceId: Int!) {
    collectionNote(where: { referenceId: $referenceId }) {
      id
      referenceId
      supplier {
        id
        slug
        name
      }
    }
  }
`;

interface NoteSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NoteSearchFormSchema = yup.object().shape({
  collectionNoteID: yup.string().required(),
});

export const NoteSearchModal = (props: NoteSearchModalProps) => {
  const [
    getCollectionNote,
    collectionNoteQuery,
  ] = useGetNoteSearchModalGetCollectionNoteLazyQuery();

  const onClose = () => {
    if (!collectionNoteQuery.refetch) {
      props.onClose();
      return;
    }

    // todo: fix hacky way of reseting the state
    collectionNoteQuery.refetch({ referenceId: 0 });
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Note search</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={{
            collectionNoteID: "",
          }}
          validationSchema={NoteSearchFormSchema}
          onSubmit={async (values) => {
            getCollectionNote({
              variables: { referenceId: parseInt(values.collectionNoteID, 10) },
            });
          }}
        >
          {() => {
            return (
              <Form>
                <ModalBody>
                  <Stack spacing={4} pb={4}>
                    <Box>
                      <TextField name="collectionNoteID" label="Note ID" />
                    </Box>

                    <Flex justifyContent="flex-end">
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isDisabled={collectionNoteQuery.loading}
                        isLoading={collectionNoteQuery.loading}
                      >
                        Search
                      </Button>
                    </Flex>

                    <Flex justifyContent="center">
                      {!collectionNoteQuery.called ? null : collectionNoteQuery.loading ? (
                        <LoadingSpinner />
                      ) : collectionNoteQuery.error ? (
                        <Stack spacing={4}>
                          <Text>Something went wrong whilst searching for the supplier</Text>
                        </Stack>
                      ) : !collectionNoteQuery.data || !collectionNoteQuery.data.collectionNote ? (
                        <Text>No Note was found matching that ID</Text>
                      ) : (
                        <MatchedSupplier
                          name={collectionNoteQuery.data.collectionNote.supplier.slug}
                          slug={collectionNoteQuery.data.collectionNote.supplier.slug}
                          collectionNoteId={collectionNoteQuery.data.collectionNote.id}
                          onNavigate={onClose}
                        />
                      )}
                    </Flex>
                  </Stack>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

interface MatchedSupplierProps {
  name: string;
  slug: string;
  collectionNoteId: string;
  onNavigate: () => void;
}

const MatchedSupplier = (props: MatchedSupplierProps) => {
  const navigate = useNavigate();

  return (
    <SimpleGrid columns={2} columnGap={4}>
      <Box>
        <Button
          onClick={() => {
            navigate(buildSupplierRootPath(props.slug));
            props.onNavigate();
          }}
        >
          Supplier: {props.name}
        </Button>
      </Box>

      <Box>
        <Button
          as={Link}
          to={buildCollectionNotePagePath(props.slug, props.collectionNoteId)}
          colorScheme="blue"
          onClick={props.onNavigate}
        >
          Go to Note
        </Button>
      </Box>
    </SimpleGrid>
  );
};
