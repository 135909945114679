import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { SelectField } from "./form/Select";
import { TextField } from "./form/TextField";

interface Container {
  id: string;
  name: string;
  type: string;
}

const SupplierContainerFormSchema = yup.object().shape({
  containerId: yup.string().required(),
  quantity: yup.number().required(),
});

interface FormValues {
  containerId: string;
  quantity: string;
}

interface SupplierContainerFormProps {
  initialValues?: FormValues;
  containers: Container[];
  onSubmit: (values: FormValues) => Promise<void>;
}

export const SupplierContainerForm: React.FC<SupplierContainerFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        containerId: "",
        quantity: "",
      };
    }

    return props.initialValues;
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SupplierContainerFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <SelectField name="containerId" label="Container">
                  <option value=""></option>;
                  {props.containers.map((container) => {
                    return (
                      <option key={container.id} value={container.id}>
                        {container.name} ({container.type})
                      </option>
                    );
                  })}
                </SelectField>
              </Box>

              <Box>
                <TextField name="quantity" label="Quantity" />
              </Box>
            </Grid>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
