import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { AppProviders } from "./AppProviders";
import LogRocketFuzzySearchSanitizer from "./utils/logrocket-fuzzy-search-sanitizer";

const { requestSanitizer, responseSanitizer } = LogRocketFuzzySearchSanitizer.setup([
  "password",
  "apiKey",
  "token",
  "refreshToken",
]);

if (process.env.NODE_ENV === "production") {
  if (process.env.REACT_APP_LOG_ROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
    });
    setupLogRocketReact(LogRocket);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
);
