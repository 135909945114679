import { Heading, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import { LocationAwareSupplierPicker } from "../components/LocationAwareSupplierPicker";
import { Page } from "../components/Page";

export const SupplierSelectionPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>WMS</title>
      </Helmet>
      <Page>
        <Stack spacing={4} justifyContent="center" alignItems="center" height="100%">
          <Heading size="lg">Select a supplier to get started</Heading>
          <LocationAwareSupplierPicker />
        </Stack>
      </Page>
    </Fragment>
  );
};
