import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { TextField } from "./form/TextField";

const EWCCodeFormSchema = yup.object().shape({
  code: yup.string().required(),
  product: yup.string().required(),
});

interface FormValues {
  code: string;
  product: string;
}

interface EWCCodeFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const EWCCodeForm: React.FC<EWCCodeFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        code: "",
        product: "",
      };
    }

    return props.initialValues;
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EWCCodeFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="code" label="Code" />
              </Box>
              <Box>
                <TextField name="product" label="Product" />
              </Box>
            </Grid>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
