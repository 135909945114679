import { gql } from "@apollo/client";
import { Box, Button, ButtonGroup, Grid, Stack, Text, useToast } from "@chakra-ui/react";
import { toDataURL as QRCodeToDataURL } from "qrcode";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedDate, FormattedTime } from "react-intl";
import { Link, Navigate } from "react-router-dom";

import { Collapsable, CollapseableToggle } from "../../components/Collapsable";
import { CollectionNoteLines } from "../../components/CollectionNoteLines";
import { DataField } from "../../components/DataField";
import { FinancialFigure } from "../../components/FinancialFigure";
import { EditIcon, PrinterIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { Status } from "../../components/Status";
import { buildSupplierRootPath, RouteParams } from "../../config/routes";
import { useSupplier } from "../../context/SupplierContext";
import {
  useGetCollectionNotePdfLazyQuery,
  useGetCollectionNoteQuery,
  useMarkCollectionNoteAsPrintedMutation,
  useToggleCollectionNoteClosedStatusMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const COLLECTION_NOTES_PAGE_GET_NOTE = gql`
  query GetCollectionNote($collectionNoteId: String!) {
    collectionNote(where: { id: $collectionNoteId }) {
      id
      linesCount
      creationDate
      printed
      printedAt
      net
      vat
      gross
      invoiced
      closed
      displayReference

      collectionNoteLines(orderBy: { createdAt: asc }) {
        id
        quantity
        description
        invoiced
        value
        vat
        total
        ewcCode {
          id
        }
        nominalCode {
          id
        }
        invoice {
          id
        }
      }

      supplierAddress {
        id
        type
        siteName
        building
        postalCode
      }
    }

    ewcCodes(orderBy: { code: asc }) {
      id
      code
      product
    }

    nominalCodes(orderBy: { code: asc }) {
      id
      code
    }
  }
`;

export const COLLECTION_NOTES_PAGE_GET_COLLECTION_NOTE_PDF = gql`
  query GetCollectionNotePDF($collectionNoteId: String!) {
    collectionNote(where: { id: $collectionNoteId }) {
      displayReference
      pdf
    }
  }
`;

export const COLLECTION_NOTES_PAGE_MARK_AS_PRINTED = gql`
  mutation MarkCollectionNoteAsPrinted($collectionNoteId: String!) {
    markCollectionNoteAsPrinted(input: { collectionNoteId: $collectionNoteId }) {
      id
      printed
    }
  }
`;

export const CollectionNotePage = () => {
  const toast = useToast();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const collectionNoteId = useSingleParam(RouteParams.CollectionNoteId, { required: true });

  const { supplier } = useSupplier();

  const { loading, error, data } = useGetCollectionNoteQuery({
    variables: { collectionNoteId: collectionNoteId },
  });
  const [markAsPrinted] = useMarkCollectionNoteAsPrintedMutation({
    refetchQueries: ["GetCollectionNote"],
  });
  const [getCollectionNotePdf, { loading: pdfLoading }] = useGetCollectionNotePdfLazyQuery({
    variables: { collectionNoteId },
    onCompleted: (data) => {
      if (!data.collectionNote) {
        toast({ title: "Something went wrong whilst downloading the PDF", status: "error" });
        return;
      }

      const { pdf: pdfContent, displayReference } = data.collectionNote;

      if (!pdfContent) {
        toast({ title: "Something went wrong whilst downloading the PDF", status: "error" });
        return;
      }

      const a = document.createElement("a");
      a.href = pdfContent;
      a.download = `Collection Note ${displayReference}.pdf`;
      a.click();

      markAsPrinted({
        variables: {
          collectionNoteId: collectionNoteId,
        },
      });
    },
    onError: (e) => {
      console.log(e);
      const errMessage = e.message ?? "Something went wrong whilst downloading the PDF";
      toast({ title: errMessage, status: "error" });
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the collection note</Text>
      </Stack>
    );
  }

  if (!data || !data.collectionNote) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { collectionNote, ewcCodes, nominalCodes } = data;
  const { supplierAddress } = collectionNote;

  const collectionNoteLines = [];

  for (let line of collectionNote.collectionNoteLines ?? []) {
    collectionNoteLines.push({
      ...line,
      value: line.value ? line.value.toString() : "",
      vat: line.vat ? line.vat.toString() : "",
      total: line.total ? line.total.toString() : "",
      ewcCodeId: line.ewcCode?.id ?? "",
      nominalCodeId: line.nominalCode?.id ?? "",
      invoiceId: line.invoice?.id,
    });
  }

  const parts = [supplierAddress.siteName, supplierAddress.building, supplierAddress.postalCode]
    .filter(Boolean)
    .join(", ");
  const addressLabel = `${supplierAddress.type}: ${parts}`;

  const generateQRCode = async () => {
    const qrCodeData = await QRCodeToDataURL(collectionNoteId, {
      width: 512,
    });

    const a = document.createElement("a");
    a.href = qrCodeData;
    a.download = `collection-note-qr-${collectionNoteId}.png`;
    a.click();
  };

  const isEditable = !collectionNote.closed && !collectionNote.invoiced;

  return (
    <Fragment>
      <Helmet>
        <title>{`WMS | Collection Note ${collectionNote.displayReference}`}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1500px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>
            Collection Note{" "}
            <Text as="span" color="blue.500">
              {collectionNote.displayReference}
            </Text>
          </PageTitle>
          <ButtonGroup>
            <Button
              colorScheme="pink"
              leftIcon={<PrinterIcon height="16px" width="16px" mr={1} />}
              isDisabled={pdfLoading}
              onClick={() => getCollectionNotePdf()}
            >
              Print
            </Button>
            <Button colorScheme="purple" onClick={generateQRCode}>
              Generate QR Code
            </Button>
            <ToggleClosedStatusButton
              collectionNoteId={collectionNoteId}
              closed={collectionNote.closed}
            />
            {isEditable ? (
              <Button
                as={Link}
                to="edit"
                colorScheme="teal"
                leftIcon={<EditIcon height="16px" width="16px" mr={1} />}
              >
                Edit
              </Button>
            ) : null}
          </ButtonGroup>
        </Stack>

        <Collapsable>
          <Panel data-testid="collection-note-details">
            <Stack p={4} spacing={6}>
              <Grid gridTemplateColumns="repeat(4, auto)" gridRowGap={6}>
                <DataField title="Created">
                  <Text>
                    <FormattedDate
                      value={collectionNote.creationDate}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </Text>
                </DataField>

                <DataField title="Printed at">
                  <Text>
                    {collectionNote.printedAt ? (
                      <React.Fragment>
                        <FormattedDate
                          value={collectionNote.printedAt}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />{" "}
                        <FormattedTime value={collectionNote.printedAt} />
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Text>
                </DataField>

                <DataField title="Invoiced">
                  <Status active={Boolean(collectionNote.invoiced)} />
                </DataField>

                <DataField title="Closed">
                  <Status active={Boolean(collectionNote.closed)} />
                </DataField>

                <DataField title="Net">
                  <FinancialFigure
                    value={(collectionNote.net as unknown) as number}
                    currency={supplier.currency}
                  />
                </DataField>

                <DataField title="VAT">
                  <FinancialFigure
                    value={(collectionNote.vat as unknown) as number}
                    currency={supplier.currency}
                  />
                </DataField>

                <DataField title="Gross">
                  <FinancialFigure
                    value={(collectionNote.gross as unknown) as number}
                    currency={supplier.currency}
                  />
                </DataField>
              </Grid>

              <Box>
                <DataField title="Address">{addressLabel}</DataField>
              </Box>
            </Stack>
          </Panel>

          <CollapseableToggle />
        </Collapsable>

        <CollectionNoteLines
          isEditable={isEditable}
          collectionNoteId={collectionNoteId}
          collectionNoteLines={collectionNoteLines}
          ewcCodes={ewcCodes}
          nominalCodes={nominalCodes}
        />
      </Stack>
    </Fragment>
  );
};

export const TOGGLE_COLLECTION_NOTE_CLOSED = gql`
  mutation ToggleCollectionNoteClosedStatus($collectionNoteId: String!) {
    toggleCollectionNoteClosedStatus(input: { collectionNoteId: $collectionNoteId }) {
      id
      closed
    }
  }
`;

interface ToggleClosedStatusButtonProps {
  collectionNoteId: string;
  closed: boolean;
}

const ToggleClosedStatusButton: React.FC<ToggleClosedStatusButtonProps> = (props) => {
  const [toggleStatus, { loading }] = useToggleCollectionNoteClosedStatusMutation({
    refetchQueries: ["GetCollectionNote"],
  });

  return (
    <Button
      colorScheme="orange"
      isDisabled={loading}
      onClick={async () => {
        await toggleStatus({
          variables: {
            collectionNoteId: props.collectionNoteId,
          },
        });
      }}
    >
      Mark as {props.closed ? "Open" : "Closed"}
    </Button>
  );
};
