import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { InvoiceForm } from "../../components/InvoiceForm";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { buildInvoicePagePath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useInvoiceEditPageGetInvoiceQuery,
  useInvoiceEditPageUpdateInvoiceMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const GetInvoice = gql`
  query InvoiceEditPageGetInvoice($invoiceId: String!) {
    invoice(where: { id: $invoiceId }) {
      id
      reference
      supplierInvoiceNumber
      net
      vat
      gross
      comments
      completed
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation InvoiceEditPageUpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      id
    }
  }
`;

export const InvoiceEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);
  const invoiceId = useSingleParam(RouteParams.InvoiceId, { required: true });

  const [updateInvoice] = useInvoiceEditPageUpdateInvoiceMutation();

  const { loading, error, data } = useInvoiceEditPageGetInvoiceQuery({ variables: { invoiceId } });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the invoice</Text>
      </Stack>
    );
  }

  if (!data || !data.invoice) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { invoice } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Invoice {invoice.reference}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Invoice{" "}
            <Text as="span" color="blue.500">
              {invoice.reference}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <InvoiceForm
              initialValues={{
                reference: invoice.reference,
                supplierInvoiceNumber: invoice.supplierInvoiceNumber ?? "",
                net: invoice.net?.toString() ?? "",
                vat: invoice.vat?.toString() ?? "",
                gross: invoice.gross?.toString() ?? "",
                comments: invoice.comments,
              }}
              onSubmit={async (values) => {
                const { data } = await updateInvoice({
                  variables: {
                    input: {
                      ...values,
                      invoiceId,
                      net: values.net,
                      vat: values.vat,
                      gross: values.gross,
                    },
                  },
                });

                if (data?.updateInvoice?.id) {
                  navigate(buildInvoicePagePath(supplierSlug, data.updateInvoice.id));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
