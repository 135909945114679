import { gql } from "@apollo/client";
import { ActorRefFrom } from "xstate";
import { createModel } from "xstate/lib/model";

import { graphQLClient } from "../../api/graphql";
import { openReportURL } from "../generateReportConfig.machine";

export interface OutstandingInvoiceReportActor
  extends ActorRefFrom<typeof outstandingInvoiceReportMachine> {}

export const outstandingInvoiceReportModel = createModel(
  {
    reportURL: undefined as string | undefined,
    error: undefined as string | undefined,
  },
  {
    events: {
      skipStep: () => ({}),
      generateReport: () => ({}),
      reportGenerationSuccess: (value: string) => ({ data: { reportURL: value } }),
      reportGenerationFailure: (value: string) => ({ data: { error: value } }),
      restart: () => ({}),
    },
  }
);

export const outstandingInvoiceReportMachine = outstandingInvoiceReportModel.createMachine(
  {
    context: outstandingInvoiceReportModel.initialContext,
    initial: "showingReportConfigSummary",
    states: {
      showingReportConfigSummary: {
        initial: "idle",
        states: {
          idle: {
            on: {
              generateReport: {
                target: "generatingReport",
              },
            },
          },
          generatingReport: {
            invoke: {
              id: "generatingReport",
              src: "generateReport",
              onError: {
                target: "#failure",
              },
            },
            on: {
              reportGenerationSuccess: {
                target: "#reportGenerated",
                actions: outstandingInvoiceReportModel.assign({
                  reportURL: (_, ev) => ev.data.reportURL,
                }),
              },
              reportGenerationFailure: {
                target: "#failure",
                actions: outstandingInvoiceReportModel.assign({
                  error: (_, ev) => ev.data.error,
                }),
              },
            },
          },
        },
      },
      reportGenerated: {
        id: "reportGenerated",
        entry: "openReportURL",
      },
      failure: {
        id: "failure",
        on: {
          restart: {
            target: "showingReportConfigSummary",
            actions: outstandingInvoiceReportModel.assign(() => {
              return outstandingInvoiceReportModel.initialContext;
            }),
          },
        },
      },
    },
  },
  {
    actions: {
      openReportURL: async (ctx) => {
        console.log(ctx);
        if (!ctx.reportURL) {
          throw new Error("Response does not contain a url");
        }

        openReportURL(ctx.reportURL);
      },
    },
    services: {
      generateReport: (ctx, ev) => (cb) => {
        graphQLClient
          .query({
            query: GENERATE_IN_PROGRESS_INVOICE_REPORT,
          })
          .then((res) => {
            const reportUrl = res.data.outstandingInvoiceReport.url;

            cb(outstandingInvoiceReportModel.events.reportGenerationSuccess(reportUrl));
          })
          .catch((e) => {
            cb(outstandingInvoiceReportModel.events.reportGenerationFailure(e.message));
          });
      },
    },
  }
);

const GENERATE_IN_PROGRESS_INVOICE_REPORT = gql`
  query GetoutstandingInvoiceReportInfo {
    outstandingInvoiceReport {
      url
    }
  }
`;
