import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  LinkProps,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { Link as RRLink, useMatch } from "react-router-dom";

import {
  buildAddressesListPath,
  buildAdminContainerListPath,
  buildAdminEWCCodeListPath,
  buildAdminNominalCodeListPath,
  buildCollectionNotesListPath,
  buildContactsListPath,
  buildInvoicesListPath,
  buildQuotesListPath,
  buildSiteVisitListPath,
  buildSupplierContainersListPath,
  buildSupplierListPath,
  RouteParams,
} from "../config/routes";
import { useSingleParam } from "../hooks/useSingleParam";
import { GenerateReportConfigurationModal } from "./GenerateReportConfigurationModal";
import {
  BookIcon,
  BookmarkIcon,
  BoxIcon,
  BriefcaseIcon,
  FileTextIcon,
  MapPinIcon,
  MonitorIcon,
  RecycleIcon,
  SearchIcon,
  TruckIcon,
  UserIcon,
} from "./icons";
import { LocationAwareSupplierPicker } from "./LocationAwareSupplierPicker";
import { NoteSearchModal } from "./NoteSearchModal";

export const Sidebar: React.FC = ({ children, ...rest }) => {
  return (
    <Stack bg="#29303E" pt={6} {...rest}>
      {children}
    </Stack>
  );
};

interface SidebarLinkProps extends LinkProps {
  to: string;
  icon?: any;
  isDisabled?: boolean;
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({
  children,
  icon: Icon,
  isDisabled = false,
  ...props
}) => {
  return (
    <SidebarLinkContainer to={props.to} isDisabled={isDisabled}>
      <Stack isInline spacing={6} alignItems="center">
        {Icon && (
          <Box as="span">
            <Icon color="gray.400" />
          </Box>
        )}
        <Box>{children}</Box>
      </Stack>
    </SidebarLinkContainer>
  );
};

interface SidebarLinkContainerProps {
  to: string;
  isDisabled: boolean;
}

const SidebarLinkContainer: React.FC<SidebarLinkContainerProps> = ({
  isDisabled,
  to,
  children,
}) => {
  const match = useMatch({ path: `${to}/*` });

  if (isDisabled) {
    return (
      <Text
        as="span"
        fontSize="md"
        color="gray.400"
        textAlign="left"
        width="100%"
        mb={2}
        px={3}
        py={3}
        borderRadius="lg"
        cursor="not-allowed"
      >
        {children}
      </Text>
    );
  }

  return (
    <Link
      as={RRLink}
      to={to}
      display="block"
      fontSize="md"
      color="gray.200"
      textAlign="left"
      width="100%"
      mb={2}
      px={3}
      py={3}
      borderRadius="lg"
      bg={match ? "gray.900" : undefined}
      _hover={{
        textDecoration: "none",
        bg: "gray.800",
      }}
    >
      {children}
    </Link>
  );
};

interface SidebarReportButtonProps {
  icon: any;
  ReportConfigModal: any;
  children: React.ReactNode;
}

const SidebarReportButton = (props: SidebarReportButtonProps) => {
  const { icon: Icon, ReportConfigModal } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <React.Fragment>
      <Box mb={2}>
        <Button
          variant="ghost"
          width="100%"
          height="51px"
          fontSize="md"
          color="gray.200"
          fontWeight="normal"
          px={3}
          _hover={{ bg: "gray.800" }}
          _focus={{ bg: "gray.800" }}
          _active={{ bg: "gray.800" }}
          onClick={onOpen}
        >
          <Stack isInline spacing={6} width="100%" alignItems="center">
            {Icon && (
              <Box as="span">
                <Icon color="gray.400" />
              </Box>
            )}
            <Box>{props.children}</Box>
          </Stack>
        </Button>
      </Box>

      <ReportConfigModal isOpen={isOpen} onClose={onClose} />
    </React.Fragment>
  );
};

export const AppSidebar = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);

  const isSupplierActive = !!supplierSlug;

  return (
    <Sidebar data-testid="app-sidebar">
      <Flex justifyContent="center" pb={4} px={2}>
        <LocationAwareSupplierPicker />
      </Flex>
      <Stack height="calc(100vh - 100px)" overflowY="scroll" px={2} pt={1}>
        <SidebarLink
          to={buildAddressesListPath(supplierSlug)}
          icon={MapPinIcon}
          isDisabled={!isSupplierActive}
        >
          Addresses
        </SidebarLink>

        <SidebarLink
          to={buildContactsListPath(supplierSlug)}
          icon={UserIcon}
          isDisabled={!isSupplierActive}
        >
          Contacts
        </SidebarLink>

        <SidebarLink
          to={buildCollectionNotesListPath(supplierSlug)}
          icon={TruckIcon}
          isDisabled={!isSupplierActive}
        >
          Collection Notes
        </SidebarLink>

        <SidebarLink
          to={buildSupplierContainersListPath(supplierSlug)}
          icon={BoxIcon}
          isDisabled={!isSupplierActive}
        >
          Containers
        </SidebarLink>

        <SidebarLink
          to={buildInvoicesListPath(supplierSlug)}
          icon={FileTextIcon}
          isDisabled={!isSupplierActive}
        >
          Invoices
        </SidebarLink>

        <SidebarLink
          to={buildQuotesListPath(supplierSlug)}
          icon={BookIcon}
          isDisabled={!isSupplierActive}
        >
          Quotes
        </SidebarLink>

        <SidebarLink
          to={buildSiteVisitListPath(supplierSlug)}
          icon={BriefcaseIcon}
          isDisabled={!isSupplierActive}
        >
          Visits
        </SidebarLink>

        <Heading as="h3" color="white" fontSize="sm" pl={3}>
          Reports
        </Heading>

        <SidebarReportButton
          icon={FileTextIcon}
          ReportConfigModal={GenerateReportConfigurationModal}
        >
          Generate Report
        </SidebarReportButton>

        <SidebarReportButton icon={SearchIcon} ReportConfigModal={NoteSearchModal}>
          Note Search
        </SidebarReportButton>

        <Heading as="h3" color="white" fontSize="sm" pl={3}>
          Admin Zone
        </Heading>

        <SidebarLink to={buildSupplierListPath()} icon={MonitorIcon}>
          Suppliers
        </SidebarLink>

        <SidebarLink to={buildAdminContainerListPath()} icon={BoxIcon}>
          Containers
        </SidebarLink>

        <SidebarLink to={buildAdminEWCCodeListPath()} icon={RecycleIcon}>
          EWC Codes
        </SidebarLink>

        <SidebarLink to={buildAdminNominalCodeListPath()} icon={BookmarkIcon}>
          Nominal Codes
        </SidebarLink>
      </Stack>
    </Sidebar>
  );
};
