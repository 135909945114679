import { gql } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "../../../components/Page";
import { Panel } from "../../../components/Panel";
import { SupplierForm } from "../../../components/SupplierForm";
import { buildSupplierRootPath } from "../../../config/routes";
import { useSupplierCreationPageCreateSupplierMutation } from "../../../generated/graphql";

export const SUPPLIER_CREATION_PAGE_CREATE_SUPPLIER = gql`
  mutation SupplierCreationPageCreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      id
      name
      slug
      vat
      currency
    }
  }
`;

export const SupplierCreationPage = () => {
  const navigate = useNavigate();

  const [createSupplier] = useSupplierCreationPageCreateSupplierMutation({
    refetchQueries: ["GetSuppliers"],
  });

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Supplier</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Supplier</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierForm
              onSubmit={async (values) => {
                const { data } = await createSupplier({
                  variables: {
                    input: values,
                  },
                });

                if (data?.createSupplier?.slug) {
                  navigate(buildSupplierRootPath(data.createSupplier.slug));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
