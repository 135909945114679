import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { DatePickerField } from "./form/DatePickerField";
import { TextareaField } from "./form/TextareaField";
import { TextField } from "./form/TextField";

const SiteVisitFormSchema = yup.object().shape({
  visitDate: yup.date().required(),
  visitor: yup.string().required(),
  comments: yup.string(),
});

interface FormValues {
  visitDate: string;
  visitor: string;
  comments: string;
}

interface SiteVisitFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const SiteVisitForm: React.FC<SiteVisitFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        visitDate: format(new Date(), "yyyy-LL-dd"),
        visitor: "",
        comments: "",
      };
    }

    return {
      ...props.initialValues,
      visitDate: format(parseISO(props.initialValues.visitDate), "yyyy-LL-dd"),
    };
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SiteVisitFormSchema}
      onSubmit={async (values) => {
        const formattedValues: FormValues = {
          ...values,
          visitDate: formatISO(parseISO(values.visitDate)),
        };

        await props.onSubmit(formattedValues);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="visitor" label="Visited By" />
              </Box>

              <Box>
                <DatePickerField name="visitDate" label="Visited Date" />
              </Box>
            </Grid>

            <Box>
              <TextareaField name="comments" label="Comments" />
            </Box>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
