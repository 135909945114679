import { Currency } from "../generated/graphql";

const currencies = {
  [Currency.Gbp]: {
    code: Currency.Gbp,
    symbol: "£",
  },
  [Currency.Usd]: {
    code: Currency.Usd,
    symbol: "$",
  },
  [Currency.Eur]: {
    code: Currency.Eur,
    symbol: "€",
  },
};

export const currencyOptions = Object.keys(currencies) as Currency[];

export const getCurrencySymbol = (code: Currency) => {
  const currency = currencies[code];

  if (!currency) {
    throw new Error(`No currency found for the given currency code: ${code}`);
  }

  return currency.symbol;
};
