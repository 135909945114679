import { gql } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { Panel } from "../../components/Panel";
import { SiteVisitForm } from "../../components/SiteVisitForm";
import { buildEditSiteVisitPath, RouteParams } from "../../config/routes";
import { useCreateSiteVisitMutation } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CREATE_SITE_VISIT = gql`
  mutation CreateSiteVisit($input: CreateSiteVisitInput!) {
    createSiteVisit(input: $input) {
      id
    }
  }
`;

export const SiteVisitCreationPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [createSiteVisit] = useCreateSiteVisitMutation();

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Site Visit</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Site Visit</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SiteVisitForm
              onSubmit={async (values) => {
                const { data } = await createSiteVisit({
                  variables: {
                    input: {
                      ...values,
                      supplierSlug,
                    },
                  },
                });

                if (data?.createSiteVisit?.id) {
                  navigate(buildEditSiteVisitPath(supplierSlug, data.createSiteVisit.id));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
