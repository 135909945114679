import { useMsal } from "@azure/msal-react";
import LogRocket from "logrocket";
import * as React from "react";

const useLogRocketIdentify = () => {
  const { accounts } = useMsal();

  React.useEffect(() => {
    const [account] = accounts;

    if (!account) {
      return;
    }

    let identity: any = { email: account.username };
    if (account.name) {
      identity.name = account.name;
    }

    LogRocket.identify(account.localAccountId, identity);
  }, [accounts]);
};

export const Analytics: React.FC = () => {
  useLogRocketIdentify();

  return null;
};
