export class ApplicationError extends Error {}

export class UserFacingError extends ApplicationError {
  private internalMessage?: string;

  constructor(message: string, internalMessage: string | undefined = undefined) {
    super(message);

    this.internalMessage = internalMessage;
  }

  getInternalMessage() {
    return this.internalMessage;
  }
}
