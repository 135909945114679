import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { useGetSuppliersLazyQuery } from "../generated/graphql";
import { useDebounce } from "../hooks/useDebounce";
import { ChevronRight } from "./icons";

export const SUPPLIER_PICKER_GET_SUPPLIERS = gql`
  query GetSuppliers($input: ListSupplierInput!) {
    suppliers(input: $input) {
      id
      slug
      name
    }
  }
`;

export interface SupplierSelectProps {
  onSupplierSelected: (value: string) => void;
}

export const SupplierPicker: React.FC<SupplierSelectProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <React.Fragment>
      <Button
        data-testid="supplier-picker-button"
        colorScheme="teal"
        onClick={onOpen}
        rightIcon={<ChevronRight />}
      >
        Select a supplier
      </Button>

      <SupplierSearchModal
        isOpen={isOpen}
        onClose={onClose}
        onSupplierSelected={(value) => {
          props.onSupplierSelected(value);
          onClose();
        }}
      />
    </React.Fragment>
  );
};

interface SupplierSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSupplierSelected: (value: string) => void;
}

const SupplierSearchModal = (props: SupplierSearchModalProps) => {
  const [supplierSearchTerm, setSupplierSearchTerm] = React.useState("");
  const debouncedSupplierSearchTerm = useDebounce(supplierSearchTerm, 200);
  const [getSuppliers, { loading, data, error }] = useGetSuppliersLazyQuery();
  const initialRef = React.useRef<any>();

  React.useEffect(() => {
    if (debouncedSupplierSearchTerm.trim().length === 0) {
      return;
    }

    getSuppliers({
      variables: {
        input: {
          offset: 0,
          limit: 10,
          filters: [{ id: "name", value: debouncedSupplierSearchTerm }],
        },
      },
    });
  }, [debouncedSupplierSearchTerm, getSuppliers]);

  return (
    <Modal initialFocusRef={initialRef} size="lg" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Search for a supplier</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8} pb={8}>
            <Box>
              <FormLabel>
                Supplier name
                <Input
                  ref={initialRef}
                  data-testid="supplier-picker-input"
                  placeholder="alutrade"
                  size="lg"
                  value={supplierSearchTerm}
                  onChange={(e) => {
                    setSupplierSearchTerm(e.currentTarget.value);
                  }}
                />
              </FormLabel>
            </Box>

            <Flex justifyContent="center">
              {loading ? (
                <LoadingSpinner />
              ) : error ? (
                <Stack spacing={4}>
                  <Text>Something went wrong loading the suppliers</Text>
                </Stack>
              ) : (
                <List data-testid="supplier-picker-grid" width="100%" spacing={4}>
                  {data?.suppliers.map((supplier) => {
                    return (
                      <ListItem key={supplier.id} color="gray.700">
                        <Button
                          onClick={() => {
                            props.onSupplierSelected(supplier.slug);
                          }}
                        >
                          {supplier.name}
                        </Button>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
