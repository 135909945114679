import { gql } from "@apollo/client";
import { Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router";

import { CollectionNoteForm } from "../../components/CollectionNoteForm";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import {
  buildCollectionNotePagePath,
  buildSupplierRootPath,
  RouteParams,
} from "../../config/routes";
import {
  useCollectionNoteCreationPageGetDataQuery,
  useCreateCollectionNoteMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CREATE_COLLECTION_NOTES_PAGE_GET_DATA = gql`
  query CollectionNoteCreationPageGetData($supplierSlug: String!) {
    supplierAddresses(
      where: { supplier: { slug: { equals: $supplierSlug } }, effectiveTo: { equals: null } }
      orderBy: [{ type: desc }, { siteName: asc }]
    ) {
      id
      type
      siteName
      building
      postalCode
    }

    ewcCodes(orderBy: { code: asc }) {
      id
      code
      product
    }

    nominalCodes(orderBy: { code: asc }) {
      id
      code
    }
  }
`;

export const CREATE_COLLECTION_NOTE = gql`
  mutation CreateCollectionNote($input: CreateCollectionNoteInput!) {
    createCollectionNote(input: $input) {
      id
    }
  }
`;

export const CollectionNoteCreationPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [createCollectionNote] = useCreateCollectionNoteMutation();

  const { loading, error, data } = useCollectionNoteCreationPageGetDataQuery({
    variables: { supplierSlug },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the addresses</Text>
      </Stack>
    );
  }

  if (!data || !data.supplierAddresses || !data.ewcCodes || !data.nominalCodes) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { supplierAddresses, ewcCodes, nominalCodes } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Collection Note</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Collection Note</PageTitle>
        </Stack>

        <CollectionNoteForm
          addresses={supplierAddresses}
          ewcCodes={ewcCodes}
          nominalCodes={nominalCodes}
          onSubmit={async (values) => {
            const { data } = await createCollectionNote({
              variables: {
                input: {
                  ...values,
                  collectionNoteLines: values.collectionNoteLines.map((noteLine) => {
                    const { invoiced, ...line } = noteLine;

                    return line;
                  }),
                  supplierSlug,
                },
              },
            });

            if (data?.createCollectionNote?.id) {
              navigate(buildCollectionNotePagePath(supplierSlug, data.createCollectionNote.id));
              return;
            }

            console.log("TODO: handle this");
          }}
        />
      </Stack>
    </Fragment>
  );
};
