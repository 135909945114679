import { FormattedNumber } from "react-intl";

interface FinancialFigureProps {
  value: number;
  currency: string;
}

export const FinancialFigure = (props: FinancialFigureProps) => {
  return (
    <FormattedNumber
      value={props.value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currencyDisplay="narrowSymbol"
      currency={props.currency}
    />
  );
};
