import { gql } from "@apollo/client";
import { Box, ButtonGroup, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { DeleteWithConfirmationButton } from "../../components/DeleteWithConfirmationButton";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { SupplierContactForm } from "../../components/SupplierContactForm";
import { buildContactsListPath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useContactEditGetSupplierContactQuery,
  useContactEditUpdateSupplierContactMutation,
  useDeleteSupplierContactMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CONTACT_EDIT_PAGE_GET_SUPPLIER_CONTACT = gql`
  query ContactEditGetSupplierContact($contactId: String!) {
    supplierContact(where: { id: $contactId }) {
      id
      name
      email
      notes
      phoneNumber
    }
  }
`;

export const UPDATE_SUPPLIER_CONTACT = gql`
  mutation ContactEditUpdateSupplierContact($input: UpdateSupplierContactInput!) {
    updateSupplierContact(input: $input) {
      id
    }
  }
`;

export const DELETE_SUPPLIER_CONTACT = gql`
  mutation DeleteSupplierContact($input: DeleteSupplierContactInput!) {
    deleteSupplierContact(input: $input) {
      id
    }
  }
`;

export const ContactEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);
  const contactId = useSingleParam(RouteParams.ContactId, { required: true });

  const [updateContact] = useContactEditUpdateSupplierContactMutation();

  const [deleteContact] = useDeleteSupplierContactMutation({
    onCompleted: () => {
      navigate(buildContactsListPath(supplierSlug));
    },
  });

  const { loading, error, data } = useContactEditGetSupplierContactQuery({
    variables: { contactId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the contact</Text>
      </Stack>
    );
  }

  if (!data || !data.supplierContact) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { supplierContact } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Contact</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="900px">
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Contact{" "}
            <Text as="span" color="blue.500">
              {supplierContact.name}
            </Text>
          </PageTitle>
          <ButtonGroup>
            <DeleteWithConfirmationButton
              buttonTx="Delete"
              confirmationTx="Are you sure you want to delete this contact?"
              onConfirmed={() => {
                deleteContact({
                  variables: {
                    input: {
                      contactId: supplierContact.id,
                    },
                  },
                });
              }}
            />
          </ButtonGroup>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierContactForm
              initialValues={{
                name: supplierContact.name,
                email: supplierContact.email ?? "",
                notes: supplierContact.notes ?? "",
                phoneNumber: supplierContact.phoneNumber ?? "",
              }}
              onSubmit={async (values) => {
                const { data } = await updateContact({
                  variables: {
                    input: {
                      ...values,
                      supplierContactId: contactId,
                    },
                  },
                });

                if (data?.updateSupplierContact?.id) {
                  navigate(buildContactsListPath(supplierSlug));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
