import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';

type DatePickerFieldProps = FieldHookConfig<any> & {
  label: string;
};

export const DatePickerField: React.FC<DatePickerFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Input {...field} id={field.name} type="date" />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
