import { Box, Flex } from "@chakra-ui/react";
import { motion, Transition } from "framer-motion";
import React, { ReactNode, useMemo, useState } from "react";

import { ChevronDown } from "./icons";
import { Rotate } from "./Rotate";

const CollapseableContext = React.createContext({ active: false, toggle: () => {} });

export const useCollapseable = () => {
  return React.useContext(CollapseableContext);
};

export enum CollapsableVariant {
  Open = "open",
  Collapsed = "collapsed",
}

interface CollapsableProps {
  initialActive?: boolean;
  collapsedHeight?: string;
  transition?: Transition;
  children: ReactNode;
}

export const Collapsable = ({
  initialActive = false,
  collapsedHeight = "75px",
  transition = { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] },
  children,
}: CollapsableProps) => {
  const [active, setActive] = useState(initialActive);

  const contextValue = useMemo(() => {
    return {
      active: active,
      toggle: () => {
        setActive(!active);
      },
    };
  }, [active, setActive]);

  return (
    <CollapseableContext.Provider value={contextValue}>
      <Box position="relative">
        <Box overflow="hidden">
          <motion.div
            initial={CollapsableVariant.Collapsed}
            animate={active ? CollapsableVariant.Open : CollapsableVariant.Collapsed}
            exit={CollapsableVariant.Collapsed}
            variants={{
              [CollapsableVariant.Open]: { height: "auto" },
              [CollapsableVariant.Collapsed]: { height: collapsedHeight },
            }}
            transition={transition}
          >
            {children}
          </motion.div>
        </Box>
      </Box>
    </CollapseableContext.Provider>
  );
};

export const CollapseableToggle = () => {
  const { active, toggle } = useCollapseable();

  const size = 25;

  return (
    <Box position="absolute" bottom="-15px" left="0" width="100%" height={`${size}px`}>
      <Flex justifyContent="center" alignItems="center">
        <Box
          as={Flex}
          justifyContent="center"
          alignItems="center"
          height={`${size}px`}
          width={`${size}px`}
          borderRadius="full"
          bg="white"
          borderColor="gray.300"
          borderWidth="2px"
          boxShadow="sm"
          cursor="pointer"
          transition="background 200ms ease-in-out"
          _hover={{
            bg: "gray.50",
          }}
          onClick={toggle}
        >
          <Rotate active={active}>
            <ChevronDown stroke="teal.500" height={`${size - 10}px`} width={`${size - 10}px`} />
          </Rotate>
        </Box>
      </Flex>
    </Box>
  );
};
