import { ApolloProvider } from "@apollo/client";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { graphQLClient } from "./api/graphql";
import { loginRequest } from "./auth/authConfig";
import { Analytics } from "./components/Analytics";
import { Page } from "./components/Page";
import { convertParam, RouteParams } from "./config/routes";
import { SupplierProvider } from "./context/SupplierContext";
import { AddressCreationPage } from "./pages/Addresses/AddressCreationPage";
import { AddressEditPage } from "./pages/Addresses/AddressEditPage";
import { AddressListPage } from "./pages/Addresses/AddressListPage";
import { AddressPage } from "./pages/Addresses/AddressPage";
import { ContainerCreationPage } from "./pages/Admin/Containers/ContainerCreationPage";
import { ContainerEditPage } from "./pages/Admin/Containers/ContainerEditPage";
import { ContainerListPage } from "./pages/Admin/Containers/ContainerListPage";
import { EWCCodeCreationPage } from "./pages/Admin/EWCCodes/EWCCodeCreationPage";
import { EWCCodeEditPage } from "./pages/Admin/EWCCodes/EWCCodeEditPage";
import { EWCCodeListPage } from "./pages/Admin/EWCCodes/EWCCodeListPage";
import { NominalCodeCreationPage } from "./pages/Admin/NominalCodes/NominalCodeCreationPage";
import { NominalCodeEditPage } from "./pages/Admin/NominalCodes/NominalCodeEditPage";
import { NominalCodeListPage } from "./pages/Admin/NominalCodes/NominalCodeListPage";
import { SupplierCreationPage } from "./pages/Admin/Suppliers/SupplierCreationPage";
import { SupplierEditPage } from "./pages/Admin/Suppliers/SupplierEditPage";
import { SupplierListPage } from "./pages/Admin/Suppliers/SupplierListPage";
import { SupplierPage } from "./pages/Admin/Suppliers/SupplierPage";
import { CollectionNoteCreationPage } from "./pages/CollectionNotes/CollectionNoteCreationPage";
import { CollectionNoteEditPage } from "./pages/CollectionNotes/CollectionNoteEditPage";
import { CollectionNoteListPage } from "./pages/CollectionNotes/CollectionNoteListPage";
import { CollectionNotePage } from "./pages/CollectionNotes/CollectionNotePage";
import { ContactCreationPage } from "./pages/Contacts/ContactCreationPage";
import { ContactEditPage } from "./pages/Contacts/ContactEditPage";
import { ContactListPage } from "./pages/Contacts/ContactListPage";
import { InvoiceCollectionNoteMatchPage } from "./pages/Invoices/InvoiceCollectionNoteMatchPage";
import { InvoiceCreationPage } from "./pages/Invoices/InvoiceCreationPage";
import { InvoiceEditPage } from "./pages/Invoices/InvoiceEditPage";
import { InvoiceListPage } from "./pages/Invoices/InvoiceListPage";
import { InvoicePage } from "./pages/Invoices/InvoicePage";
import { QuoteCreationPage } from "./pages/Quotes/QuoteCreationPage";
import { QuoteEditPage } from "./pages/Quotes/QuoteEditPage";
import { QuoteListPage } from "./pages/Quotes/QuoteListPage";
import { QuotePage } from "./pages/Quotes/QuotePage";
import { SiteVisitCreationPage } from "./pages/SiteVisits/SiteVisitCreationPage";
import { SiteVisitEditPage } from "./pages/SiteVisits/SiteVisitEditPage";
import { SiteVisitsListPage } from "./pages/SiteVisits/SiteVisitListPage";
import { SupplierContainerCreationPage } from "./pages/SupplierContainers/SupplierContainerCreationPage";
import { SupplierContainerEditPage } from "./pages/SupplierContainers/SupplierContainerEditPage";
import { SupplierContainerListPage } from "./pages/SupplierContainers/SupplierContainerListPage";
import { SupplierSelectionPage } from "./pages/SupplierSelectionPage";

export const AuthenticatedApp = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <ApolloProvider client={graphQLClient}>
      {process.env.NODE_ENV === "development" ? (
        <AppRoutes />
      ) : (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <AppRoutes />
          <Analytics />
        </MsalAuthenticationTemplate>
      )}
    </ApolloProvider>
  );
};

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <SupplierSelectionPage />,
    },
    {
      path: `/supplier/${convertParam(RouteParams.SupplierSlug)}`,
      element: <SupplierPageContainer />,
      children: [
        {
          path: "/",
          element: <Navigate to="addresses" />,
        },
        {
          path: "/addresses",
          children: [
            { path: "/", element: <AddressListPage /> },
            { path: "/new", element: <AddressCreationPage /> },
            { path: `/${convertParam(RouteParams.AddressId)}`, element: <AddressPage /> },
            { path: `/${convertParam(RouteParams.AddressId)}/edit`, element: <AddressEditPage /> },
          ],
        },
        {
          path: "/collection-notes",
          children: [
            { path: "/", element: <CollectionNoteListPage /> },
            { path: "/new", element: <CollectionNoteCreationPage /> },
            {
              path: `/${convertParam(RouteParams.CollectionNoteId)}`,
              element: <CollectionNotePage />,
            },
            {
              path: `/${convertParam(RouteParams.CollectionNoteId)}/edit`,
              element: <CollectionNoteEditPage />,
            },
          ],
        },
        {
          path: "/contacts",
          children: [
            { path: "/", element: <ContactListPage /> },
            { path: "/new", element: <ContactCreationPage /> },
            // { path: `/${convertParam(RouteParams.ContactId)}`, element: <AddressPage /> },
            { path: `/${convertParam(RouteParams.ContactId)}/edit`, element: <ContactEditPage /> },
          ],
        },
        {
          path: "/containers",
          children: [
            { path: "/", element: <SupplierContainerListPage /> },
            { path: "/new", element: <SupplierContainerCreationPage /> },
            {
              path: `/${convertParam(RouteParams.SupplierContainerId)}/edit`,
              element: <SupplierContainerEditPage />,
            },
          ],
        },
        {
          path: "/invoices",
          children: [
            { path: "/", element: <InvoiceListPage /> },
            { path: "/new", element: <InvoiceCreationPage /> },
            { path: `/${convertParam(RouteParams.InvoiceId)}`, element: <InvoicePage /> },
            { path: `/${convertParam(RouteParams.InvoiceId)}/edit`, element: <InvoiceEditPage /> },
            {
              path: `/${convertParam(RouteParams.InvoiceId)}/match`,
              element: <InvoiceCollectionNoteMatchPage />,
            },
          ],
        },
        {
          path: "/quotes",
          children: [
            { path: "/", element: <QuoteListPage /> },
            { path: "/new", element: <QuoteCreationPage /> },
            { path: `/${convertParam(RouteParams.QuoteId)}`, element: <QuotePage /> },
            { path: `/${convertParam(RouteParams.QuoteId)}/edit`, element: <QuoteEditPage /> },
          ],
        },
        {
          path: "/visits",
          children: [
            { path: "/", element: <SiteVisitsListPage /> },
            { path: "/new", element: <SiteVisitCreationPage /> },
            {
              path: `/${convertParam(RouteParams.SiteVisitId)}/edit`,
              element: <SiteVisitEditPage />,
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to="addresses" />,
        },
      ],
    },

    {
      path: "/admin",
      element: <PageContainer />,
      children: [
        {
          path: "/supplier",
          children: [
            { path: "/", element: <SupplierListPage /> },
            { path: "/new", element: <SupplierCreationPage /> },
            { path: `/${convertParam(RouteParams.SupplierSlug)}`, element: <SupplierPage /> },
            {
              path: `/${convertParam(RouteParams.SupplierSlug)}/edit`,
              element: <SupplierEditPage />,
            },
          ],
        },
        {
          path: "/container",
          children: [
            { path: "/", element: <ContainerListPage /> },
            { path: "/new", element: <ContainerCreationPage /> },
            {
              path: `/${convertParam(RouteParams.ContainerId)}/edit`,
              element: <ContainerEditPage />,
            },
          ],
        },
        {
          path: "/ewc-codes",
          children: [
            { path: "/", element: <EWCCodeListPage /> },
            { path: "/new", element: <EWCCodeCreationPage /> },
            {
              path: `/${convertParam(RouteParams.EWCCodeId)}/edit`,
              element: <EWCCodeEditPage />,
            },
          ],
        },
        {
          path: "/nominal-codes",
          children: [
            { path: "/", element: <NominalCodeListPage /> },
            { path: "/new", element: <NominalCodeCreationPage /> },
            {
              path: `/${convertParam(RouteParams.NominalCodeId)}/edit`,
              element: <NominalCodeEditPage />,
            },
          ],
        },
      ],
    },

    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return routes;
};

const PageContainer = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  );
};

const SupplierPageContainer = () => {
  return (
    <SupplierProvider>
      <Page>
        <Outlet />
      </Page>
    </SupplierProvider>
  );
};
