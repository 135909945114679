import { Box, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { Column, useFilters, usePagination, useSortBy, useTable } from "react-table";

import { ChevronDown, ChevronUp } from "../icons";
import { Pagination } from "../Pagination";
import { Panel } from "../Panel";
import { ColumnMenu } from "./ColumnMenu";

type Mode = "default" | "compact";

interface SortBy {
  id: string;
  desc: boolean;
}

export interface Filter {
  id: string;
  value: string;
}

export type Filters = Filter[];

export interface FetchDataCallbackOptions {
  sortBy: SortBy[];
  pageIndex: number;
  pageSize: number;
  filters: Filters;
}

interface TableProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  "data-testid"?: string;
  instanceProps?: Record<string, any>;
  // The height of the table should be decided where the table is rendered not by the table itself
  // implementing this hack to get an MVP shipped
  tableHeight?: string;
  mode?: Mode;
  sortBy?: SortBy[];
  filters?: Filters;
  pageCount: number;
  defaultPageIndex: number;
  defaultPageSize: number;
  fetchData: (opts: FetchDataCallbackOptions) => void;
}

export const Table: React.FC<TableProps<any>> = ({ fetchData, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore react-table doesn't support TS with plugins
    pageOptions,
    // @ts-ignore react-table doesn't support TS with plugins
    gotoPage,
    state: {
      // @ts-ignore react-table doesn't support TS with plugins
      sortBy,
      // @ts-ignore react-table doesn't support TS with plugins
      pageIndex,
      // @ts-ignore react-table doesn't support TS with plugins
      pageSize,
      // @ts-ignore react-table doesn't support TS with plugins
      filters,
    },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      // @ts-ignore react-table doesn't support TS with plugins
      manualSortBy: true,
      manualPagination: true,
      manualFilters: true,
      initialState: {
        // @ts-ignore react-table doesn't support TS with plugins
        pageSize: props.defaultPageSize,
        pageIndex: props.defaultPageIndex,
        filters: props.filters,
      },
      pageCount: props.pageCount,
      // @ts-ignore react-table doesn't support TS with plugins
      sortBy: props.sortBy,
      ...props.instanceProps,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const fetchIdRef = React.useRef(0);

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    const fetchId = ++fetchIdRef.current;

    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        fetchData({ sortBy, pageIndex, pageSize, filters });
      }
    }, 300);
  }, [fetchData, sortBy, pageIndex, pageSize, filters]);

  // reset the page index to 0 when sorting changes
  React.useEffect(() => {
    gotoPage(0);
  }, [gotoPage, sortBy]);

  const mode = props.mode ?? "default";

  return (
    <Stack spacing={6}>
      <Panel height={props.tableHeight ?? "65vh"} overflowY="auto">
        <StyledTable {...getTableProps()} data-testid={props["data-testid"]}>
          <StyledTableHeader>
            {headerGroups.map((headerGroup) => (
              <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <StyledTableHeaderCell key={column.id} mode={mode}>
                      <Flex direction="row" gridColumnGap={1}>
                        <ColumnMenu
                          isSortable={!(column as any).disableSortBy}
                          isSortedDesc={(column as any).isSortedDesc}
                          isFilterable={!(column as any).disableFilters}
                          filterValue={(column as any).filterValue ?? ""}
                          setFilterValue={(column as any).setFilter}
                          toggleSortBy={(column as any).toggleSortBy}
                        >
                          {column.render("Header")}
                        </ColumnMenu>

                        <span>
                          {(column as any).isSorted ? (
                            (column as any).isSortedDesc ? (
                              <ChevronDown />
                            ) : (
                              <ChevronUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </StyledTableHeaderCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </StyledTableHeader>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <StyledTableBodyCell
                        {...cell.getCellProps()}
                        width={cell.column.width}
                        mode={mode}
                      >
                        {cell.render("Cell")}
                      </StyledTableBodyCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </tbody>
        </StyledTable>
      </Panel>

      <Pagination
        currentPage={pageIndex + 1}
        pageSize={pageSize}
        totalCount={pageOptions.length}
        onGoToPage={(pageNumber) => {
          gotoPage(pageNumber - 1);
        }}
      />
    </Stack>
  );
};

export const StyledTable: React.FC = (props) => {
  return <Box width="100%" {...props} as="table" />;
};

export const StyledTableHeader: React.FC = (props) => {
  return <Box bg="gray.50" {...props} as="thead" />;
};

export const StyledTableRow: React.FC = (props) => {
  return <Box borderBottomWidth="1px" borderBottomColor="gray.200" {...props} as="tr" />;
};

interface StyledTableHeaderCellProps {
  mode: Mode;
}

export const StyledTableHeaderCell: React.FC<StyledTableHeaderCellProps> = (props) => {
  return (
    <Box
      bg="gray.50"
      textTransform="capitalize"
      fontSize="sm"
      textAlign="left"
      color="#7C7F88"
      pl={props.mode === "compact" ? 2 : 4}
      py={props.mode === "compact" ? 2 : 5}
      position="sticky"
      top={0}
      zIndex={2}
      {...props}
      as="th"
    />
  );
};

interface StyledTableBodyCellProps {
  width?: string | number;
  mode?: Mode;
}

export const StyledTableBodyCell: React.FC<StyledTableBodyCellProps> = (props) => {
  return (
    <Box
      fontSize="sm"
      textAlign="left"
      px={props.mode === "compact" ? 2 : 4}
      py={props.mode === "compact" ? 2 : 4}
      {...props}
      as="td"
    />
  );
};

// interface DefaultColumnFilterProps {
//   column: {
//     filterValue: string;
//     setFilter: (value: string | undefined) => void;
//   };
// }

// function DefaultColumnFilter({ column: { filterValue, setFilter } }: DefaultColumnFilterProps) {
//   return (
//     <input
//       value={filterValue || ""}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//     />
//   );
// }
