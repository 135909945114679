import { gql } from "@apollo/client";
import { Stack, Text, useToast } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router";

import { CollectionNoteForm } from "../../components/CollectionNoteForm";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import {
  buildCollectionNotePagePath,
  buildSupplierRootPath,
  RouteParams,
} from "../../config/routes";
import {
  useCollectionNoteEditPageGetDataQuery,
  useCollectionNoteEditPageUpdateCollectionNoteMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const COLLECTION_NOTES_PAGE_GET_COLLECTION_NOTE = gql`
  query CollectionNoteEditPageGetData($collectionNoteId: String!, $supplierSlug: String!) {
    collectionNote(where: { id: $collectionNoteId }) {
      id
      displayReference
      creationDate
      return
      supplierAddress {
        id
      }
      collectionNoteLines(orderBy: { createdAt: asc }) {
        id
        quantity
        description
        invoiced
        value
        vat
        total
        ewcCode {
          id
        }
        nominalCode {
          id
        }
      }
    }

    supplierAddresses(
      where: { supplier: { slug: { equals: $supplierSlug } }, effectiveTo: { equals: null } }
      orderBy: [{ type: desc }, { siteName: asc }]
    ) {
      id
      type
      siteName
      building
      postalCode
    }

    ewcCodes(orderBy: { code: asc }) {
      id
      code
      product
    }

    nominalCodes(orderBy: { code: asc }) {
      id
      code
    }
  }
`;

export const UPDATE_COLLECTION_NOTE = gql`
  mutation CollectionNoteEditPageUpdateCollectionNote($input: UpdateCollectionNoteInput!) {
    updateCollectionNote(input: $input) {
      id
    }
  }
`;

export const CollectionNoteEditPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const collectionNoteId = useSingleParam(RouteParams.CollectionNoteId, { required: true });

  const [updateCollectionNote] = useCollectionNoteEditPageUpdateCollectionNoteMutation({
    onCompleted: () => {
      toast({ title: "Note saved", status: "success" });
    },
  });

  const { loading, error, data } = useCollectionNoteEditPageGetDataQuery({
    variables: { collectionNoteId, supplierSlug },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the collection note</Text>
      </Stack>
    );
  }

  if (
    !data ||
    !data.collectionNote ||
    !data.supplierAddresses ||
    !data.ewcCodes ||
    !data.nominalCodes
  ) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { collectionNote, supplierAddresses, ewcCodes, nominalCodes } = data;

  return (
    <Fragment>
      <Helmet>
        <title>{`WMS | Collection Note ${collectionNote.displayReference}`}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Collection Note{" "}
            <Text as="span" color="blue.500">
              {collectionNote.displayReference}
            </Text>
          </PageTitle>
        </Stack>

        <CollectionNoteForm
          initialValues={{
            supplierAddressId: collectionNote.supplierAddress.id,
            creationDate: collectionNote.creationDate,
            isReturn: collectionNote.return,
            collectionNoteLines: collectionNote.collectionNoteLines.map((line) => {
              return {
                id: line.id,
                quantity: line.quantity,
                description: line.description,
                invoiced: line.invoiced,
                value: line.value ? line.value.toString() : "",
                vat: line.vat ? line.vat.toString() : "",
                total: line.total ? line.total.toString() : "",
                ewcCodeId: line.ewcCode ? line.ewcCode.id : undefined,
                nominalCodeId: line.nominalCode ? line.nominalCode.id : undefined,
              };
            }),
          }}
          addresses={supplierAddresses}
          ewcCodes={ewcCodes}
          nominalCodes={nominalCodes}
          onSubmit={async (values) => {
            const { data } = await updateCollectionNote({
              variables: {
                input: {
                  ...values,
                  collectionNoteLines: values.collectionNoteLines.map((noteLine) => {
                    const { invoiced, ...line } = noteLine;

                    return line;
                  }),
                  collectionNoteId,
                },
              },
            });

            if (data?.updateCollectionNote?.id) {
              navigate(buildCollectionNotePagePath(supplierSlug, data.updateCollectionNote.id));
              return;
            }

            console.log("TODO: handle this");
          }}
        />
      </Stack>
    </Fragment>
  );
};
