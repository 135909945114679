import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";

import { TrashIcon } from "./icons";

interface DeleteWithConfirmationButtonProps {
  buttonTx: string;
  confirmationTx: string;
  onConfirmed: () => void;
}

export const DeleteWithConfirmationButton = (props: DeleteWithConfirmationButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover isOpen={isOpen} initialFocusRef={initialFocusRef} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button colorScheme="red" leftIcon={<TrashIcon height="16px" width="16px" mr={1} />}>
          {props.buttonTx}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {props.confirmationTx}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button colorScheme="red" ref={initialFocusRef} onClick={props.onConfirmed}>
              Yes
            </Button>
            <Button colorScheme="gray" onClick={onClose}>
              No
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
