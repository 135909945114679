export enum RouteParams {
  SupplierSlug = "supplierSlug",
  QuoteId = "quoteId",
  InvoiceId = "invoiceId",
  AddressId = "addressId",
  CollectionNoteId = "collectionNoteId",
  ContactId = "contactId",
  SiteVisitId = "siteVisitId",
  ContainerId = "containerId",
  SupplierContainerId = "supplierContainerId",
  EWCCodeId = "ewcCodeId",
  NominalCodeId = "nominalCodeId",
}

export const convertParam = (param: string) => `:${param}`;

export const buildSupplierRootPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}`;
};

export const buildQuotesListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/quotes`;
};

export const buildNewQuotePath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/quotes/new`;
};

export const buildQuotePagePath = (supplierSlug: string, quoteId: string) => {
  return `/supplier/${supplierSlug}/quotes/${quoteId}`;
};

export const buildAddressesListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/addresses`;
};

export const buildNewAddressPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/addresses/new`;
};

export const buildAddressPagePath = (supplierSlug: string, addressId: string) => {
  return `/supplier/${supplierSlug}/addresses/${addressId}`;
};

export const buildEditAddressPath = (supplierSlug: string, addressId: string) => {
  return `/supplier/${supplierSlug}/addresses/${addressId}/edit`;
};

export const buildCollectionNotesListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/collection-notes`;
};

export const buildNewCollectionNotePath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/collection-notes/new`;
};

export const buildCollectionNotePagePath = (supplierSlug: string, collectionNoteId: string) => {
  return `/supplier/${supplierSlug}/collection-notes/${collectionNoteId}`;
};

export const buildEditCollectionNotePath = (supplierSlug: string, collectionNoteId: string) => {
  return `/supplier/${supplierSlug}/collection-notes/${collectionNoteId}/edit`;
};

export const buildContactsListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/contacts`;
};

export const buildNewContactPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/contacts/new`;
};

export const buildEditContactPagePath = (supplierSlug: string, contactId: string) => {
  return `/supplier/${supplierSlug}/contacts/${contactId}/edit`;
};

export const buildSupplierContainersListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/containers`;
};

export const buildNewSupplierContainerPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/containers/new`;
};

export const buildEditSupplierContainerPath = (supplierSlug: string, containerId: string) => {
  return `/supplier/${supplierSlug}/containers/${containerId}/edit`;
};

export const buildInvoicesListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/invoices`;
};

export const buildNewInvoicePath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/invoices/new`;
};

export const buildInvoicePagePath = (supplierSlug: string, invoiceId: string) => {
  return `/supplier/${supplierSlug}/invoices/${invoiceId}`;
};

export const buildInvoiceMatchPagePath = (supplierSlug: string, invoiceId: string) => {
  return `/supplier/${supplierSlug}/invoices/${invoiceId}/match`;
};

export const buildSiteVisitListPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/visits`;
};

export const buildNewSiteVisitPath = (supplierSlug: string) => {
  return `/supplier/${supplierSlug}/visits/new`;
};

export const buildEditSiteVisitPath = (supplierSlug: string, siteVisitId: string) => {
  return `/supplier/${supplierSlug}/visits/${siteVisitId}/edit`;
};

export const buildSupplierListPath = () => {
  return `/admin/supplier`;
};

export const buildNewSupplierPath = () => {
  return `/admin/supplier/new`;
};

export const buildSupplierPagePath = (supplierSlug: string) => {
  return `/admin/supplier/${supplierSlug}`;
};

export const buildSupplierEditPath = (supplierSlug: string) => {
  return `/admin/supplier/${supplierSlug}/edit`;
};

export const buildAdminContainerListPath = () => {
  return `/admin/container`;
};

export const buildAdminNewContainerPath = () => {
  return `/admin/container/new`;
};

export const buildAdminEditContainerPath = (containerId: string) => {
  return `/admin/container/${containerId}/edit`;
};

export const buildAdminEWCCodeListPath = () => {
  return `/admin/ewc-codes`;
};

export const buildAdminNewEWCCodePath = () => {
  return `/admin/ewc-codes/new`;
};

export const buildAdminEditEWCCodePath = (ewcCodeId: string) => {
  return `/admin/ewc-codes/${ewcCodeId}/edit`;
};

export const buildAdminNominalCodeListPath = () => {
  return `/admin/nominal-codes`;
};

export const buildAdminNewNominalCodePath = () => {
  return `/admin/nominal-codes/new`;
};

export const buildAdminEditNominalCodePath = (nominalCodeId: string) => {
  return `/admin/nominal-codes/${nominalCodeId}/edit`;
};
