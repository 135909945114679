import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { SupplierContainerForm } from "../../components/SupplierContainerForm";
import { buildEditSupplierContainerPath, RouteParams } from "../../config/routes";
import {
  useCreateSupplierContainerMutation,
  useCreateSupplierGetContainersQuery,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CREATE_SUPPLIER_GET_CONTAINERS = gql`
  query CreateSupplierGetContainers {
    containers(orderBy: { name: asc }) {
      id
      name
      type
    }
  }
`;

export const CREATE_SUPPLIER_CONTAINER = gql`
  mutation CreateSupplierContainer($input: CreateSupplierContainerInput!) {
    createSupplierContainer(input: $input) {
      id
    }
  }
`;

export const SupplierContainerCreationPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [createSupplierContainerMutation] = useCreateSupplierContainerMutation();

  const { loading, error, data } = useCreateSupplierGetContainersQuery();

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the containers</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Container</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Container</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierContainerForm
              containers={data?.containers ?? []}
              onSubmit={async (values) => {
                const { data } = await createSupplierContainerMutation({
                  variables: {
                    input: {
                      ...values,
                      supplierSlug,
                      quantity: parseInt(values.quantity, 10),
                    },
                  },
                });

                if (data?.createSupplierContainer?.id) {
                  navigate(
                    buildEditSupplierContainerPath(supplierSlug, data.createSupplierContainer.id)
                  );
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
