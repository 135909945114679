import { Flex } from "@chakra-ui/react";

import { LoadingSpinner } from "./LoadingSpinner";

export const PageSpinner = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
      <LoadingSpinner />
    </Flex>
  );
};
