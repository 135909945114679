import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, PlusIcon } from "../../../components/icons";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Pagination } from "../../../components/Pagination";
import { Panel } from "../../../components/Panel";
import { StyledLink } from "../../../components/StyledLink";
import { Table } from "../../../components/Table";
import {
  buildAdminEditNominalCodePath,
  buildAdminNewNominalCodePath,
} from "../../../config/routes";
import { GetNominalCodesQuery, useGetNominalCodesQuery } from "../../../generated/graphql";

export const NOMINAL_CODE_LIST_GET_NOMINAL_CODES = gql`
  query GetNominalCodes($offset: Int, $limit: Int) {
    nominalCodes(orderBy: { code: asc }, skip: $offset, take: $limit) {
      id
      code
    }

    nominalCodeCount
  }
`;

export const NominalCodeListPage = () => {
  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useGetNominalCodesQuery({
    variables: {
      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the Nominal Codes</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Nominal Codes</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Nominal Codes</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildAdminNewNominalCodePath()}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new Nominal Code
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="nominal-codes-list"
            data={data?.nominalCodes ?? []}
            columns={nominalCodeTableColumns}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.nominalCodeCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const CodeAccessor = (nominalCode: GetNominalCodesQuery["nominalCodes"][0]) => {
  return {
    id: nominalCode.id,
    code: nominalCode.code,
  };
};

const nominalCodeTableColumns = [
  {
    Header: "Code",
    accessor: CodeAccessor,
    Cell: (props: { value: ReturnType<typeof CodeAccessor> }) => {
      const { id, code } = props.value;

      return <StyledLink to={buildAdminEditNominalCodePath(id)}>{code}</StyledLink>;
    },
  },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Link to={`${props.value}/edit`}>
          <IconButton colorScheme="green" aria-label="Edit Nominal Code" icon={<EditIcon />} />
        </Link>
      );
    },
  },
];
