interface Request {
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

interface Response {
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

interface Filtered {
  body?: any;
}

export default class LogRocketFuzzySearchSanitizer {
  public static setup(fields: string[]) {
    const instance = new LogRocketFuzzySearchSanitizer(fields);

    return {
      requestSanitizer: instance.requestSanitizer.bind(instance),
      responseSanitizer: instance.responseSanitizer.bind(instance),
    };
  }

  public fields: string[] = [];

  constructor(privateFields: string[]) {
    this.fields = privateFields;
  }

  public requestSanitizer(request: Request): object | any {
    // avoid parsing GET requests as there will be no body
    if (request.method === "GET") {
      return request;
    }

    return this._networkHandler(request);
  }

  public responseSanitizer(reponse: Response): object | any {
    return this._networkHandler(reponse);
  }

  private _networkHandler(input: Request | Response) {
    const { body } = input;
    let parsedBody: object;

    try {
      parsedBody = JSON.parse(body ?? "null");

      this._searchBody(parsedBody);
    } catch (error) {
      return input;
    }

    (input as Filtered).body = parsedBody;

    return input;
  }

  private _searchBody(body: any = {}) {
    // iterate over collection of objects ex. [{}, ...]
    if (body && body.constructor === Array) {
      body.forEach((item) => this._searchBody(item));
    } else {
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          const keyName = body[key];

          /*
              Objects with the following shape:
                {
                  type: 'email',
                  value: 'secret@ex.com'
                }
              where type/value keynames are generic and instead
              the value matching the type keyname should be masked.
            */
          const isTypeValuePair = key === "type" && "value" in body;

          if (typeof keyName === "object") {
            if (!isTypeValuePair) {
              this._searchBody(keyName);
            }
          }

          if (isTypeValuePair) {
            this._mask(body, body.type, "value");
          } else {
            this._mask(body, key);
          }
        }
      }
    }
  }

  private _mask(body: object, searchKeyName: string, maskKeyName?: string) {
    maskKeyName = maskKeyName || searchKeyName;

    const isSensitiveFieldName = this._match(searchKeyName);

    if (isSensitiveFieldName) {
      (body as any)[maskKeyName] = "*";
    }
  }

  private _match(keyName: string = ""): boolean {
    const { fields } = this;
    const normalizedKeyName = keyName.toLowerCase();

    return fields.some((field) => normalizedKeyName.indexOf(field.toLowerCase()) > -1);
  }
}
