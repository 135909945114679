import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { SupplierContainerForm } from "../../components/SupplierContainerForm";
import {
  buildSupplierContainersListPath,
  buildSupplierRootPath,
  RouteParams,
} from "../../config/routes";
import {
  useUpdateSupplierContainerMutation,
  useUpdateSupplierGetDataQuery,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const UPDATE_SUPPLIER_GET_DATA = gql`
  query UpdateSupplierGetData($supplierContainerId: String!) {
    containers(orderBy: { name: asc }) {
      id
      name
      type
    }

    supplierContainer(where: { id: $supplierContainerId }) {
      id
      quantity
      container {
        id
      }
    }
  }
`;

export const Update_SUPPLIER_CONTAINER = gql`
  mutation UpdateSupplierContainer($input: UpdateSupplierContainerInput!) {
    updateSupplierContainer(input: $input) {
      id
    }
  }
`;

export const SupplierContainerEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const supplierContainerId = useSingleParam(RouteParams.SupplierContainerId, { required: true });

  const [updateSupplierContainerMutation] = useUpdateSupplierContainerMutation();

  const { loading, error, data } = useUpdateSupplierGetDataQuery({
    variables: {
      supplierContainerId,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the containers</Text>
      </Stack>
    );
  }

  if (!data || !data.supplierContainer) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { containers, supplierContainer } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Container {data.supplierContainer.id}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Container{" "}
            <Text as="span" color="blue.500">
              {data.supplierContainer.id}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierContainerForm
              initialValues={{
                containerId: supplierContainer.container.id,
                quantity: supplierContainer.quantity.toString(),
              }}
              containers={containers ?? []}
              onSubmit={async (values) => {
                const { data } = await updateSupplierContainerMutation({
                  variables: {
                    input: {
                      ...values,
                      supplierContainerId,
                      quantity: parseInt(values.quantity, 10),
                    },
                  },
                });

                if (data?.updateSupplierContainer?.id) {
                  navigate(buildSupplierContainersListPath(supplierSlug));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
