import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { FormikHandlers, useField } from "formik";

interface TextFieldProps {
  name: string;
  label: string;
  type?: string;
  onChange?: FormikHandlers["handleChange"];
}

export const TextField: React.FC<TextFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Input
        {...field}
        id={field.name}
        type={props.type ?? "text"}
        onChange={props.onChange ?? field.onChange}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
