import { spawn } from "xstate";
import { stop } from "xstate/lib/actions";
import { createModel } from "xstate/lib/model";

import { msalInstance } from "../AppProviders";
import { loginRequest } from "../auth/authConfig";
import { blankNotesReportMachine } from "./reports/blankNoteReport.machine";
import { deliveryNotesReportMachine } from "./reports/deliveryNoteReport.machine";
import { invoiceReportMachine } from "./reports/invoiceReport.machine";
import { notesReportMachine } from "./reports/noteReport.machine";
import { outstandingInvoiceReportMachine } from "./reports/outstandingInvoiceReport.machine";
import { supplierReengagementReportMachine } from "./reports/supplierReengagementReport.machine";

const generateAccessToken = async () => {
  if (process.env.NODE_ENV === "development") {
    return "dev_fake_token";
  }

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  return response.accessToken;
};

// openReportUrl ensures we have a fresh access token and then open the given path
// via a POST request so we can pass along the access token to the API
export const openReportURL = async (path: string) => {
  const accessToken = await generateAccessToken();

  // Submitting a form as the api auth requires the access token to be present in either the body or as a header
  const form = document.createElement("form");
  form.action = `${process.env.REACT_APP_API_BASE_URL}${path}`;
  form.method = "POST";
  form.target = "_blank";

  const tokenInput = document.createElement("input");
  tokenInput.name = "access_token";
  tokenInput.value = accessToken;
  form.appendChild(tokenInput);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export interface SelectedSupplier {
  id: string;
  name: string;
  internalId: number;
}

export interface SupplierFilter {
  from: SelectedSupplier;
  to: SelectedSupplier;
}

export interface CollectionNoteIDRangeFilter {
  fromID: number;
  toID: number;
}

export const generateReportConfigModel = createModel(
  {
    activeFlow: undefined as any,
  },
  {
    events: {
      startBlankNotesReportFlow: () => ({}),
      startNotesReportFlow: () => ({}),
      startDeliveryNotesReportFlow: () => ({}),
      startSupplierReengagementReportFlow: () => ({}),
      startInvoiceReportFlow: () => ({}),
      startOutstandingInvoiceReportFlow: () => ({}),
      restart: () => ({}),

      updateName: (value: string) => ({ value }),
      updateAge: (value: number) => ({ value }),
      anotherEvent: () => ({}), // no payload
    },
  }
);

export const generateReportConfigMachine = generateReportConfigModel.createMachine({
  id: "generateReportConfiguration",
  context: generateReportConfigModel.initialContext,
  initial: "selectingReportType",
  states: {
    selectingReportType: {
      on: {
        startBlankNotesReportFlow: {
          target: "blankNotesReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(blankNotesReportMachine);
            },
          }),
        },
        startNotesReportFlow: {
          target: "notesReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(notesReportMachine);
            },
          }),
        },
        startDeliveryNotesReportFlow: {
          target: "deliveryNotesReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(deliveryNotesReportMachine);
            },
          }),
        },
        startSupplierReengagementReportFlow: {
          target: "supplierReengagementReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(supplierReengagementReportMachine);
            },
          }),
        },
        startInvoiceReportFlow: {
          target: "invoiceReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(invoiceReportMachine);
            },
          }),
        },
        startOutstandingInvoiceReportFlow: {
          target: "outstandingInvoiceReportFlow",
          actions: generateReportConfigModel.assign({
            activeFlow: () => {
              return spawn(outstandingInvoiceReportMachine);
            },
          }),
        },
      },
    },
    blankNotesReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    notesReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    deliveryNotesReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    supplierReengagementReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    invoiceReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    outstandingInvoiceReportFlow: {
      exit: stop((ctx) => ctx.activeFlow),
    },
    restart: {
      always: {
        actions: generateReportConfigModel.assign({
          activeFlow: generateReportConfigModel.initialContext.activeFlow,
        }) as any,
        target: "selectingReportType",
      },
    },
  },
  on: {
    restart: ".restart",
  },
});
