import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { EWCCodeForm } from "../../../components/EWCCodeForm";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Panel } from "../../../components/Panel";
import { buildAdminEWCCodeListPath, RouteParams } from "../../../config/routes";
import {
  useEwcCodeEditPageGetEwcCodeQuery,
  useEwcCodeEditPageUpdateEwcCodeMutation,
} from "../../../generated/graphql";
import { useSingleParam } from "../../../hooks/useSingleParam";

export const EWC_CODE_EDIT_PAGE_GET_EWC_CODE = gql`
  query EWCCodeEditPageGetEWCCode($ewcCodeId: String!) {
    ewcCode(where: { id: $ewcCodeId }) {
      id
      code
      product
    }
  }
`;

export const EWC_CODE_EDIT_PAGE_EDIT_EWC_CODE = gql`
  mutation EWCCodeEditPageUpdateEWCCode($input: UpdateEWCCodeInput!) {
    updateEWCCode(input: $input) {
      id
    }
  }
`;

export const EWCCodeEditPage = () => {
  const navigate = useNavigate();
  const ewcCodeId = useSingleParam(RouteParams.EWCCodeId);

  const [updateEWCCode] = useEwcCodeEditPageUpdateEwcCodeMutation({
    refetchQueries: ["GetEWCCodes"],
  });

  const { loading, error, data } = useEwcCodeEditPageGetEwcCodeQuery({
    variables: { ewcCodeId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the EWC Code</Text>
      </Stack>
    );
  }

  if (!data || !data.ewcCode) {
    return <Navigate to={buildAdminEWCCodeListPath()} />;
  }

  const { ewcCode } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | EWC Code {ewcCode.id} </title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit EWC Code{" "}
            <Text as="span" color="blue.500">
              {ewcCode.code}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <EWCCodeForm
              initialValues={{
                code: ewcCode.code,
                product: ewcCode.product,
              }}
              onSubmit={async (values) => {
                await updateEWCCode({
                  variables: {
                    input: {
                      ...values,
                      ewcCodeId,
                    },
                  },
                });

                navigate(buildAdminEWCCodeListPath());
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
