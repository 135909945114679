import { gql } from "@apollo/client";
import { Button, ButtonGroup, Grid, Stack, Text, useToast } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedDate } from "react-intl";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { Collapsable, CollapseableToggle } from "../../components/Collapsable";
import { DataField } from "../../components/DataField";
import { CopyIcon, EditIcon, PrinterIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { QuoteLines } from "../../components/QuoteLines";
import { QuoteStatus } from "../../components/QuoteStatus";
import { buildQuotePagePath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useDuplicateQuoteMutation,
  useGetQuotePdfLazyQuery,
  useGetQuoteQuery,
  useToggleQuoteClosedStatusMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const QUOTES_PAGE_GET_QUOTE = gql`
  query GetQuote($quoteId: String!) {
    quote(where: { id: $quoteId }) {
      id
      quoteReference
      contact
      quotedBy
      creationDate
      closed
      notes

      quoteLines(orderBy: { material: asc }) {
        id
        material
        tonnage
        price
      }
    }
  }
`;

export const QUOTES_PAGE_GET_QUOTE_PDF = gql`
  query GetQuotePDF($quoteId: String!) {
    quote(where: { id: $quoteId }) {
      quoteReference
      pdf
    }
  }
`;

export const QUOTES_PAGE_DUPLICATE_QUOTE = gql`
  mutation DuplicateQuote($input: DuplicateQuoteInput!) {
    duplicateQuote(input: $input) {
      id
    }
  }
`;

export const QuotePage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const quoteId = useSingleParam(RouteParams.QuoteId, { required: true });

  const { loading, error, data } = useGetQuoteQuery({ variables: { quoteId } });
  const [getQuotePdf, { loading: pdfLoading }] = useGetQuotePdfLazyQuery({
    variables: { quoteId },
    onCompleted: (data) => {
      if (!data.quote || !data.quote.pdf) {
        toast({ title: "Something went wrong whilst downloading the PDF", status: "error" });
        return;
      }

      const { pdf: pdfContent, quoteReference } = data.quote;

      const a = document.createElement("a");
      a.href = pdfContent;
      a.download = `Quote Reference ${quoteReference}.pdf`;
      a.click();
    },
    onError: (e) => {
      console.log(e);
      toast({ title: "Something went wrong whilst downloading the PDF", status: "error" });
    },
  });

  const [duplicateQuote, { loading: isDuplicating }] = useDuplicateQuoteMutation({
    variables: { input: { quoteId } },
    onCompleted: (data) => {
      const duplicateQuoteId = data.duplicateQuote?.id;

      if (!duplicateQuoteId) {
        toast({ title: "Something went wrong whilst duplicating the Quote", status: "error" });
        return;
      }

      navigate(buildQuotePagePath(supplierSlug, duplicateQuoteId));
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the quote</Text>
      </Stack>
    );
  }

  if (!data || !data.quote) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { quote } = data;

  return (
    <Fragment>
      <Helmet>
        <title>{`WMS | Quote ${quote.quoteReference}`}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1000px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>
            Quote{" "}
            <Text as="span" color="blue.500">
              {quote.quoteReference}
            </Text>
          </PageTitle>
          <ButtonGroup>
            <Button
              colorScheme="pink"
              leftIcon={<PrinterIcon height="16px" width="16px" mr={1} />}
              isDisabled={pdfLoading}
              onClick={() => getQuotePdf()}
            >
              Print
            </Button>

            <Button
              colorScheme="green"
              leftIcon={<CopyIcon height="16px" width="16px" mr={1} />}
              isDisabled={isDuplicating}
              onClick={() => duplicateQuote()}
            >
              Duplicate
            </Button>

            <ToggleClosedStatusButton quoteId={quote.id} closed={quote.closed} />

            <Button
              as={Link}
              to="edit"
              colorScheme="teal"
              leftIcon={<EditIcon height="16px" width="16px" mr={1} />}
            >
              Edit
            </Button>
          </ButtonGroup>
        </Stack>

        <Collapsable>
          <Panel data-testid="quote-details">
            <Stack p={4} spacing={6}>
              <Grid gridTemplateColumns="50% 50%" gridRowGap={6}>
                <DataField title="Contact">
                  <Text>{quote.contact}</Text>
                </DataField>

                <DataField title="Status">
                  <QuoteStatus closed={Boolean(quote.closed)} />
                </DataField>

                <DataField title="Quoted By">
                  <Text>{quote.quotedBy}</Text>
                </DataField>

                <DataField title="Created">
                  <Text>
                    <FormattedDate
                      value={quote.creationDate}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </Text>
                </DataField>
              </Grid>

              <DataField title="Notes">
                {quote.notes ? (
                  <Text>{quote.notes}</Text>
                ) : (
                  <Text color="gray.400" fontSize="sm">
                    No notes found
                  </Text>
                )}
              </DataField>
            </Stack>
          </Panel>

          <CollapseableToggle />
        </Collapsable>

        <QuoteLines quoteId={quoteId} quoteLines={quote.quoteLines ?? []} />
      </Stack>
    </Fragment>
  );
};

export const TOGGLE_MUTATION_CLOSED_STATUS = gql`
  mutation ToggleQuoteClosedStatus($quoteId: String!) {
    toggleQuoteClosedStatus(input: { quoteId: $quoteId }) {
      id
      closed
    }
  }
`;

interface ToggleClosedStatusButtonProps {
  quoteId: string;
  closed: boolean;
}

const ToggleClosedStatusButton: React.FC<ToggleClosedStatusButtonProps> = (props) => {
  const [toggleStatus, { loading }] = useToggleQuoteClosedStatusMutation({
    refetchQueries: ["GetQuote"],
  });

  return (
    <Button
      colorScheme="orange"
      isDisabled={loading}
      onClick={async () => {
        await toggleStatus({
          variables: {
            quoteId: props.quoteId,
          },
        });
      }}
    >
      Mark as {props.closed ? "Open" : "Closed"}
    </Button>
  );
};
