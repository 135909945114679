import { gql } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Heading,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { DataField } from "../../components/DataField";
import { DeleteWithConfirmationButton } from "../../components/DeleteWithConfirmationButton";
import { FinancialFigure } from "../../components/FinancialFigure";
import { EditIcon, MaximizeIcon, MinimizeIcon } from "../../components/icons";
import { Link as LinkIcon } from "../../components/icons";
import { InvoiceCompletedStatus } from "../../components/InvoiceCompletedStatus";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { StyledLink } from "../../components/StyledLink";
import { FinancialFigureCell, Table } from "../../components/Table";
import {
  buildCollectionNotePagePath,
  buildInvoicesListPath,
  buildSupplierRootPath,
  RouteParams,
} from "../../config/routes";
import { useSupplier } from "../../context/SupplierContext";
import {
  CollectionNoteLine,
  useGetInvoiceQuery,
  useInvoicePageDeleteInvoiceMutation,
  useRemoveMatchedCollectionNotesFromInvoiceMutation,
  useToggleInvoiceCompletedStatusMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const INVOICE_PAGE_GET_INVOICE = gql`
  query GetInvoice($invoiceId: String!) {
    invoice(where: { id: $invoiceId }) {
      id
      reference
      supplierInvoiceNumber
      net
      vat
      gross
      completed
      comments
      hasMatchedCollectionNotes
      collectionNoteLines {
        id
        collectionNote {
          id
          referenceId
        }
        quantity
        description
        value
        vat
        total
        ewcCode {
          code
        }
        nominalCode {
          code
        }
      }
    }
  }
`;

export const INVOICE_PAGE_REMOVE_MATCHED_INVOICE = gql`
  mutation RemoveMatchedCollectionNotesFromInvoice(
    $input: RemoveMatchedCollectionNotesFromInvoiceInput!
  ) {
    removeMatchedCollectionNotesFromInvoice(input: $input) {
      id
      completed
    }
  }
`;

export const INVOICE_PAGE_DELETE_INVOICE = gql`
  mutation InvoicePageDeleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input) {
      id
    }
  }
`;

export const InvoicePage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const invoiceId = useSingleParam(RouteParams.InvoiceId, { required: true });

  const { supplier } = useSupplier();

  const { loading, error, data } = useGetInvoiceQuery({ variables: { invoiceId } });

  const [removeMatchedCollectNotes] = useRemoveMatchedCollectionNotesFromInvoiceMutation({
    onCompleted: () => {
      toast({ title: "Removed matched Notes", status: "success" });
    },
  });

  const [deleteInvoice] = useInvoicePageDeleteInvoiceMutation({
    onCompleted: () => {
      navigate(buildInvoicesListPath(supplierSlug));
    },
  });

  const orderedMatchedNotes = React.useMemo(() => {
    if (!data?.invoice?.collectionNoteLines) {
      return [];
    }

    // clone the array rather than mutating the given array
    const cloned = [...data.invoice.collectionNoteLines];

    // sort by collection note descending
    return cloned.sort((a, b) => b.collectionNote.referenceId - a.collectionNote.referenceId);
  }, [data?.invoice?.collectionNoteLines]);

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the invoice</Text>
      </Stack>
    );
  }

  if (!data || !data.invoice) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { invoice } = data;

  return (
    <React.Fragment>
      <Helmet>
        <title>WMS | Invoice {invoice.reference}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1000px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>
            Invoice{" "}
            <Text as="span" color="blue.500">
              {invoice.reference}
            </Text>
          </PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to="edit"
              colorScheme="teal"
              leftIcon={<EditIcon height="16px" width="16px" mr={1} />}
            >
              Edit
            </Button>

            <ToggleCompletedStatusButton invoiceId={invoice.id} completed={invoice.completed} />

            <CollectionNoteMatchingButton
              completed={invoice.completed}
              onRemoveMatchedNotes={() => {
                removeMatchedCollectNotes({
                  variables: {
                    input: {
                      invoiceId: invoice.id,
                    },
                  },
                });
              }}
            />

            {!invoice.hasMatchedCollectionNotes ? (
              <DeleteWithConfirmationButton
                buttonTx="Delete"
                confirmationTx="Are you sure you want to delete this invoice?"
                onConfirmed={() => {
                  deleteInvoice({
                    variables: {
                      input: {
                        invoiceId: invoice.id,
                      },
                    },
                  });
                }}
              />
            ) : null}
          </ButtonGroup>
        </Stack>

        <Panel data-testid="invoice-details">
          <Stack p={4} spacing={6}>
            <Grid gridTemplateColumns="33% 33% 33%" gridRowGap={6}>
              <DataField title="File Reference">{invoice.reference}</DataField>
              <DataField title="Supplier Invoice Number">{invoice.supplierInvoiceNumber}</DataField>
              <DataField title="Status">
                <InvoiceCompletedStatus completed={invoice.completed} />
              </DataField>
            </Grid>
            <Grid gridTemplateColumns="33% 33% 33%" gridRowGap={6}>
              <DataField title="Net">
                {invoice.net ? (
                  <FinancialFigure value={invoice.net} currency={supplier.currency} />
                ) : null}
              </DataField>

              <DataField title="VAT">
                {invoice.vat ? (
                  <FinancialFigure value={invoice.vat} currency={supplier.currency} />
                ) : null}
              </DataField>

              <DataField title="Gross">
                {invoice.gross ? (
                  <FinancialFigure value={invoice.gross} currency={supplier.currency} />
                ) : null}
              </DataField>
            </Grid>

            <DataField title="Comments">
              {invoice.comments ? (
                <Text>{invoice.comments}</Text>
              ) : (
                <Text color="gray.400" fontSize="sm">
                  No comments found
                </Text>
              )}
            </DataField>
          </Stack>
        </Panel>

        {orderedMatchedNotes.length > 0 ? (
          <Stack>
            <Heading size="md" fontWeight="500" color="gray.700">
              Matched notes
            </Heading>
            <Box sx={{ bg: "white" }}>
              <Table
                data={orderedMatchedNotes}
                columns={collectionNoteLinesTableColumns}
                rowActions={({ row }) => {
                  return (
                    <Link to={buildCollectionNotePagePath(supplierSlug, row.collectionNote.id)}>
                      <LinkIcon />
                    </Link>
                  );
                }}
              />
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </React.Fragment>
  );
};

interface CollectionNoteMatchingButtonProps {
  completed: boolean;
  onRemoveMatchedNotes: () => void;
}

const CollectionNoteMatchingButton = (props: CollectionNoteMatchingButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialFocusRef = React.useRef<HTMLButtonElement>(null);

  if (!props.completed) {
    return (
      <Button
        as={Link}
        to="match"
        colorScheme="blue"
        leftIcon={<MinimizeIcon height="16px" width="16px" mr={1} />}
      >
        Match to Notes
      </Button>
    );
  }

  return (
    <Popover isOpen={isOpen} initialFocusRef={initialFocusRef} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button colorScheme="blue" leftIcon={<MaximizeIcon height="16px" width="16px" mr={1} />}>
          Remove Matched Notes
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Remove matched notes from this invoice?
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button colorScheme="green" ref={initialFocusRef} onClick={props.onRemoveMatchedNotes}>
              Yes
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              No
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

const CollectionNoteIdFieldAccessor = (line: CollectionNoteLine) => {
  return {
    id: line.collectionNote.id,
    referenceId: line.collectionNote.referenceId,
  };
};

const collectionNoteLinesTableColumns = [
  {
    id: "collectionNoteId",
    accessor: CollectionNoteIdFieldAccessor,
    Header: "Note ID",
    width: "8%",
    Cell: (props: { value: ReturnType<typeof CollectionNoteIdFieldAccessor> }) => {
      const { id, referenceId } = props.value;

      const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

      return (
        <StyledLink to={buildCollectionNotePagePath(supplierSlug, id)}>{referenceId}</StyledLink>
      );
    },
  },
  {
    id: "quantity",
    accessor: "quantity",
    Header: "Quantity",
    width: "10%",
  },
  {
    id: "description",
    accessor: "description",
    Header: "Description",
    width: "20%",
  },
  {
    id: "ewcCode",
    accessor: "ewcCode.code",
    Header: "EWC Code",
    width: "15%",
  },
  {
    id: "nominalCode",
    accessor: "nominalCode.code",
    Header: "Nominal Code",
    width: "15%",
  },
  {
    id: "value",
    accessor: "value",
    Header: "Value",
    Cell: FinancialFigureCell,
  },
  {
    id: "vat",
    accessor: "vat",
    Header: "VAT",
    Cell: FinancialFigureCell,
  },
  {
    id: "total",
    accessor: "total",
    Header: "Total",
    Cell: FinancialFigureCell,
  },
];

export const TOGGLE_INVOICE_COMPLETED_STATUS = gql`
  mutation ToggleInvoiceCompletedStatus($invoiceId: String!) {
    toggleInvoiceCompletedStatus(input: { invoiceId: $invoiceId }) {
      id
      completed
    }
  }
`;

interface ToggleCompletedStatusButtonProps {
  invoiceId: string;
  completed: boolean;
}

const ToggleCompletedStatusButton: React.FC<ToggleCompletedStatusButtonProps> = (props) => {
  const [toggleStatus, { loading }] = useToggleInvoiceCompletedStatusMutation({
    refetchQueries: ["GetInvoice"],
  });

  return (
    <Button
      colorScheme="orange"
      isDisabled={loading}
      onClick={async () => {
        await toggleStatus({
          variables: {
            invoiceId: props.invoiceId,
          },
        });
      }}
    >
      Mark as {props.completed ? "Open" : "Completed"}
    </Button>
  );
};
