import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';

type TextareaFieldProps = FieldHookConfig<any> & {
  label: string;
};

export const TextareaField: React.FC<TextareaFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Textarea {...field} id={field.name} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
