import { Checkbox, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { FieldHookConfig, useField } from "formik";

type CheckboxFieldProps = FieldHookConfig<any> & {
  label: string;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={field.name} display="flex" alignItems="center">
        {label}
        <Checkbox id={field.name} isChecked={field.value} ml={2} onChange={field.onChange} />
      </FormLabel>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
