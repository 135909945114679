import { motion, Transition } from "framer-motion";
import React from "react";

export enum RotateVariant {
  Idle = "idle",
  Rotated = "rotated",
}

interface RotateProps {
  active: boolean;
  transition?: Transition;
}

export const Rotate: React.FC<RotateProps> = (props) => {
  return (
    <motion.div
      initial={RotateVariant.Idle}
      animate={props.active ? RotateVariant.Rotated : RotateVariant.Idle}
      exit={RotateVariant.Idle}
      variants={{
        [RotateVariant.Idle]: { transform: "rotate(0deg)" },
        [RotateVariant.Rotated]: { transform: "rotate(180deg)" },
      }}
      transition={props.transition ?? { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      {props.children}
    </motion.div>
  );
};
