import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import currency from "currency.js";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { useSupplier } from "../context/SupplierContext";
import { TextareaField } from "./form/TextareaField";
import { TextField } from "./form/TextField";

const InvoiceFormSchema = yup.object().shape({
  reference: yup.string().required(),
  supplierInvoiceNumber: yup.string(),
  net: yup.number().required(),
  vat: yup.number().required(),
  gross: yup.number().required(),
  comments: yup.string(),
});

interface FormValues {
  reference: string;
  supplierInvoiceNumber: string;
  net: string;
  vat: string;
  gross: string;
  comments: string;
}

interface InvoiceFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const InvoiceForm: React.FC<InvoiceFormProps> = (props) => {
  const { supplier } = useSupplier();

  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        reference: "",
        supplierInvoiceNumber: "",
        net: "",
        vat: "",
        gross: "",
        comments: "",
      };
    }

    return props.initialValues;
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={InvoiceFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting, handleChange, setFieldValue }) => (
        <Form>
          <Stack spacing={6}>
            <Box>
              <TextField name="reference" label="File Reference" />
            </Box>
            <Box>
              <TextField name="supplierInvoiceNumber" label="Supplier Invoice Number" />
            </Box>
            <Grid gridTemplateColumns="auto auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField
                  name="net"
                  label="Net"
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleChange(e);

                    const inputValue = e.currentTarget.value;

                    if (!inputValue || inputValue.trim() === "") {
                      return;
                    }

                    const vat = currency(inputValue).multiply(supplier.vat / 100);
                    const gross = currency(inputValue).add(vat);

                    setFieldValue("vat", vat.value.toString());
                    setFieldValue("gross", gross.value.toString());
                  }}
                />
              </Box>

              <Box>
                <TextField name="vat" label="VAT" />
              </Box>

              <Box>
                <TextField name="gross" label="Gross" />
              </Box>
            </Grid>

            <Box>
              <TextareaField name="comments" label="Comments" />
            </Box>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
