import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, PlusIcon } from "../../../components/icons";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Pagination } from "../../../components/Pagination";
import { Panel } from "../../../components/Panel";
import { StyledLink } from "../../../components/StyledLink";
import { Table } from "../../../components/Table";
import { buildAdminEditEWCCodePath, buildAdminNewEWCCodePath } from "../../../config/routes";
import { GetEwcCodesQuery, useGetEwcCodesQuery } from "../../../generated/graphql";

export const EWC_CODE_LIST_GET_EWC_CODES = gql`
  query GetEWCCodes($offset: Int, $limit: Int) {
    ewcCodes(orderBy: { code: asc }, skip: $offset, take: $limit) {
      id
      code
      product
    }

    ewcCodeCount
  }
`;

export const EWCCodeListPage = () => {
  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useGetEwcCodesQuery({
    variables: {
      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the EWC Codes</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | EWC Codes</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>EWC Codes</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildAdminNewEWCCodePath()}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new EWC Code
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="ewc-codes-list"
            data={data?.ewcCodes ?? []}
            columns={ewcCodeTableColumns}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.ewcCodeCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const CodeAccessor = (ewcCode: GetEwcCodesQuery["ewcCodes"][0]) => {
  return {
    id: ewcCode.id,
    code: ewcCode.code,
  };
};

const ewcCodeTableColumns = [
  {
    Header: "Code",
    accessor: CodeAccessor,
    Cell: (props: { value: ReturnType<typeof CodeAccessor> }) => {
      const { id, code } = props.value;

      return <StyledLink to={buildAdminEditEWCCodePath(id)}>{code}</StyledLink>;
    },
  },
  { Header: "Product", accessor: "product" },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Link to={`${props.value}/edit`}>
          <IconButton colorScheme="green" aria-label="Edit EWC Code" icon={<EditIcon />} />
        </Link>
      );
    },
  },
];
