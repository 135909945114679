import { useMsal } from "@azure/msal-react";
import { Box, Grid, Heading, Stack, Tag, Text } from "@chakra-ui/react";

import { RouteParams } from "../config/routes";
import { useSupplier } from "../context/SupplierContext";
import { useSingleParam } from "../hooks/useSingleParam";
import { AppSidebar } from "./Sidebar";

export const Page: React.FC = ({ children }) => {
  return (
    <Grid
      minHeight="100vh"
      width="100%"
      gridTemplateColumns={["210px auto", null, null, null, null, "250px auto"]}
      bg="gray.50"
    >
      <AppSidebar />

      <Grid height="100%" gridTemplateRows="90px auto">
        <Header />

        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
};

const Header = () => {
  const { accounts } = useMsal();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={6}
      shadow="sm"
      bg="white"
    >
      <Box>{supplierSlug ? <SupplierName /> : null}</Box>
      {accounts[0] ? <Text>{accounts[0].username}</Text> : null}
    </Stack>
  );
};

const SupplierName = () => {
  const { supplier } = useSupplier();

  return (
    <Tag size="lg" variant="solid" colorScheme="teal">
      {supplier.name}
    </Tag>
  );
};

export const PageTitle: React.FC = ({ children }) => {
  return (
    <Heading size="lg" fontWeight="500" color="gray.700">
      {children}
    </Heading>
  );
};
