import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

export type FilterInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type CollectionNoteLineOrderByInput = {
  createdAt?: Maybe<SortOrder>;
};

export type CollectionNoteLineWhereInput = {
  supplierSlug?: Maybe<Scalars['String']>;
  invoiced?: Maybe<Scalars['Boolean']>;
};

export type CollectionNote = {
  __typename?: 'CollectionNote';
  id: Scalars['String'];
  referenceId: Scalars['Int'];
  creationDate: Scalars['DateTime'];
  printedAt?: Maybe<Scalars['DateTime']>;
  closed: Scalars['Boolean'];
  return: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  supplier: Supplier;
  supplierAddress: SupplierAddress;
  collectionNoteLines: Array<CollectionNoteLine>;
  linesCount: Scalars['Int'];
  invoiced: Scalars['Boolean'];
  net: Scalars['String'];
  vat: Scalars['String'];
  gross: Scalars['String'];
  printed?: Maybe<Scalars['Boolean']>;
  displayReference?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
};


export type CollectionNoteCollectionNoteLinesArgs = {
  orderBy?: Maybe<CollectionNoteLineOrderByInput>;
};

export type CollectionNoteLine = {
  __typename?: 'CollectionNoteLine';
  id: Scalars['String'];
  quantity: Scalars['String'];
  description: Scalars['String'];
  ewcCode?: Maybe<EwcCode>;
  nominalCode?: Maybe<NominalCode>;
  value?: Maybe<Scalars['Decimal']>;
  vat?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  collectionNote: CollectionNote;
  invoice?: Maybe<Invoice>;
  invoiced: Scalars['Boolean'];
};

export type CollectionNoteLineInput = {
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ewcCodeId?: Maybe<Scalars['String']>;
  nominalCodeId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export enum CollectionNotesFilterOption {
  All = 'all',
  Open = 'open',
  Invoiced = 'invoiced',
  Closed = 'closed'
}

export type CreateCollectionNoteInput = {
  supplierSlug: Scalars['String'];
  supplierAddressId: Scalars['String'];
  creationDate: Scalars['DateTime'];
  isReturn: Scalars['Boolean'];
  collectionNoteLines: Array<CollectionNoteLineInput>;
};

export type ListCollectionNoteCountInput = {
  supplierSlug: Scalars['String'];
  filter: Array<FilterInput>;
};

export type CollectionNotesOrderByInput = {
  displayReference?: Maybe<SortOrder>;
  creationDate?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  invoiced?: Maybe<SortOrder>;
  closed?: Maybe<SortOrder>;
  printedAt?: Maybe<SortOrder>;
};

export type ListCollectionNotesInput = {
  supplierSlug: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filter: Array<FilterInput>;
  orderBy?: Maybe<CollectionNotesOrderByInput>;
};

export type MarkCollectionNoteAsPrinted = {
  collectionNoteId: Scalars['String'];
};

export type UpdateCollectionNoteInput = {
  collectionNoteId: Scalars['String'];
  supplierAddressId: Scalars['String'];
  creationDate: Scalars['DateTime'];
  isReturn: Scalars['Boolean'];
  collectionNoteLines: Array<CollectionNoteLineInput>;
};

export type UpdateCollectionNoteLinesInput = {
  collectionNoteId: Scalars['String'];
  collectionNoteLines: Array<CollectionNoteLineInput>;
};

export type ToggleCollectionNoteClosedStatus = {
  collectionNoteId: Scalars['String'];
};

export type Container = {
  __typename?: 'Container';
  id: Scalars['String'];
  name: Scalars['String'];
  type: ContainerTypeEnum;
  supplierContainers: Array<SupplierContainer>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type ContainerSupplierContainersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SupplierContainerWhereUniqueInput>;
};

export enum ContainerTypeEnum {
  Bins = 'BINS',
  Hl_5Skips = 'HL_5_SKIPS',
  Hl20Skips = 'HL20_SKIPS'
}

export type CreateContainerInput = {
  name: Scalars['String'];
  type: ContainerTypeEnum;
};

export type UpdateContainerInput = {
  containerId: Scalars['String'];
  name: Scalars['String'];
  type: ContainerTypeEnum;
};

export type EwcCode = {
  __typename?: 'EWCCode';
  id: Scalars['String'];
  code: Scalars['String'];
  product: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CreateEwcCodeInput = {
  code: Scalars['String'];
  product: Scalars['String'];
};

export type UpdateEwcCodeInput = {
  ewcCodeId: Scalars['String'];
  code: Scalars['String'];
  product: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  reference: Scalars['String'];
  supplierInvoiceNumber?: Maybe<Scalars['String']>;
  net?: Maybe<Scalars['Decimal']>;
  vat?: Maybe<Scalars['Decimal']>;
  gross?: Maybe<Scalars['Decimal']>;
  comments: Scalars['String'];
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  supplier: Supplier;
  collectionNoteLines: Array<CollectionNoteLine>;
  hasMatchedCollectionNotes: Scalars['Boolean'];
};


export type InvoiceCollectionNoteLinesArgs = {
  orderBy?: Maybe<Array<InvoiceCollectionNoteLinesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CollectionNoteLineWhereUniqueInput>;
};

export type CreateInvoiceInput = {
  supplierSlug: Scalars['String'];
  reference: Scalars['String'];
  supplierInvoiceNumber: Scalars['String'];
  net: Scalars['String'];
  vat: Scalars['String'];
  gross: Scalars['String'];
  comments: Scalars['String'];
};

export type DeleteInvoiceInput = {
  invoiceId: Scalars['String'];
};

export type ListInvoiceCountInput = {
  supplierSlug: Scalars['String'];
  filter: Array<FilterInput>;
};

export type MatchCollectionNotesToInvoiceInput = {
  invoiceId: Scalars['String'];
  collectionNoteLineIds: Array<Scalars['String']>;
  markInvoiceAsComplete: Scalars['Boolean'];
};

export type RemoveMatchedCollectionNotesFromInvoiceInput = {
  invoiceId: Scalars['String'];
};

export type ToggleInvoiceCompletedStatus = {
  invoiceId: Scalars['String'];
};

export type UpdateInvoiceInput = {
  invoiceId: Scalars['String'];
  reference: Scalars['String'];
  supplierInvoiceNumber: Scalars['String'];
  net: Scalars['String'];
  vat: Scalars['String'];
  gross: Scalars['String'];
  comments: Scalars['String'];
};

export type NominalCode = {
  __typename?: 'NominalCode';
  id: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CreateNominalCodeInput = {
  code: Scalars['String'];
};

export type UpdateNominalCodeInput = {
  nominalCodeId: Scalars['String'];
  code: Scalars['String'];
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['String'];
  quoteReference: Scalars['Int'];
  contact: Scalars['String'];
  quotedBy: Scalars['String'];
  creationDate: Scalars['DateTime'];
  notes: Scalars['String'];
  closed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  supplier: Supplier;
  quoteLines: Array<QuoteLine>;
  pdf?: Maybe<Scalars['String']>;
};


export type QuoteQuoteLinesArgs = {
  orderBy?: Maybe<Array<QuoteQuoteLinesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuoteLineWhereUniqueInput>;
};

export type QuoteLine = {
  __typename?: 'QuoteLine';
  id: Scalars['String'];
  material: Scalars['String'];
  price: Scalars['String'];
  tonnage: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  quote: Quote;
};

export type CreateQuoteInput = {
  supplierSlug: Scalars['String'];
  contact: Scalars['String'];
  quotedBy: Scalars['String'];
  creationDate: Scalars['DateTime'];
  notes: Scalars['String'];
};

export type DuplicateQuoteInput = {
  quoteId: Scalars['String'];
};

export type UpdateQuoteInput = {
  quoteId: Scalars['String'];
  contact: Scalars['String'];
  quotedBy: Scalars['String'];
  creationDate: Scalars['DateTime'];
  notes: Scalars['String'];
};

export type UpdateQuoteLinesLineInput = {
  id?: Maybe<Scalars['String']>;
  material: Scalars['String'];
  price: Scalars['String'];
  tonnage: Scalars['String'];
};

export type UpdateQuoteLinesInput = {
  quoteId: Scalars['String'];
  quoteLines: Array<UpdateQuoteLinesLineInput>;
};

export type ToggleQuoteClosedStatus = {
  quoteId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  url?: Maybe<Scalars['String']>;
};

export type BlankCollectionNotesReportInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  fromSupplierID?: Maybe<Scalars['Int']>;
  toSupplierID?: Maybe<Scalars['Int']>;
  fromCollectionNoteID?: Maybe<Scalars['Int']>;
  toCollectionNoteID?: Maybe<Scalars['Int']>;
};

export type CollectionNotesReportInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  fromSupplierID?: Maybe<Scalars['Int']>;
  toSupplierID?: Maybe<Scalars['Int']>;
  fromCollectionNoteID?: Maybe<Scalars['Int']>;
  toCollectionNoteID?: Maybe<Scalars['Int']>;
  includeCollectionNoteValues: Scalars['Boolean'];
  includeInvoicedCollectionNotes: Scalars['Boolean'];
};

export type DeliveryCollectionNotesReportInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  fromSupplierID?: Maybe<Scalars['Int']>;
  toSupplierID?: Maybe<Scalars['Int']>;
};

export type InvoiceReportInput = {
  fromSupplierID: Scalars['Int'];
  toSupplierID: Scalars['Int'];
  fromFileReference: Scalars['String'];
  toFileReference: Scalars['String'];
};

export type SupplierReengagementReportInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type SiteVisitCommentInput = {
  max: Scalars['Int'];
};

export type SiteVisit = {
  __typename?: 'SiteVisit';
  id: Scalars['String'];
  visitDate: Scalars['DateTime'];
  visitor: Scalars['String'];
  comments: Scalars['String'];
  truncatedComments: Scalars['String'];
  supplier: Supplier;
};


export type SiteVisitTruncatedCommentsArgs = {
  input: SiteVisitCommentInput;
};

export type CreateSiteVisitInput = {
  supplierSlug: Scalars['String'];
  visitDate: Scalars['DateTime'];
  visitor: Scalars['String'];
  comments: Scalars['String'];
};

export type UpdateSiteVisitInput = {
  siteVisitId: Scalars['String'];
  visitDate: Scalars['DateTime'];
  visitor: Scalars['String'];
  comments: Scalars['String'];
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  vat: Scalars['Float'];
  sageReference?: Maybe<Scalars['String']>;
  currency: Currency;
  internalId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  quotes: Array<Quote>;
};


export type SupplierQuotesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuoteWhereUniqueInput>;
};

export type CreateSupplierInput = {
  name: Scalars['String'];
  vat?: Maybe<Scalars['Float']>;
  sageReference?: Maybe<Scalars['String']>;
  currency: Currency;
};

export type ListSupplierCountInput = {
  filters: Array<FilterInput>;
};

export type ListSupplierInput = {
  offset: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  filters: Array<FilterInput>;
};

export type UpdateSupplierInput = {
  supplierId: Scalars['String'];
  name: Scalars['String'];
  vat?: Maybe<Scalars['Float']>;
  sageReference?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
};

export enum SupplierAddressTypeEnum {
  Invoice = 'Invoice',
  Collection = 'Collection'
}

export type SupplierAddress = {
  __typename?: 'SupplierAddress';
  id: Scalars['String'];
  type: SupplierAddressTypeEnum;
  siteName?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  supplier: Supplier;
};

export type CreateSupplierAddressInput = {
  supplierSlug: Scalars['String'];
  type: SupplierAddressTypeEnum;
  siteName?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateSupplierAddressInput = {
  supplierAddressId: Scalars['String'];
  type: SupplierAddressTypeEnum;
  siteName?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type SupplierContainer = {
  __typename?: 'SupplierContainer';
  id: Scalars['String'];
  quantity: Scalars['Int'];
  container: Container;
  supplier: Supplier;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CreateSupplierContainerInput = {
  supplierSlug: Scalars['String'];
  containerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type UpdateSupplierContainerInput = {
  supplierContainerId: Scalars['String'];
  containerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type SupplierContact = {
  __typename?: 'SupplierContact';
  id: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  supplier: Supplier;
};

export type CreateSupplierContactInput = {
  supplierSlug: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DeleteSupplierContactInput = {
  contactId: Scalars['String'];
};

export type UpdateSupplierContactInput = {
  supplierContactId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


export type CollectionNoteCollectionNoteLinesOrderByInput = {
  createdAt?: Maybe<SortOrder>;
};

export type CollectionNoteLineWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type CollectionNoteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['Int']>;
};

export type SupplierContainerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ContainerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ContainerWhereInput = {
  AND?: Maybe<Array<ContainerWhereInput>>;
  OR?: Maybe<Array<ContainerWhereInput>>;
  NOT?: Maybe<Array<ContainerWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumContainerTypeEnumFilter>;
  supplierContainers?: Maybe<SupplierContainerListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QueryContainersOrderByInput = {
  name?: Maybe<SortOrder>;
};

export type EwcCodeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EwcCodeWhereInput = {
  AND?: Maybe<Array<EwcCodeWhereInput>>;
  OR?: Maybe<Array<EwcCodeWhereInput>>;
  NOT?: Maybe<Array<EwcCodeWhereInput>>;
  id?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  product?: Maybe<StringFilter>;
  collectionNoteLines?: Maybe<CollectionNoteLineListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QueryEwcCodesOrderByInput = {
  code?: Maybe<SortOrder>;
  product?: Maybe<SortOrder>;
};

export type InvoiceCollectionNoteLinesOrderByInput = {
  createdAt?: Maybe<SortOrder>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  supplierInvoiceNumber?: Maybe<StringNullableFilter>;
  net?: Maybe<DecimalNullableFilter>;
  vat?: Maybe<DecimalNullableFilter>;
  gross?: Maybe<DecimalNullableFilter>;
  comments?: Maybe<StringFilter>;
  completed?: Maybe<BoolFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  collectionNoteLines?: Maybe<CollectionNoteLineListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QueryInvoicesOrderByInput = {
  reference?: Maybe<SortOrder>;
  supplierInvoiceNumber?: Maybe<SortOrder>;
  net?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  gross?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type NominalCodeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NominalCodeWhereInput = {
  AND?: Maybe<Array<NominalCodeWhereInput>>;
  OR?: Maybe<Array<NominalCodeWhereInput>>;
  NOT?: Maybe<Array<NominalCodeWhereInput>>;
  id?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  collectionNoteLines?: Maybe<CollectionNoteLineListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QueryNominalCodesOrderByInput = {
  code?: Maybe<SortOrder>;
};

export type QuoteQuoteLinesOrderByInput = {
  material?: Maybe<SortOrder>;
};

export type QuoteLineWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type QuoteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  quoteReference?: Maybe<Scalars['Int']>;
};

export type QuoteWhereInput = {
  AND?: Maybe<Array<QuoteWhereInput>>;
  OR?: Maybe<Array<QuoteWhereInput>>;
  NOT?: Maybe<Array<QuoteWhereInput>>;
  id?: Maybe<StringFilter>;
  quoteReference?: Maybe<IntFilter>;
  contact?: Maybe<StringFilter>;
  quotedBy?: Maybe<StringFilter>;
  creationDate?: Maybe<DateTimeFilter>;
  notes?: Maybe<StringFilter>;
  closed?: Maybe<BoolFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  quoteLines?: Maybe<QuoteLineListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QueryQuotesOrderByInput = {
  creationDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type SiteVisitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SiteVisitWhereInput = {
  AND?: Maybe<Array<SiteVisitWhereInput>>;
  OR?: Maybe<Array<SiteVisitWhereInput>>;
  NOT?: Maybe<Array<SiteVisitWhereInput>>;
  id?: Maybe<StringFilter>;
  visitDate?: Maybe<DateTimeFilter>;
  visitor?: Maybe<StringFilter>;
  comments?: Maybe<StringFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QuerySiteVisitsOrderByInput = {
  visitDate?: Maybe<SortOrder>;
};

export enum Currency {
  Gbp = 'GBP',
  Usd = 'USD',
  Eur = 'EUR'
}

export type SupplierWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['Int']>;
};

export type SupplierAddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SupplierAddressWhereInput = {
  AND?: Maybe<Array<SupplierAddressWhereInput>>;
  OR?: Maybe<Array<SupplierAddressWhereInput>>;
  NOT?: Maybe<Array<SupplierAddressWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumSupplierAddressTypeEnumFilter>;
  siteName?: Maybe<StringNullableFilter>;
  building?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  district?: Maybe<StringNullableFilter>;
  town?: Maybe<StringNullableFilter>;
  county?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  effectiveFrom?: Maybe<DateTimeFilter>;
  effectiveTo?: Maybe<DateTimeNullableFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  collectionNotes?: Maybe<CollectionNoteListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QuerySupplierAddressesOrderByInput = {
  type?: Maybe<SortOrder>;
  siteName?: Maybe<SortOrder>;
};

export type SupplierContainerWhereInput = {
  AND?: Maybe<Array<SupplierContainerWhereInput>>;
  OR?: Maybe<Array<SupplierContainerWhereInput>>;
  NOT?: Maybe<Array<SupplierContainerWhereInput>>;
  id?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  containerId?: Maybe<StringFilter>;
  container?: Maybe<ContainerWhereInput>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QuerySupplierContainersOrderByInput = {
  createdAt?: Maybe<SortOrder>;
};

export type SupplierContactWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SupplierContactWhereInput = {
  AND?: Maybe<Array<SupplierContactWhereInput>>;
  OR?: Maybe<Array<SupplierContactWhereInput>>;
  NOT?: Maybe<Array<SupplierContactWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type QuerySupplierContactsOrderByInput = {
  name?: Maybe<SortOrder>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type EnumContainerTypeEnumFilter = {
  equals?: Maybe<ContainerTypeEnum>;
  in?: Maybe<Array<ContainerTypeEnum>>;
  notIn?: Maybe<Array<ContainerTypeEnum>>;
  not?: Maybe<NestedEnumContainerTypeEnumFilter>;
};

export type SupplierContainerListRelationFilter = {
  every?: Maybe<SupplierContainerWhereInput>;
  some?: Maybe<SupplierContainerWhereInput>;
  none?: Maybe<SupplierContainerWhereInput>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type CollectionNoteLineListRelationFilter = {
  every?: Maybe<CollectionNoteLineWhereInput>;
  some?: Maybe<CollectionNoteLineWhereInput>;
  none?: Maybe<CollectionNoteLineWhereInput>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type DecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type SupplierWhereInput = {
  AND?: Maybe<Array<SupplierWhereInput>>;
  OR?: Maybe<Array<SupplierWhereInput>>;
  NOT?: Maybe<Array<SupplierWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  vat?: Maybe<FloatFilter>;
  sageReference?: Maybe<StringNullableFilter>;
  internalId?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  quotes?: Maybe<QuoteListRelationFilter>;
  supplierAddresses?: Maybe<SupplierAddressListRelationFilter>;
  collectionNotes?: Maybe<CollectionNoteListRelationFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  siteVisits?: Maybe<SiteVisitListRelationFilter>;
  supplierContainers?: Maybe<SupplierContainerListRelationFilter>;
  SupplierContact?: Maybe<SupplierContactListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type QuoteLineListRelationFilter = {
  every?: Maybe<QuoteLineWhereInput>;
  some?: Maybe<QuoteLineWhereInput>;
  none?: Maybe<QuoteLineWhereInput>;
};

export type EnumSupplierAddressTypeEnumFilter = {
  equals?: Maybe<SupplierAddressTypeEnum>;
  in?: Maybe<Array<SupplierAddressTypeEnum>>;
  notIn?: Maybe<Array<SupplierAddressTypeEnum>>;
  not?: Maybe<NestedEnumSupplierAddressTypeEnumFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type CollectionNoteListRelationFilter = {
  every?: Maybe<CollectionNoteWhereInput>;
  some?: Maybe<CollectionNoteWhereInput>;
  none?: Maybe<CollectionNoteWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedEnumContainerTypeEnumFilter = {
  equals?: Maybe<ContainerTypeEnum>;
  in?: Maybe<Array<ContainerTypeEnum>>;
  notIn?: Maybe<Array<ContainerTypeEnum>>;
  not?: Maybe<NestedEnumContainerTypeEnumFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedDecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type QuoteListRelationFilter = {
  every?: Maybe<QuoteWhereInput>;
  some?: Maybe<QuoteWhereInput>;
  none?: Maybe<QuoteWhereInput>;
};

export type SupplierAddressListRelationFilter = {
  every?: Maybe<SupplierAddressWhereInput>;
  some?: Maybe<SupplierAddressWhereInput>;
  none?: Maybe<SupplierAddressWhereInput>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type SiteVisitListRelationFilter = {
  every?: Maybe<SiteVisitWhereInput>;
  some?: Maybe<SiteVisitWhereInput>;
  none?: Maybe<SiteVisitWhereInput>;
};

export type SupplierContactListRelationFilter = {
  every?: Maybe<SupplierContactWhereInput>;
  some?: Maybe<SupplierContactWhereInput>;
  none?: Maybe<SupplierContactWhereInput>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type QuoteLineWhereInput = {
  AND?: Maybe<Array<QuoteLineWhereInput>>;
  OR?: Maybe<Array<QuoteLineWhereInput>>;
  NOT?: Maybe<Array<QuoteLineWhereInput>>;
  id?: Maybe<StringFilter>;
  material?: Maybe<StringFilter>;
  price?: Maybe<StringFilter>;
  tonnage?: Maybe<StringFilter>;
  quoteId?: Maybe<StringFilter>;
  quote?: Maybe<QuoteWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NestedEnumSupplierAddressTypeEnumFilter = {
  equals?: Maybe<SupplierAddressTypeEnum>;
  in?: Maybe<Array<SupplierAddressTypeEnum>>;
  notIn?: Maybe<Array<SupplierAddressTypeEnum>>;
  not?: Maybe<NestedEnumSupplierAddressTypeEnumFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type CollectionNoteWhereInput = {
  AND?: Maybe<Array<CollectionNoteWhereInput>>;
  OR?: Maybe<Array<CollectionNoteWhereInput>>;
  NOT?: Maybe<Array<CollectionNoteWhereInput>>;
  id?: Maybe<StringFilter>;
  referenceId?: Maybe<IntFilter>;
  creationDate?: Maybe<DateTimeFilter>;
  printedAt?: Maybe<DateTimeNullableFilter>;
  closed?: Maybe<BoolFilter>;
  return?: Maybe<BoolFilter>;
  supplierId?: Maybe<StringFilter>;
  supplier?: Maybe<SupplierWhereInput>;
  supplierAddressId?: Maybe<StringFilter>;
  supplierAddress?: Maybe<SupplierAddressWhereInput>;
  collectionNoteLines?: Maybe<CollectionNoteLineListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type Query = {
  __typename?: 'Query';
  collectionNote?: Maybe<CollectionNote>;
  collectionNoteLines: Array<CollectionNoteLine>;
  collectionNoteCount?: Maybe<Scalars['Int']>;
  collectionNotes?: Maybe<Array<Maybe<CollectionNote>>>;
  container?: Maybe<Container>;
  containers: Array<Container>;
  containerCount: Scalars['Int'];
  ewcCode?: Maybe<EwcCode>;
  ewcCodes: Array<EwcCode>;
  ewcCodeCount: Scalars['Int'];
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  invoiceCount?: Maybe<Scalars['Int']>;
  nominalCode?: Maybe<NominalCode>;
  nominalCodes: Array<NominalCode>;
  nominalCodeCount: Scalars['Int'];
  quote?: Maybe<Quote>;
  quotes: Array<Quote>;
  quoteCount: Scalars['Int'];
  blankCollectionNotesReport?: Maybe<Report>;
  collectionNotesReport?: Maybe<Report>;
  deliveryCollectionNotesReport?: Maybe<Report>;
  invoiceReport?: Maybe<Report>;
  outstandingInvoiceReport?: Maybe<Report>;
  supplierReengagementReport?: Maybe<Report>;
  siteVisit?: Maybe<SiteVisit>;
  siteVisits: Array<SiteVisit>;
  siteVisitCount: Scalars['Int'];
  supplier?: Maybe<Supplier>;
  supplierCount?: Maybe<Scalars['Int']>;
  suppliers: Array<Supplier>;
  supplierAddress?: Maybe<SupplierAddress>;
  supplierAddresses: Array<SupplierAddress>;
  supplierAddressCount: Scalars['Int'];
  supplierContainer?: Maybe<SupplierContainer>;
  supplierContainers: Array<SupplierContainer>;
  supplierContainerCount: Scalars['Int'];
  supplierContact?: Maybe<SupplierContact>;
  supplierContacts: Array<SupplierContact>;
  supplierContactCount: Scalars['Int'];
};


export type QueryCollectionNoteArgs = {
  where: CollectionNoteWhereUniqueInput;
};


export type QueryCollectionNoteLinesArgs = {
  where?: Maybe<CollectionNoteLineWhereInput>;
};


export type QueryCollectionNoteCountArgs = {
  input: ListCollectionNoteCountInput;
};


export type QueryCollectionNotesArgs = {
  input: ListCollectionNotesInput;
};


export type QueryContainerArgs = {
  where: ContainerWhereUniqueInput;
};


export type QueryContainersArgs = {
  where?: Maybe<ContainerWhereInput>;
  orderBy?: Maybe<Array<QueryContainersOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ContainerWhereUniqueInput>;
};


export type QueryEwcCodeArgs = {
  where: EwcCodeWhereUniqueInput;
};


export type QueryEwcCodesArgs = {
  where?: Maybe<EwcCodeWhereInput>;
  orderBy?: Maybe<Array<QueryEwcCodesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EwcCodeWhereUniqueInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<QueryInvoicesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
};


export type QueryInvoiceCountArgs = {
  input: ListInvoiceCountInput;
};


export type QueryNominalCodeArgs = {
  where: NominalCodeWhereUniqueInput;
};


export type QueryNominalCodesArgs = {
  where?: Maybe<NominalCodeWhereInput>;
  orderBy?: Maybe<Array<QueryNominalCodesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<NominalCodeWhereUniqueInput>;
};


export type QueryQuoteArgs = {
  where: QuoteWhereUniqueInput;
};


export type QueryQuotesArgs = {
  where?: Maybe<QuoteWhereInput>;
  orderBy?: Maybe<Array<QueryQuotesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuoteWhereUniqueInput>;
};


export type QueryQuoteCountArgs = {
  slug: Scalars['String'];
};


export type QueryBlankCollectionNotesReportArgs = {
  input: BlankCollectionNotesReportInput;
};


export type QueryCollectionNotesReportArgs = {
  input: CollectionNotesReportInput;
};


export type QueryDeliveryCollectionNotesReportArgs = {
  input: DeliveryCollectionNotesReportInput;
};


export type QueryInvoiceReportArgs = {
  input: InvoiceReportInput;
};


export type QuerySupplierReengagementReportArgs = {
  input: SupplierReengagementReportInput;
};


export type QuerySiteVisitArgs = {
  where: SiteVisitWhereUniqueInput;
};


export type QuerySiteVisitsArgs = {
  where?: Maybe<SiteVisitWhereInput>;
  orderBy?: Maybe<Array<QuerySiteVisitsOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SiteVisitWhereUniqueInput>;
};


export type QuerySiteVisitCountArgs = {
  slug: Scalars['String'];
};


export type QuerySupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type QuerySupplierCountArgs = {
  input: ListSupplierCountInput;
};


export type QuerySuppliersArgs = {
  input: ListSupplierInput;
};


export type QuerySupplierAddressArgs = {
  where: SupplierAddressWhereUniqueInput;
};


export type QuerySupplierAddressesArgs = {
  where?: Maybe<SupplierAddressWhereInput>;
  orderBy?: Maybe<Array<QuerySupplierAddressesOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SupplierAddressWhereUniqueInput>;
};


export type QuerySupplierAddressCountArgs = {
  slug: Scalars['String'];
};


export type QuerySupplierContainerArgs = {
  where: SupplierContainerWhereUniqueInput;
};


export type QuerySupplierContainersArgs = {
  where?: Maybe<SupplierContainerWhereInput>;
  orderBy?: Maybe<Array<QuerySupplierContainersOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SupplierContainerWhereUniqueInput>;
};


export type QuerySupplierContainerCountArgs = {
  slug: Scalars['String'];
};


export type QuerySupplierContactArgs = {
  where: SupplierContactWhereUniqueInput;
};


export type QuerySupplierContactsArgs = {
  where?: Maybe<SupplierContactWhereInput>;
  orderBy?: Maybe<Array<QuerySupplierContactsOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SupplierContactWhereUniqueInput>;
};


export type QuerySupplierContactCountArgs = {
  slug: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollectionNote?: Maybe<CollectionNote>;
  markCollectionNoteAsPrinted?: Maybe<CollectionNote>;
  updateCollectionNote?: Maybe<CollectionNote>;
  updateCollectionNoteLines?: Maybe<CollectionNote>;
  toggleCollectionNoteClosedStatus?: Maybe<CollectionNote>;
  createContainer?: Maybe<Container>;
  updateContainer?: Maybe<Container>;
  createEWCCode?: Maybe<EwcCode>;
  updateEWCCode?: Maybe<EwcCode>;
  createInvoice?: Maybe<Invoice>;
  deleteInvoice?: Maybe<Invoice>;
  matchCollectionNotesToInvoice?: Maybe<Invoice>;
  removeMatchedCollectionNotesFromInvoice?: Maybe<Invoice>;
  toggleInvoiceCompletedStatus?: Maybe<Invoice>;
  updateInvoice?: Maybe<Invoice>;
  createNominalCode?: Maybe<NominalCode>;
  updateNominalCode?: Maybe<NominalCode>;
  createQuote?: Maybe<Quote>;
  duplicateQuote?: Maybe<Quote>;
  updateQuote?: Maybe<Quote>;
  updateQuoteLines?: Maybe<Quote>;
  toggleQuoteClosedStatus?: Maybe<Quote>;
  createSiteVisit?: Maybe<SiteVisit>;
  updateSiteVisit?: Maybe<SiteVisit>;
  createSupplier?: Maybe<Supplier>;
  updateSupplier?: Maybe<Supplier>;
  createSupplierAddress?: Maybe<SupplierAddress>;
  updateSupplierAddress?: Maybe<SupplierAddress>;
  createSupplierContainer?: Maybe<SupplierContainer>;
  updateSupplierContainer?: Maybe<SupplierContainer>;
  createSupplierContact?: Maybe<SupplierContact>;
  deleteSupplierContact?: Maybe<SupplierContact>;
  updateSupplierContact?: Maybe<SupplierContact>;
};


export type MutationCreateCollectionNoteArgs = {
  input: CreateCollectionNoteInput;
};


export type MutationMarkCollectionNoteAsPrintedArgs = {
  input: MarkCollectionNoteAsPrinted;
};


export type MutationUpdateCollectionNoteArgs = {
  input: UpdateCollectionNoteInput;
};


export type MutationUpdateCollectionNoteLinesArgs = {
  input: UpdateCollectionNoteLinesInput;
};


export type MutationToggleCollectionNoteClosedStatusArgs = {
  input: ToggleCollectionNoteClosedStatus;
};


export type MutationCreateContainerArgs = {
  input: CreateContainerInput;
};


export type MutationUpdateContainerArgs = {
  input: UpdateContainerInput;
};


export type MutationCreateEwcCodeArgs = {
  input: CreateEwcCodeInput;
};


export type MutationUpdateEwcCodeArgs = {
  input: UpdateEwcCodeInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput;
};


export type MutationMatchCollectionNotesToInvoiceArgs = {
  input: MatchCollectionNotesToInvoiceInput;
};


export type MutationRemoveMatchedCollectionNotesFromInvoiceArgs = {
  input: RemoveMatchedCollectionNotesFromInvoiceInput;
};


export type MutationToggleInvoiceCompletedStatusArgs = {
  input: ToggleInvoiceCompletedStatus;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationCreateNominalCodeArgs = {
  input: CreateNominalCodeInput;
};


export type MutationUpdateNominalCodeArgs = {
  input: UpdateNominalCodeInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationDuplicateQuoteArgs = {
  input: DuplicateQuoteInput;
};


export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteInput;
};


export type MutationUpdateQuoteLinesArgs = {
  input: UpdateQuoteLinesInput;
};


export type MutationToggleQuoteClosedStatusArgs = {
  input: ToggleQuoteClosedStatus;
};


export type MutationCreateSiteVisitArgs = {
  input: CreateSiteVisitInput;
};


export type MutationUpdateSiteVisitArgs = {
  input: UpdateSiteVisitInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


export type MutationCreateSupplierAddressArgs = {
  input: CreateSupplierAddressInput;
};


export type MutationUpdateSupplierAddressArgs = {
  input: UpdateSupplierAddressInput;
};


export type MutationCreateSupplierContainerArgs = {
  input: CreateSupplierContainerInput;
};


export type MutationUpdateSupplierContainerArgs = {
  input: UpdateSupplierContainerInput;
};


export type MutationCreateSupplierContactArgs = {
  input: CreateSupplierContactInput;
};


export type MutationDeleteSupplierContactArgs = {
  input: DeleteSupplierContactInput;
};


export type MutationUpdateSupplierContactArgs = {
  input: UpdateSupplierContactInput;
};

export type CollectionNoteLinesUpdateLinesMutationVariables = Exact<{
  input: UpdateCollectionNoteLinesInput;
}>;


export type CollectionNoteLinesUpdateLinesMutation = (
  { __typename?: 'Mutation' }
  & { updateCollectionNoteLines?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id'>
    & { collectionNoteLines: Array<(
      { __typename?: 'CollectionNoteLine' }
      & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'invoiced' | 'value' | 'vat' | 'total'>
      & { ewcCode?: Maybe<(
        { __typename?: 'EWCCode' }
        & Pick<EwcCode, 'id' | 'code' | 'product'>
      )>, nominalCode?: Maybe<(
        { __typename?: 'NominalCode' }
        & Pick<NominalCode, 'id' | 'code'>
      )> }
    )> }
  )> }
);

export type SupplierFilterFormGetSuppliersQueryVariables = Exact<{
  input: ListSupplierInput;
}>;


export type SupplierFilterFormGetSuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'internalId'>
  )> }
);

export type GetNoteSearchModalGetCollectionNoteQueryVariables = Exact<{
  referenceId: Scalars['Int'];
}>;


export type GetNoteSearchModalGetCollectionNoteQuery = (
  { __typename?: 'Query' }
  & { collectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'referenceId'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'slug' | 'name'>
    ) }
  )> }
);

export type QuoteLinesUpdateQuoteLinesMutationVariables = Exact<{
  input: UpdateQuoteLinesInput;
}>;


export type QuoteLinesUpdateQuoteLinesMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteLines?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
    & { quoteLines: Array<(
      { __typename?: 'QuoteLine' }
      & Pick<QuoteLine, 'id' | 'material' | 'tonnage' | 'price'>
    )> }
  )> }
);

export type GetSuppliersQueryVariables = Exact<{
  input: ListSupplierInput;
}>;


export type GetSuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'slug' | 'name'>
  )> }
);

export type AppGetSupplierQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type AppGetSupplierQuery = (
  { __typename?: 'Query' }
  & { supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'currency'>
  )> }
);

export type GetBlankCollectionNotesReportInfoQueryVariables = Exact<{
  input: BlankCollectionNotesReportInput;
}>;


export type GetBlankCollectionNotesReportInfoQuery = (
  { __typename?: 'Query' }
  & { blankCollectionNotesReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type GetDeliveryCollectionNotesReportInfoQueryVariables = Exact<{
  input: DeliveryCollectionNotesReportInput;
}>;


export type GetDeliveryCollectionNotesReportInfoQuery = (
  { __typename?: 'Query' }
  & { deliveryCollectionNotesReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type GetInvoiceReportInfoQueryVariables = Exact<{
  input: InvoiceReportInput;
}>;


export type GetInvoiceReportInfoQuery = (
  { __typename?: 'Query' }
  & { invoiceReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type GetCollectionNotesReportInfoQueryVariables = Exact<{
  input: CollectionNotesReportInput;
}>;


export type GetCollectionNotesReportInfoQuery = (
  { __typename?: 'Query' }
  & { collectionNotesReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type GetoutstandingInvoiceReportInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetoutstandingInvoiceReportInfoQuery = (
  { __typename?: 'Query' }
  & { outstandingInvoiceReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type GetSupplierReengagementReportInfoQueryVariables = Exact<{
  input: SupplierReengagementReportInput;
}>;


export type GetSupplierReengagementReportInfoQuery = (
  { __typename?: 'Query' }
  & { supplierReengagementReport?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'url'>
  )> }
);

export type CreateSupplierAddressMutationVariables = Exact<{
  input: CreateSupplierAddressInput;
}>;


export type CreateSupplierAddressMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierAddress?: Maybe<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id'>
  )> }
);

export type AddressEditGetSupplierAddressQueryVariables = Exact<{
  addressId: Scalars['String'];
}>;


export type AddressEditGetSupplierAddressQuery = (
  { __typename?: 'Query' }
  & { supplierAddress?: Maybe<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id' | 'type' | 'siteName' | 'building' | 'street' | 'district' | 'town' | 'county' | 'postalCode' | 'country' | 'notes'>
  )> }
);

export type AddressEditUpdateSupplierAddressMutationVariables = Exact<{
  input: UpdateSupplierAddressInput;
}>;


export type AddressEditUpdateSupplierAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierAddress?: Maybe<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id'>
  )> }
);

export type AddressListGetAddressesQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AddressListGetAddressesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierAddressCount'>
  & { supplierAddresses: Array<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id' | 'siteName' | 'type' | 'building' | 'street' | 'district' | 'town' | 'county' | 'postalCode' | 'country'>
  )> }
);

export type GetSupplierAddressQueryVariables = Exact<{
  addressId: Scalars['String'];
}>;


export type GetSupplierAddressQuery = (
  { __typename?: 'Query' }
  & { supplierAddress?: Maybe<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id' | 'type' | 'siteName' | 'building' | 'street' | 'district' | 'town' | 'county' | 'postalCode' | 'country' | 'notes'>
  )> }
);

export type ContainerCreationPageCreateContainerMutationVariables = Exact<{
  input: CreateContainerInput;
}>;


export type ContainerCreationPageCreateContainerMutation = (
  { __typename?: 'Mutation' }
  & { createContainer?: Maybe<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )> }
);

export type ContainerEditPageGetContainerQueryVariables = Exact<{
  containerId: Scalars['String'];
}>;


export type ContainerEditPageGetContainerQuery = (
  { __typename?: 'Query' }
  & { container?: Maybe<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )> }
);

export type ContainerEditPageUpdateContainerMutationVariables = Exact<{
  input: UpdateContainerInput;
}>;


export type ContainerEditPageUpdateContainerMutation = (
  { __typename?: 'Mutation' }
  & { updateContainer?: Maybe<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )> }
);

export type ContainerListGetContainersQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ContainerListGetContainersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'containerCount'>
  & { containers: Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )> }
);

export type EwcCodeCreationPageCreateEwcCodeMutationVariables = Exact<{
  input: CreateEwcCodeInput;
}>;


export type EwcCodeCreationPageCreateEwcCodeMutation = (
  { __typename?: 'Mutation' }
  & { createEWCCode?: Maybe<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id'>
  )> }
);

export type EwcCodeEditPageGetEwcCodeQueryVariables = Exact<{
  ewcCodeId: Scalars['String'];
}>;


export type EwcCodeEditPageGetEwcCodeQuery = (
  { __typename?: 'Query' }
  & { ewcCode?: Maybe<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id' | 'code' | 'product'>
  )> }
);

export type EwcCodeEditPageUpdateEwcCodeMutationVariables = Exact<{
  input: UpdateEwcCodeInput;
}>;


export type EwcCodeEditPageUpdateEwcCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateEWCCode?: Maybe<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id'>
  )> }
);

export type GetEwcCodesQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetEwcCodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ewcCodeCount'>
  & { ewcCodes: Array<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id' | 'code' | 'product'>
  )> }
);

export type NominalCodeCreationPageCreateNominalCodeMutationVariables = Exact<{
  input: CreateNominalCodeInput;
}>;


export type NominalCodeCreationPageCreateNominalCodeMutation = (
  { __typename?: 'Mutation' }
  & { createNominalCode?: Maybe<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id'>
  )> }
);

export type NominalCodeEditPageGetNominalCodeQueryVariables = Exact<{
  nominalCodeId: Scalars['String'];
}>;


export type NominalCodeEditPageGetNominalCodeQuery = (
  { __typename?: 'Query' }
  & { nominalCode?: Maybe<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id' | 'code'>
  )> }
);

export type NominalCodeEditPageUpdateNominalCodeMutationVariables = Exact<{
  input: UpdateNominalCodeInput;
}>;


export type NominalCodeEditPageUpdateNominalCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateNominalCode?: Maybe<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id'>
  )> }
);

export type GetNominalCodesQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetNominalCodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nominalCodeCount'>
  & { nominalCodes: Array<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id' | 'code'>
  )> }
);

export type SupplierCreationPageCreateSupplierMutationVariables = Exact<{
  input: CreateSupplierInput;
}>;


export type SupplierCreationPageCreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'currency'>
  )> }
);

export type SupplierEditPageGetSupplierQueryVariables = Exact<{
  supplierSlug: Scalars['String'];
}>;


export type SupplierEditPageGetSupplierQuery = (
  { __typename?: 'Query' }
  & { supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'sageReference' | 'currency'>
  )> }
);

export type SupplierEditPageUpdateSupplierMutationVariables = Exact<{
  input: UpdateSupplierInput;
}>;


export type SupplierEditPageUpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'sageReference' | 'currency'>
  )> }
);

export type SupplierListGetSuppliersQueryVariables = Exact<{
  input: ListSupplierInput;
  countInput: ListSupplierCountInput;
}>;


export type SupplierListGetSuppliersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierCount'>
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'sageReference' | 'currency'>
  )> }
);

export type SupplierPageGetSupplierQueryVariables = Exact<{
  supplierSlug: Scalars['String'];
}>;


export type SupplierPageGetSupplierQuery = (
  { __typename?: 'Query' }
  & { supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'slug' | 'vat' | 'sageReference' | 'currency'>
  )> }
);

export type CollectionNoteCreationPageGetDataQueryVariables = Exact<{
  supplierSlug: Scalars['String'];
}>;


export type CollectionNoteCreationPageGetDataQuery = (
  { __typename?: 'Query' }
  & { supplierAddresses: Array<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id' | 'type' | 'siteName' | 'building' | 'postalCode'>
  )>, ewcCodes: Array<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id' | 'code' | 'product'>
  )>, nominalCodes: Array<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id' | 'code'>
  )> }
);

export type CreateCollectionNoteMutationVariables = Exact<{
  input: CreateCollectionNoteInput;
}>;


export type CreateCollectionNoteMutation = (
  { __typename?: 'Mutation' }
  & { createCollectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id'>
  )> }
);

export type CollectionNoteEditPageGetDataQueryVariables = Exact<{
  collectionNoteId: Scalars['String'];
  supplierSlug: Scalars['String'];
}>;


export type CollectionNoteEditPageGetDataQuery = (
  { __typename?: 'Query' }
  & { collectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'displayReference' | 'creationDate' | 'return'>
    & { supplierAddress: (
      { __typename?: 'SupplierAddress' }
      & Pick<SupplierAddress, 'id'>
    ), collectionNoteLines: Array<(
      { __typename?: 'CollectionNoteLine' }
      & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'invoiced' | 'value' | 'vat' | 'total'>
      & { ewcCode?: Maybe<(
        { __typename?: 'EWCCode' }
        & Pick<EwcCode, 'id'>
      )>, nominalCode?: Maybe<(
        { __typename?: 'NominalCode' }
        & Pick<NominalCode, 'id'>
      )> }
    )> }
  )>, supplierAddresses: Array<(
    { __typename?: 'SupplierAddress' }
    & Pick<SupplierAddress, 'id' | 'type' | 'siteName' | 'building' | 'postalCode'>
  )>, ewcCodes: Array<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id' | 'code' | 'product'>
  )>, nominalCodes: Array<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id' | 'code'>
  )> }
);

export type CollectionNoteEditPageUpdateCollectionNoteMutationVariables = Exact<{
  input: UpdateCollectionNoteInput;
}>;


export type CollectionNoteEditPageUpdateCollectionNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateCollectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id'>
  )> }
);

export type GetCollectionNotesQueryVariables = Exact<{
  input: ListCollectionNotesInput;
  countInput: ListCollectionNoteCountInput;
}>;


export type GetCollectionNotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'collectionNoteCount'>
  & { collectionNotes?: Maybe<Array<Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'displayReference' | 'linesCount' | 'creationDate' | 'printed' | 'net' | 'vat' | 'gross' | 'invoiced' | 'closed'>
    & { collectionNoteLines: Array<(
      { __typename?: 'CollectionNoteLine' }
      & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'invoiced' | 'value' | 'vat' | 'total'>
    )> }
  )>>> }
);

export type GetCollectionNoteQueryVariables = Exact<{
  collectionNoteId: Scalars['String'];
}>;


export type GetCollectionNoteQuery = (
  { __typename?: 'Query' }
  & { collectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'linesCount' | 'creationDate' | 'printed' | 'printedAt' | 'net' | 'vat' | 'gross' | 'invoiced' | 'closed' | 'displayReference'>
    & { collectionNoteLines: Array<(
      { __typename?: 'CollectionNoteLine' }
      & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'invoiced' | 'value' | 'vat' | 'total'>
      & { ewcCode?: Maybe<(
        { __typename?: 'EWCCode' }
        & Pick<EwcCode, 'id'>
      )>, nominalCode?: Maybe<(
        { __typename?: 'NominalCode' }
        & Pick<NominalCode, 'id'>
      )>, invoice?: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )> }
    )>, supplierAddress: (
      { __typename?: 'SupplierAddress' }
      & Pick<SupplierAddress, 'id' | 'type' | 'siteName' | 'building' | 'postalCode'>
    ) }
  )>, ewcCodes: Array<(
    { __typename?: 'EWCCode' }
    & Pick<EwcCode, 'id' | 'code' | 'product'>
  )>, nominalCodes: Array<(
    { __typename?: 'NominalCode' }
    & Pick<NominalCode, 'id' | 'code'>
  )> }
);

export type GetCollectionNotePdfQueryVariables = Exact<{
  collectionNoteId: Scalars['String'];
}>;


export type GetCollectionNotePdfQuery = (
  { __typename?: 'Query' }
  & { collectionNote?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'displayReference' | 'pdf'>
  )> }
);

export type MarkCollectionNoteAsPrintedMutationVariables = Exact<{
  collectionNoteId: Scalars['String'];
}>;


export type MarkCollectionNoteAsPrintedMutation = (
  { __typename?: 'Mutation' }
  & { markCollectionNoteAsPrinted?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'printed'>
  )> }
);

export type ToggleCollectionNoteClosedStatusMutationVariables = Exact<{
  collectionNoteId: Scalars['String'];
}>;


export type ToggleCollectionNoteClosedStatusMutation = (
  { __typename?: 'Mutation' }
  & { toggleCollectionNoteClosedStatus?: Maybe<(
    { __typename?: 'CollectionNote' }
    & Pick<CollectionNote, 'id' | 'closed'>
  )> }
);

export type CreateSupplierContactMutationVariables = Exact<{
  input: CreateSupplierContactInput;
}>;


export type CreateSupplierContactMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierContact?: Maybe<(
    { __typename?: 'SupplierContact' }
    & Pick<SupplierContact, 'id'>
  )> }
);

export type ContactEditGetSupplierContactQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;


export type ContactEditGetSupplierContactQuery = (
  { __typename?: 'Query' }
  & { supplierContact?: Maybe<(
    { __typename?: 'SupplierContact' }
    & Pick<SupplierContact, 'id' | 'name' | 'email' | 'notes' | 'phoneNumber'>
  )> }
);

export type ContactEditUpdateSupplierContactMutationVariables = Exact<{
  input: UpdateSupplierContactInput;
}>;


export type ContactEditUpdateSupplierContactMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierContact?: Maybe<(
    { __typename?: 'SupplierContact' }
    & Pick<SupplierContact, 'id'>
  )> }
);

export type DeleteSupplierContactMutationVariables = Exact<{
  input: DeleteSupplierContactInput;
}>;


export type DeleteSupplierContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteSupplierContact?: Maybe<(
    { __typename?: 'SupplierContact' }
    & Pick<SupplierContact, 'id'>
  )> }
);

export type ContactListGetContactsQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ContactListGetContactsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierContactCount'>
  & { supplierContacts: Array<(
    { __typename?: 'SupplierContact' }
    & Pick<SupplierContact, 'id' | 'name' | 'email' | 'notes' | 'phoneNumber'>
  )> }
);

export type GetInvoiceCollectionNoteMatchQueryVariables = Exact<{
  supplierSlug: Scalars['String'];
  invoiceId: Scalars['String'];
}>;


export type GetInvoiceCollectionNoteMatchQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'reference' | 'net' | 'vat' | 'gross'>
  )>, collectionNoteLines: Array<(
    { __typename?: 'CollectionNoteLine' }
    & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'value' | 'vat' | 'total'>
    & { collectionNote: (
      { __typename?: 'CollectionNote' }
      & Pick<CollectionNote, 'id' | 'referenceId' | 'creationDate'>
    ) }
  )> }
);

export type MatchInvoiceToCollectionNoteLinesMutationVariables = Exact<{
  input: MatchCollectionNotesToInvoiceInput;
}>;


export type MatchInvoiceToCollectionNoteLinesMutation = (
  { __typename?: 'Mutation' }
  & { matchCollectionNotesToInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'completed'>
  )> }
);

export type CreateInvoiceMutationVariables = Exact<{
  input: CreateInvoiceInput;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type InvoiceEditPageGetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type InvoiceEditPageGetInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'reference' | 'supplierInvoiceNumber' | 'net' | 'vat' | 'gross' | 'comments' | 'completed'>
  )> }
);

export type InvoiceEditPageUpdateInvoiceMutationVariables = Exact<{
  input: UpdateInvoiceInput;
}>;


export type InvoiceEditPageUpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type GetInvoicesQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueryInvoicesOrderByInput> | QueryInvoicesOrderByInput>;
  referenceFilter?: Maybe<Scalars['String']>;
  supplierInvoiceNumberFilter?: Maybe<Scalars['String']>;
  commentsFilter?: Maybe<Scalars['String']>;
  filter: Array<FilterInput> | FilterInput;
}>;


export type GetInvoicesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'invoiceCount'>
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'reference' | 'supplierInvoiceNumber' | 'net' | 'vat' | 'gross' | 'comments' | 'completed'>
  )> }
);

export type GetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'reference' | 'supplierInvoiceNumber' | 'net' | 'vat' | 'gross' | 'completed' | 'comments' | 'hasMatchedCollectionNotes'>
    & { collectionNoteLines: Array<(
      { __typename?: 'CollectionNoteLine' }
      & Pick<CollectionNoteLine, 'id' | 'quantity' | 'description' | 'value' | 'vat' | 'total'>
      & { collectionNote: (
        { __typename?: 'CollectionNote' }
        & Pick<CollectionNote, 'id' | 'referenceId'>
      ), ewcCode?: Maybe<(
        { __typename?: 'EWCCode' }
        & Pick<EwcCode, 'code'>
      )>, nominalCode?: Maybe<(
        { __typename?: 'NominalCode' }
        & Pick<NominalCode, 'code'>
      )> }
    )> }
  )> }
);

export type RemoveMatchedCollectionNotesFromInvoiceMutationVariables = Exact<{
  input: RemoveMatchedCollectionNotesFromInvoiceInput;
}>;


export type RemoveMatchedCollectionNotesFromInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { removeMatchedCollectionNotesFromInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'completed'>
  )> }
);

export type InvoicePageDeleteInvoiceMutationVariables = Exact<{
  input: DeleteInvoiceInput;
}>;


export type InvoicePageDeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type ToggleInvoiceCompletedStatusMutationVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type ToggleInvoiceCompletedStatusMutation = (
  { __typename?: 'Mutation' }
  & { toggleInvoiceCompletedStatus?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'completed'>
  )> }
);

export type CreateQuoteMutationVariables = Exact<{
  input: CreateQuoteInput;
}>;


export type CreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type QuoteEditPageGetQuoteQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type QuoteEditPageGetQuoteQuery = (
  { __typename?: 'Query' }
  & { quote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'quoteReference' | 'contact' | 'quotedBy' | 'creationDate' | 'closed' | 'notes'>
  )> }
);

export type QuoteEditPageUpdateQuoteMutationVariables = Exact<{
  input: UpdateQuoteInput;
}>;


export type QuoteEditPageUpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type GetQuotesQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetQuotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quoteCount'>
  & { quotes: Array<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'quoteReference' | 'contact' | 'quotedBy' | 'creationDate' | 'closed'>
  )> }
);

export type GetQuoteQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { quote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'quoteReference' | 'contact' | 'quotedBy' | 'creationDate' | 'closed' | 'notes'>
    & { quoteLines: Array<(
      { __typename?: 'QuoteLine' }
      & Pick<QuoteLine, 'id' | 'material' | 'tonnage' | 'price'>
    )> }
  )> }
);

export type GetQuotePdfQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type GetQuotePdfQuery = (
  { __typename?: 'Query' }
  & { quote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'quoteReference' | 'pdf'>
  )> }
);

export type DuplicateQuoteMutationVariables = Exact<{
  input: DuplicateQuoteInput;
}>;


export type DuplicateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { duplicateQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type ToggleQuoteClosedStatusMutationVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type ToggleQuoteClosedStatusMutation = (
  { __typename?: 'Mutation' }
  & { toggleQuoteClosedStatus?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'closed'>
  )> }
);

export type CreateSiteVisitMutationVariables = Exact<{
  input: CreateSiteVisitInput;
}>;


export type CreateSiteVisitMutation = (
  { __typename?: 'Mutation' }
  & { createSiteVisit?: Maybe<(
    { __typename?: 'SiteVisit' }
    & Pick<SiteVisit, 'id'>
  )> }
);

export type SiteVisitEditPageGetSiteVisitQueryVariables = Exact<{
  siteVisitId: Scalars['String'];
}>;


export type SiteVisitEditPageGetSiteVisitQuery = (
  { __typename?: 'Query' }
  & { siteVisit?: Maybe<(
    { __typename?: 'SiteVisit' }
    & Pick<SiteVisit, 'id' | 'visitor' | 'visitDate' | 'comments'>
  )> }
);

export type SiteVisitEditPageUpdateSiteVisitMutationVariables = Exact<{
  input: UpdateSiteVisitInput;
}>;


export type SiteVisitEditPageUpdateSiteVisitMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteVisit?: Maybe<(
    { __typename?: 'SiteVisit' }
    & Pick<SiteVisit, 'id'>
  )> }
);

export type GetSiteVisitsQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetSiteVisitsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteVisitCount'>
  & { siteVisits: Array<(
    { __typename?: 'SiteVisit' }
    & Pick<SiteVisit, 'id' | 'visitor' | 'visitDate' | 'comments' | 'truncatedComments'>
  )> }
);

export type CreateSupplierGetContainersQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateSupplierGetContainersQuery = (
  { __typename?: 'Query' }
  & { containers: Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )> }
);

export type CreateSupplierContainerMutationVariables = Exact<{
  input: CreateSupplierContainerInput;
}>;


export type CreateSupplierContainerMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierContainer?: Maybe<(
    { __typename?: 'SupplierContainer' }
    & Pick<SupplierContainer, 'id'>
  )> }
);

export type UpdateSupplierGetDataQueryVariables = Exact<{
  supplierContainerId: Scalars['String'];
}>;


export type UpdateSupplierGetDataQuery = (
  { __typename?: 'Query' }
  & { containers: Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'id' | 'name' | 'type'>
  )>, supplierContainer?: Maybe<(
    { __typename?: 'SupplierContainer' }
    & Pick<SupplierContainer, 'id' | 'quantity'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<Container, 'id'>
    ) }
  )> }
);

export type UpdateSupplierContainerMutationVariables = Exact<{
  input: UpdateSupplierContainerInput;
}>;


export type UpdateSupplierContainerMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierContainer?: Maybe<(
    { __typename?: 'SupplierContainer' }
    & Pick<SupplierContainer, 'id'>
  )> }
);

export type GetSupplierContainersQueryVariables = Exact<{
  slug: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetSupplierContainersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierContainerCount'>
  & { supplierContainers: Array<(
    { __typename?: 'SupplierContainer' }
    & Pick<SupplierContainer, 'id' | 'quantity'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<Container, 'name'>
    ) }
  )> }
);


export const CollectionNoteLinesUpdateLinesDocument = gql`
    mutation CollectionNoteLinesUpdateLines($input: UpdateCollectionNoteLinesInput!) {
  updateCollectionNoteLines(input: $input) {
    id
    collectionNoteLines {
      id
      quantity
      description
      invoiced
      value
      vat
      total
      ewcCode {
        id
        code
        product
      }
      nominalCode {
        id
        code
      }
    }
  }
}
    `;
export type CollectionNoteLinesUpdateLinesMutationFn = Apollo.MutationFunction<CollectionNoteLinesUpdateLinesMutation, CollectionNoteLinesUpdateLinesMutationVariables>;

/**
 * __useCollectionNoteLinesUpdateLinesMutation__
 *
 * To run a mutation, you first call `useCollectionNoteLinesUpdateLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectionNoteLinesUpdateLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectionNoteLinesUpdateLinesMutation, { data, loading, error }] = useCollectionNoteLinesUpdateLinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionNoteLinesUpdateLinesMutation(baseOptions?: Apollo.MutationHookOptions<CollectionNoteLinesUpdateLinesMutation, CollectionNoteLinesUpdateLinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectionNoteLinesUpdateLinesMutation, CollectionNoteLinesUpdateLinesMutationVariables>(CollectionNoteLinesUpdateLinesDocument, options);
      }
export type CollectionNoteLinesUpdateLinesMutationHookResult = ReturnType<typeof useCollectionNoteLinesUpdateLinesMutation>;
export type CollectionNoteLinesUpdateLinesMutationResult = Apollo.MutationResult<CollectionNoteLinesUpdateLinesMutation>;
export type CollectionNoteLinesUpdateLinesMutationOptions = Apollo.BaseMutationOptions<CollectionNoteLinesUpdateLinesMutation, CollectionNoteLinesUpdateLinesMutationVariables>;
export const SupplierFilterFormGetSuppliersDocument = gql`
    query SupplierFilterFormGetSuppliers($input: ListSupplierInput!) {
  suppliers(input: $input) {
    id
    name
    internalId
  }
}
    `;

/**
 * __useSupplierFilterFormGetSuppliersQuery__
 *
 * To run a query within a React component, call `useSupplierFilterFormGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierFilterFormGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierFilterFormGetSuppliersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierFilterFormGetSuppliersQuery(baseOptions: Apollo.QueryHookOptions<SupplierFilterFormGetSuppliersQuery, SupplierFilterFormGetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierFilterFormGetSuppliersQuery, SupplierFilterFormGetSuppliersQueryVariables>(SupplierFilterFormGetSuppliersDocument, options);
      }
export function useSupplierFilterFormGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierFilterFormGetSuppliersQuery, SupplierFilterFormGetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierFilterFormGetSuppliersQuery, SupplierFilterFormGetSuppliersQueryVariables>(SupplierFilterFormGetSuppliersDocument, options);
        }
export type SupplierFilterFormGetSuppliersQueryHookResult = ReturnType<typeof useSupplierFilterFormGetSuppliersQuery>;
export type SupplierFilterFormGetSuppliersLazyQueryHookResult = ReturnType<typeof useSupplierFilterFormGetSuppliersLazyQuery>;
export type SupplierFilterFormGetSuppliersQueryResult = Apollo.QueryResult<SupplierFilterFormGetSuppliersQuery, SupplierFilterFormGetSuppliersQueryVariables>;
export const GetNoteSearchModalGetCollectionNoteDocument = gql`
    query GetNoteSearchModalGetCollectionNote($referenceId: Int!) {
  collectionNote(where: {referenceId: $referenceId}) {
    id
    referenceId
    supplier {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useGetNoteSearchModalGetCollectionNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteSearchModalGetCollectionNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteSearchModalGetCollectionNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteSearchModalGetCollectionNoteQuery({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useGetNoteSearchModalGetCollectionNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteSearchModalGetCollectionNoteQuery, GetNoteSearchModalGetCollectionNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteSearchModalGetCollectionNoteQuery, GetNoteSearchModalGetCollectionNoteQueryVariables>(GetNoteSearchModalGetCollectionNoteDocument, options);
      }
export function useGetNoteSearchModalGetCollectionNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteSearchModalGetCollectionNoteQuery, GetNoteSearchModalGetCollectionNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteSearchModalGetCollectionNoteQuery, GetNoteSearchModalGetCollectionNoteQueryVariables>(GetNoteSearchModalGetCollectionNoteDocument, options);
        }
export type GetNoteSearchModalGetCollectionNoteQueryHookResult = ReturnType<typeof useGetNoteSearchModalGetCollectionNoteQuery>;
export type GetNoteSearchModalGetCollectionNoteLazyQueryHookResult = ReturnType<typeof useGetNoteSearchModalGetCollectionNoteLazyQuery>;
export type GetNoteSearchModalGetCollectionNoteQueryResult = Apollo.QueryResult<GetNoteSearchModalGetCollectionNoteQuery, GetNoteSearchModalGetCollectionNoteQueryVariables>;
export const QuoteLinesUpdateQuoteLinesDocument = gql`
    mutation QuoteLinesUpdateQuoteLines($input: UpdateQuoteLinesInput!) {
  updateQuoteLines(input: $input) {
    id
    quoteLines {
      id
      material
      tonnage
      price
    }
  }
}
    `;
export type QuoteLinesUpdateQuoteLinesMutationFn = Apollo.MutationFunction<QuoteLinesUpdateQuoteLinesMutation, QuoteLinesUpdateQuoteLinesMutationVariables>;

/**
 * __useQuoteLinesUpdateQuoteLinesMutation__
 *
 * To run a mutation, you first call `useQuoteLinesUpdateQuoteLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteLinesUpdateQuoteLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteLinesUpdateQuoteLinesMutation, { data, loading, error }] = useQuoteLinesUpdateQuoteLinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteLinesUpdateQuoteLinesMutation(baseOptions?: Apollo.MutationHookOptions<QuoteLinesUpdateQuoteLinesMutation, QuoteLinesUpdateQuoteLinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteLinesUpdateQuoteLinesMutation, QuoteLinesUpdateQuoteLinesMutationVariables>(QuoteLinesUpdateQuoteLinesDocument, options);
      }
export type QuoteLinesUpdateQuoteLinesMutationHookResult = ReturnType<typeof useQuoteLinesUpdateQuoteLinesMutation>;
export type QuoteLinesUpdateQuoteLinesMutationResult = Apollo.MutationResult<QuoteLinesUpdateQuoteLinesMutation>;
export type QuoteLinesUpdateQuoteLinesMutationOptions = Apollo.BaseMutationOptions<QuoteLinesUpdateQuoteLinesMutation, QuoteLinesUpdateQuoteLinesMutationVariables>;
export const GetSuppliersDocument = gql`
    query GetSuppliers($input: ListSupplierInput!) {
  suppliers(input: $input) {
    id
    slug
    name
  }
}
    `;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSuppliersQuery(baseOptions: Apollo.QueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
      }
export function useGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
        }
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<GetSuppliersQuery, GetSuppliersQueryVariables>;
export const AppGetSupplierDocument = gql`
    query AppGetSupplier($slug: String!) {
  supplier(where: {slug: $slug}) {
    id
    name
    slug
    vat
    currency
  }
}
    `;

/**
 * __useAppGetSupplierQuery__
 *
 * To run a query within a React component, call `useAppGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppGetSupplierQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAppGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<AppGetSupplierQuery, AppGetSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppGetSupplierQuery, AppGetSupplierQueryVariables>(AppGetSupplierDocument, options);
      }
export function useAppGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppGetSupplierQuery, AppGetSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppGetSupplierQuery, AppGetSupplierQueryVariables>(AppGetSupplierDocument, options);
        }
export type AppGetSupplierQueryHookResult = ReturnType<typeof useAppGetSupplierQuery>;
export type AppGetSupplierLazyQueryHookResult = ReturnType<typeof useAppGetSupplierLazyQuery>;
export type AppGetSupplierQueryResult = Apollo.QueryResult<AppGetSupplierQuery, AppGetSupplierQueryVariables>;
export const GetBlankCollectionNotesReportInfoDocument = gql`
    query GetBlankCollectionNotesReportInfo($input: BlankCollectionNotesReportInput!) {
  blankCollectionNotesReport(input: $input) {
    url
  }
}
    `;

/**
 * __useGetBlankCollectionNotesReportInfoQuery__
 *
 * To run a query within a React component, call `useGetBlankCollectionNotesReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlankCollectionNotesReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlankCollectionNotesReportInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBlankCollectionNotesReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetBlankCollectionNotesReportInfoQuery, GetBlankCollectionNotesReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlankCollectionNotesReportInfoQuery, GetBlankCollectionNotesReportInfoQueryVariables>(GetBlankCollectionNotesReportInfoDocument, options);
      }
export function useGetBlankCollectionNotesReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlankCollectionNotesReportInfoQuery, GetBlankCollectionNotesReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlankCollectionNotesReportInfoQuery, GetBlankCollectionNotesReportInfoQueryVariables>(GetBlankCollectionNotesReportInfoDocument, options);
        }
export type GetBlankCollectionNotesReportInfoQueryHookResult = ReturnType<typeof useGetBlankCollectionNotesReportInfoQuery>;
export type GetBlankCollectionNotesReportInfoLazyQueryHookResult = ReturnType<typeof useGetBlankCollectionNotesReportInfoLazyQuery>;
export type GetBlankCollectionNotesReportInfoQueryResult = Apollo.QueryResult<GetBlankCollectionNotesReportInfoQuery, GetBlankCollectionNotesReportInfoQueryVariables>;
export const GetDeliveryCollectionNotesReportInfoDocument = gql`
    query GetDeliveryCollectionNotesReportInfo($input: DeliveryCollectionNotesReportInput!) {
  deliveryCollectionNotesReport(input: $input) {
    url
  }
}
    `;

/**
 * __useGetDeliveryCollectionNotesReportInfoQuery__
 *
 * To run a query within a React component, call `useGetDeliveryCollectionNotesReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCollectionNotesReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCollectionNotesReportInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeliveryCollectionNotesReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryCollectionNotesReportInfoQuery, GetDeliveryCollectionNotesReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryCollectionNotesReportInfoQuery, GetDeliveryCollectionNotesReportInfoQueryVariables>(GetDeliveryCollectionNotesReportInfoDocument, options);
      }
export function useGetDeliveryCollectionNotesReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryCollectionNotesReportInfoQuery, GetDeliveryCollectionNotesReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryCollectionNotesReportInfoQuery, GetDeliveryCollectionNotesReportInfoQueryVariables>(GetDeliveryCollectionNotesReportInfoDocument, options);
        }
export type GetDeliveryCollectionNotesReportInfoQueryHookResult = ReturnType<typeof useGetDeliveryCollectionNotesReportInfoQuery>;
export type GetDeliveryCollectionNotesReportInfoLazyQueryHookResult = ReturnType<typeof useGetDeliveryCollectionNotesReportInfoLazyQuery>;
export type GetDeliveryCollectionNotesReportInfoQueryResult = Apollo.QueryResult<GetDeliveryCollectionNotesReportInfoQuery, GetDeliveryCollectionNotesReportInfoQueryVariables>;
export const GetInvoiceReportInfoDocument = gql`
    query GetInvoiceReportInfo($input: InvoiceReportInput!) {
  invoiceReport(input: $input) {
    url
  }
}
    `;

/**
 * __useGetInvoiceReportInfoQuery__
 *
 * To run a query within a React component, call `useGetInvoiceReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceReportInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInvoiceReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceReportInfoQuery, GetInvoiceReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceReportInfoQuery, GetInvoiceReportInfoQueryVariables>(GetInvoiceReportInfoDocument, options);
      }
export function useGetInvoiceReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceReportInfoQuery, GetInvoiceReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceReportInfoQuery, GetInvoiceReportInfoQueryVariables>(GetInvoiceReportInfoDocument, options);
        }
export type GetInvoiceReportInfoQueryHookResult = ReturnType<typeof useGetInvoiceReportInfoQuery>;
export type GetInvoiceReportInfoLazyQueryHookResult = ReturnType<typeof useGetInvoiceReportInfoLazyQuery>;
export type GetInvoiceReportInfoQueryResult = Apollo.QueryResult<GetInvoiceReportInfoQuery, GetInvoiceReportInfoQueryVariables>;
export const GetCollectionNotesReportInfoDocument = gql`
    query GetCollectionNotesReportInfo($input: CollectionNotesReportInput!) {
  collectionNotesReport(input: $input) {
    url
  }
}
    `;

/**
 * __useGetCollectionNotesReportInfoQuery__
 *
 * To run a query within a React component, call `useGetCollectionNotesReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionNotesReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionNotesReportInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCollectionNotesReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionNotesReportInfoQuery, GetCollectionNotesReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionNotesReportInfoQuery, GetCollectionNotesReportInfoQueryVariables>(GetCollectionNotesReportInfoDocument, options);
      }
export function useGetCollectionNotesReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionNotesReportInfoQuery, GetCollectionNotesReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionNotesReportInfoQuery, GetCollectionNotesReportInfoQueryVariables>(GetCollectionNotesReportInfoDocument, options);
        }
export type GetCollectionNotesReportInfoQueryHookResult = ReturnType<typeof useGetCollectionNotesReportInfoQuery>;
export type GetCollectionNotesReportInfoLazyQueryHookResult = ReturnType<typeof useGetCollectionNotesReportInfoLazyQuery>;
export type GetCollectionNotesReportInfoQueryResult = Apollo.QueryResult<GetCollectionNotesReportInfoQuery, GetCollectionNotesReportInfoQueryVariables>;
export const GetoutstandingInvoiceReportInfoDocument = gql`
    query GetoutstandingInvoiceReportInfo {
  outstandingInvoiceReport {
    url
  }
}
    `;

/**
 * __useGetoutstandingInvoiceReportInfoQuery__
 *
 * To run a query within a React component, call `useGetoutstandingInvoiceReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetoutstandingInvoiceReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetoutstandingInvoiceReportInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetoutstandingInvoiceReportInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetoutstandingInvoiceReportInfoQuery, GetoutstandingInvoiceReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetoutstandingInvoiceReportInfoQuery, GetoutstandingInvoiceReportInfoQueryVariables>(GetoutstandingInvoiceReportInfoDocument, options);
      }
export function useGetoutstandingInvoiceReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetoutstandingInvoiceReportInfoQuery, GetoutstandingInvoiceReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetoutstandingInvoiceReportInfoQuery, GetoutstandingInvoiceReportInfoQueryVariables>(GetoutstandingInvoiceReportInfoDocument, options);
        }
export type GetoutstandingInvoiceReportInfoQueryHookResult = ReturnType<typeof useGetoutstandingInvoiceReportInfoQuery>;
export type GetoutstandingInvoiceReportInfoLazyQueryHookResult = ReturnType<typeof useGetoutstandingInvoiceReportInfoLazyQuery>;
export type GetoutstandingInvoiceReportInfoQueryResult = Apollo.QueryResult<GetoutstandingInvoiceReportInfoQuery, GetoutstandingInvoiceReportInfoQueryVariables>;
export const GetSupplierReengagementReportInfoDocument = gql`
    query GetSupplierReengagementReportInfo($input: SupplierReengagementReportInput!) {
  supplierReengagementReport(input: $input) {
    url
  }
}
    `;

/**
 * __useGetSupplierReengagementReportInfoQuery__
 *
 * To run a query within a React component, call `useGetSupplierReengagementReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierReengagementReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierReengagementReportInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupplierReengagementReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierReengagementReportInfoQuery, GetSupplierReengagementReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierReengagementReportInfoQuery, GetSupplierReengagementReportInfoQueryVariables>(GetSupplierReengagementReportInfoDocument, options);
      }
export function useGetSupplierReengagementReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierReengagementReportInfoQuery, GetSupplierReengagementReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierReengagementReportInfoQuery, GetSupplierReengagementReportInfoQueryVariables>(GetSupplierReengagementReportInfoDocument, options);
        }
export type GetSupplierReengagementReportInfoQueryHookResult = ReturnType<typeof useGetSupplierReengagementReportInfoQuery>;
export type GetSupplierReengagementReportInfoLazyQueryHookResult = ReturnType<typeof useGetSupplierReengagementReportInfoLazyQuery>;
export type GetSupplierReengagementReportInfoQueryResult = Apollo.QueryResult<GetSupplierReengagementReportInfoQuery, GetSupplierReengagementReportInfoQueryVariables>;
export const CreateSupplierAddressDocument = gql`
    mutation CreateSupplierAddress($input: CreateSupplierAddressInput!) {
  createSupplierAddress(input: $input) {
    id
  }
}
    `;
export type CreateSupplierAddressMutationFn = Apollo.MutationFunction<CreateSupplierAddressMutation, CreateSupplierAddressMutationVariables>;

/**
 * __useCreateSupplierAddressMutation__
 *
 * To run a mutation, you first call `useCreateSupplierAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierAddressMutation, { data, loading, error }] = useCreateSupplierAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierAddressMutation, CreateSupplierAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierAddressMutation, CreateSupplierAddressMutationVariables>(CreateSupplierAddressDocument, options);
      }
export type CreateSupplierAddressMutationHookResult = ReturnType<typeof useCreateSupplierAddressMutation>;
export type CreateSupplierAddressMutationResult = Apollo.MutationResult<CreateSupplierAddressMutation>;
export type CreateSupplierAddressMutationOptions = Apollo.BaseMutationOptions<CreateSupplierAddressMutation, CreateSupplierAddressMutationVariables>;
export const AddressEditGetSupplierAddressDocument = gql`
    query AddressEditGetSupplierAddress($addressId: String!) {
  supplierAddress(where: {id: $addressId}) {
    id
    type
    siteName
    building
    street
    district
    town
    county
    postalCode
    country
    notes
  }
}
    `;

/**
 * __useAddressEditGetSupplierAddressQuery__
 *
 * To run a query within a React component, call `useAddressEditGetSupplierAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressEditGetSupplierAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressEditGetSupplierAddressQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useAddressEditGetSupplierAddressQuery(baseOptions: Apollo.QueryHookOptions<AddressEditGetSupplierAddressQuery, AddressEditGetSupplierAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressEditGetSupplierAddressQuery, AddressEditGetSupplierAddressQueryVariables>(AddressEditGetSupplierAddressDocument, options);
      }
export function useAddressEditGetSupplierAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressEditGetSupplierAddressQuery, AddressEditGetSupplierAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressEditGetSupplierAddressQuery, AddressEditGetSupplierAddressQueryVariables>(AddressEditGetSupplierAddressDocument, options);
        }
export type AddressEditGetSupplierAddressQueryHookResult = ReturnType<typeof useAddressEditGetSupplierAddressQuery>;
export type AddressEditGetSupplierAddressLazyQueryHookResult = ReturnType<typeof useAddressEditGetSupplierAddressLazyQuery>;
export type AddressEditGetSupplierAddressQueryResult = Apollo.QueryResult<AddressEditGetSupplierAddressQuery, AddressEditGetSupplierAddressQueryVariables>;
export const AddressEditUpdateSupplierAddressDocument = gql`
    mutation AddressEditUpdateSupplierAddress($input: UpdateSupplierAddressInput!) {
  updateSupplierAddress(input: $input) {
    id
  }
}
    `;
export type AddressEditUpdateSupplierAddressMutationFn = Apollo.MutationFunction<AddressEditUpdateSupplierAddressMutation, AddressEditUpdateSupplierAddressMutationVariables>;

/**
 * __useAddressEditUpdateSupplierAddressMutation__
 *
 * To run a mutation, you first call `useAddressEditUpdateSupplierAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressEditUpdateSupplierAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressEditUpdateSupplierAddressMutation, { data, loading, error }] = useAddressEditUpdateSupplierAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressEditUpdateSupplierAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddressEditUpdateSupplierAddressMutation, AddressEditUpdateSupplierAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddressEditUpdateSupplierAddressMutation, AddressEditUpdateSupplierAddressMutationVariables>(AddressEditUpdateSupplierAddressDocument, options);
      }
export type AddressEditUpdateSupplierAddressMutationHookResult = ReturnType<typeof useAddressEditUpdateSupplierAddressMutation>;
export type AddressEditUpdateSupplierAddressMutationResult = Apollo.MutationResult<AddressEditUpdateSupplierAddressMutation>;
export type AddressEditUpdateSupplierAddressMutationOptions = Apollo.BaseMutationOptions<AddressEditUpdateSupplierAddressMutation, AddressEditUpdateSupplierAddressMutationVariables>;
export const AddressListGetAddressesDocument = gql`
    query AddressListGetAddresses($slug: String!, $offset: Int, $limit: Int) {
  supplierAddresses(
    where: {supplier: {slug: {equals: $slug}}, effectiveTo: {equals: null}}
    orderBy: [{type: asc}, {siteName: asc}]
    skip: $offset
    take: $limit
  ) {
    id
    siteName
    type
    building
    street
    district
    town
    county
    postalCode
    country
  }
  supplierAddressCount(slug: $slug)
}
    `;

/**
 * __useAddressListGetAddressesQuery__
 *
 * To run a query within a React component, call `useAddressListGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressListGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressListGetAddressesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAddressListGetAddressesQuery(baseOptions: Apollo.QueryHookOptions<AddressListGetAddressesQuery, AddressListGetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressListGetAddressesQuery, AddressListGetAddressesQueryVariables>(AddressListGetAddressesDocument, options);
      }
export function useAddressListGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressListGetAddressesQuery, AddressListGetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressListGetAddressesQuery, AddressListGetAddressesQueryVariables>(AddressListGetAddressesDocument, options);
        }
export type AddressListGetAddressesQueryHookResult = ReturnType<typeof useAddressListGetAddressesQuery>;
export type AddressListGetAddressesLazyQueryHookResult = ReturnType<typeof useAddressListGetAddressesLazyQuery>;
export type AddressListGetAddressesQueryResult = Apollo.QueryResult<AddressListGetAddressesQuery, AddressListGetAddressesQueryVariables>;
export const GetSupplierAddressDocument = gql`
    query GetSupplierAddress($addressId: String!) {
  supplierAddress(where: {id: $addressId}) {
    id
    type
    siteName
    building
    street
    district
    town
    county
    postalCode
    country
    notes
  }
}
    `;

/**
 * __useGetSupplierAddressQuery__
 *
 * To run a query within a React component, call `useGetSupplierAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAddressQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useGetSupplierAddressQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierAddressQuery, GetSupplierAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierAddressQuery, GetSupplierAddressQueryVariables>(GetSupplierAddressDocument, options);
      }
export function useGetSupplierAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierAddressQuery, GetSupplierAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierAddressQuery, GetSupplierAddressQueryVariables>(GetSupplierAddressDocument, options);
        }
export type GetSupplierAddressQueryHookResult = ReturnType<typeof useGetSupplierAddressQuery>;
export type GetSupplierAddressLazyQueryHookResult = ReturnType<typeof useGetSupplierAddressLazyQuery>;
export type GetSupplierAddressQueryResult = Apollo.QueryResult<GetSupplierAddressQuery, GetSupplierAddressQueryVariables>;
export const ContainerCreationPageCreateContainerDocument = gql`
    mutation ContainerCreationPageCreateContainer($input: CreateContainerInput!) {
  createContainer(input: $input) {
    id
    name
    type
  }
}
    `;
export type ContainerCreationPageCreateContainerMutationFn = Apollo.MutationFunction<ContainerCreationPageCreateContainerMutation, ContainerCreationPageCreateContainerMutationVariables>;

/**
 * __useContainerCreationPageCreateContainerMutation__
 *
 * To run a mutation, you first call `useContainerCreationPageCreateContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContainerCreationPageCreateContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [containerCreationPageCreateContainerMutation, { data, loading, error }] = useContainerCreationPageCreateContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContainerCreationPageCreateContainerMutation(baseOptions?: Apollo.MutationHookOptions<ContainerCreationPageCreateContainerMutation, ContainerCreationPageCreateContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContainerCreationPageCreateContainerMutation, ContainerCreationPageCreateContainerMutationVariables>(ContainerCreationPageCreateContainerDocument, options);
      }
export type ContainerCreationPageCreateContainerMutationHookResult = ReturnType<typeof useContainerCreationPageCreateContainerMutation>;
export type ContainerCreationPageCreateContainerMutationResult = Apollo.MutationResult<ContainerCreationPageCreateContainerMutation>;
export type ContainerCreationPageCreateContainerMutationOptions = Apollo.BaseMutationOptions<ContainerCreationPageCreateContainerMutation, ContainerCreationPageCreateContainerMutationVariables>;
export const ContainerEditPageGetContainerDocument = gql`
    query ContainerEditPageGetContainer($containerId: String!) {
  container(where: {id: $containerId}) {
    id
    name
    type
  }
}
    `;

/**
 * __useContainerEditPageGetContainerQuery__
 *
 * To run a query within a React component, call `useContainerEditPageGetContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerEditPageGetContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerEditPageGetContainerQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useContainerEditPageGetContainerQuery(baseOptions: Apollo.QueryHookOptions<ContainerEditPageGetContainerQuery, ContainerEditPageGetContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainerEditPageGetContainerQuery, ContainerEditPageGetContainerQueryVariables>(ContainerEditPageGetContainerDocument, options);
      }
export function useContainerEditPageGetContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainerEditPageGetContainerQuery, ContainerEditPageGetContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainerEditPageGetContainerQuery, ContainerEditPageGetContainerQueryVariables>(ContainerEditPageGetContainerDocument, options);
        }
export type ContainerEditPageGetContainerQueryHookResult = ReturnType<typeof useContainerEditPageGetContainerQuery>;
export type ContainerEditPageGetContainerLazyQueryHookResult = ReturnType<typeof useContainerEditPageGetContainerLazyQuery>;
export type ContainerEditPageGetContainerQueryResult = Apollo.QueryResult<ContainerEditPageGetContainerQuery, ContainerEditPageGetContainerQueryVariables>;
export const ContainerEditPageUpdateContainerDocument = gql`
    mutation ContainerEditPageUpdateContainer($input: UpdateContainerInput!) {
  updateContainer(input: $input) {
    id
    name
    type
  }
}
    `;
export type ContainerEditPageUpdateContainerMutationFn = Apollo.MutationFunction<ContainerEditPageUpdateContainerMutation, ContainerEditPageUpdateContainerMutationVariables>;

/**
 * __useContainerEditPageUpdateContainerMutation__
 *
 * To run a mutation, you first call `useContainerEditPageUpdateContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContainerEditPageUpdateContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [containerEditPageUpdateContainerMutation, { data, loading, error }] = useContainerEditPageUpdateContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContainerEditPageUpdateContainerMutation(baseOptions?: Apollo.MutationHookOptions<ContainerEditPageUpdateContainerMutation, ContainerEditPageUpdateContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContainerEditPageUpdateContainerMutation, ContainerEditPageUpdateContainerMutationVariables>(ContainerEditPageUpdateContainerDocument, options);
      }
export type ContainerEditPageUpdateContainerMutationHookResult = ReturnType<typeof useContainerEditPageUpdateContainerMutation>;
export type ContainerEditPageUpdateContainerMutationResult = Apollo.MutationResult<ContainerEditPageUpdateContainerMutation>;
export type ContainerEditPageUpdateContainerMutationOptions = Apollo.BaseMutationOptions<ContainerEditPageUpdateContainerMutation, ContainerEditPageUpdateContainerMutationVariables>;
export const ContainerListGetContainersDocument = gql`
    query ContainerListGetContainers($offset: Int, $limit: Int) {
  containers(orderBy: {name: asc}, skip: $offset, take: $limit) {
    id
    name
    type
  }
  containerCount
}
    `;

/**
 * __useContainerListGetContainersQuery__
 *
 * To run a query within a React component, call `useContainerListGetContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerListGetContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerListGetContainersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useContainerListGetContainersQuery(baseOptions?: Apollo.QueryHookOptions<ContainerListGetContainersQuery, ContainerListGetContainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainerListGetContainersQuery, ContainerListGetContainersQueryVariables>(ContainerListGetContainersDocument, options);
      }
export function useContainerListGetContainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainerListGetContainersQuery, ContainerListGetContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainerListGetContainersQuery, ContainerListGetContainersQueryVariables>(ContainerListGetContainersDocument, options);
        }
export type ContainerListGetContainersQueryHookResult = ReturnType<typeof useContainerListGetContainersQuery>;
export type ContainerListGetContainersLazyQueryHookResult = ReturnType<typeof useContainerListGetContainersLazyQuery>;
export type ContainerListGetContainersQueryResult = Apollo.QueryResult<ContainerListGetContainersQuery, ContainerListGetContainersQueryVariables>;
export const EwcCodeCreationPageCreateEwcCodeDocument = gql`
    mutation EWCCodeCreationPageCreateEWCCode($input: CreateEWCCodeInput!) {
  createEWCCode(input: $input) {
    id
  }
}
    `;
export type EwcCodeCreationPageCreateEwcCodeMutationFn = Apollo.MutationFunction<EwcCodeCreationPageCreateEwcCodeMutation, EwcCodeCreationPageCreateEwcCodeMutationVariables>;

/**
 * __useEwcCodeCreationPageCreateEwcCodeMutation__
 *
 * To run a mutation, you first call `useEwcCodeCreationPageCreateEwcCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEwcCodeCreationPageCreateEwcCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ewcCodeCreationPageCreateEwcCodeMutation, { data, loading, error }] = useEwcCodeCreationPageCreateEwcCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEwcCodeCreationPageCreateEwcCodeMutation(baseOptions?: Apollo.MutationHookOptions<EwcCodeCreationPageCreateEwcCodeMutation, EwcCodeCreationPageCreateEwcCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EwcCodeCreationPageCreateEwcCodeMutation, EwcCodeCreationPageCreateEwcCodeMutationVariables>(EwcCodeCreationPageCreateEwcCodeDocument, options);
      }
export type EwcCodeCreationPageCreateEwcCodeMutationHookResult = ReturnType<typeof useEwcCodeCreationPageCreateEwcCodeMutation>;
export type EwcCodeCreationPageCreateEwcCodeMutationResult = Apollo.MutationResult<EwcCodeCreationPageCreateEwcCodeMutation>;
export type EwcCodeCreationPageCreateEwcCodeMutationOptions = Apollo.BaseMutationOptions<EwcCodeCreationPageCreateEwcCodeMutation, EwcCodeCreationPageCreateEwcCodeMutationVariables>;
export const EwcCodeEditPageGetEwcCodeDocument = gql`
    query EWCCodeEditPageGetEWCCode($ewcCodeId: String!) {
  ewcCode(where: {id: $ewcCodeId}) {
    id
    code
    product
  }
}
    `;

/**
 * __useEwcCodeEditPageGetEwcCodeQuery__
 *
 * To run a query within a React component, call `useEwcCodeEditPageGetEwcCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEwcCodeEditPageGetEwcCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEwcCodeEditPageGetEwcCodeQuery({
 *   variables: {
 *      ewcCodeId: // value for 'ewcCodeId'
 *   },
 * });
 */
export function useEwcCodeEditPageGetEwcCodeQuery(baseOptions: Apollo.QueryHookOptions<EwcCodeEditPageGetEwcCodeQuery, EwcCodeEditPageGetEwcCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EwcCodeEditPageGetEwcCodeQuery, EwcCodeEditPageGetEwcCodeQueryVariables>(EwcCodeEditPageGetEwcCodeDocument, options);
      }
export function useEwcCodeEditPageGetEwcCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EwcCodeEditPageGetEwcCodeQuery, EwcCodeEditPageGetEwcCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EwcCodeEditPageGetEwcCodeQuery, EwcCodeEditPageGetEwcCodeQueryVariables>(EwcCodeEditPageGetEwcCodeDocument, options);
        }
export type EwcCodeEditPageGetEwcCodeQueryHookResult = ReturnType<typeof useEwcCodeEditPageGetEwcCodeQuery>;
export type EwcCodeEditPageGetEwcCodeLazyQueryHookResult = ReturnType<typeof useEwcCodeEditPageGetEwcCodeLazyQuery>;
export type EwcCodeEditPageGetEwcCodeQueryResult = Apollo.QueryResult<EwcCodeEditPageGetEwcCodeQuery, EwcCodeEditPageGetEwcCodeQueryVariables>;
export const EwcCodeEditPageUpdateEwcCodeDocument = gql`
    mutation EWCCodeEditPageUpdateEWCCode($input: UpdateEWCCodeInput!) {
  updateEWCCode(input: $input) {
    id
  }
}
    `;
export type EwcCodeEditPageUpdateEwcCodeMutationFn = Apollo.MutationFunction<EwcCodeEditPageUpdateEwcCodeMutation, EwcCodeEditPageUpdateEwcCodeMutationVariables>;

/**
 * __useEwcCodeEditPageUpdateEwcCodeMutation__
 *
 * To run a mutation, you first call `useEwcCodeEditPageUpdateEwcCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEwcCodeEditPageUpdateEwcCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ewcCodeEditPageUpdateEwcCodeMutation, { data, loading, error }] = useEwcCodeEditPageUpdateEwcCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEwcCodeEditPageUpdateEwcCodeMutation(baseOptions?: Apollo.MutationHookOptions<EwcCodeEditPageUpdateEwcCodeMutation, EwcCodeEditPageUpdateEwcCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EwcCodeEditPageUpdateEwcCodeMutation, EwcCodeEditPageUpdateEwcCodeMutationVariables>(EwcCodeEditPageUpdateEwcCodeDocument, options);
      }
export type EwcCodeEditPageUpdateEwcCodeMutationHookResult = ReturnType<typeof useEwcCodeEditPageUpdateEwcCodeMutation>;
export type EwcCodeEditPageUpdateEwcCodeMutationResult = Apollo.MutationResult<EwcCodeEditPageUpdateEwcCodeMutation>;
export type EwcCodeEditPageUpdateEwcCodeMutationOptions = Apollo.BaseMutationOptions<EwcCodeEditPageUpdateEwcCodeMutation, EwcCodeEditPageUpdateEwcCodeMutationVariables>;
export const GetEwcCodesDocument = gql`
    query GetEWCCodes($offset: Int, $limit: Int) {
  ewcCodes(orderBy: {code: asc}, skip: $offset, take: $limit) {
    id
    code
    product
  }
  ewcCodeCount
}
    `;

/**
 * __useGetEwcCodesQuery__
 *
 * To run a query within a React component, call `useGetEwcCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEwcCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEwcCodesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEwcCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetEwcCodesQuery, GetEwcCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEwcCodesQuery, GetEwcCodesQueryVariables>(GetEwcCodesDocument, options);
      }
export function useGetEwcCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEwcCodesQuery, GetEwcCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEwcCodesQuery, GetEwcCodesQueryVariables>(GetEwcCodesDocument, options);
        }
export type GetEwcCodesQueryHookResult = ReturnType<typeof useGetEwcCodesQuery>;
export type GetEwcCodesLazyQueryHookResult = ReturnType<typeof useGetEwcCodesLazyQuery>;
export type GetEwcCodesQueryResult = Apollo.QueryResult<GetEwcCodesQuery, GetEwcCodesQueryVariables>;
export const NominalCodeCreationPageCreateNominalCodeDocument = gql`
    mutation NominalCodeCreationPageCreateNominalCode($input: CreateNominalCodeInput!) {
  createNominalCode(input: $input) {
    id
  }
}
    `;
export type NominalCodeCreationPageCreateNominalCodeMutationFn = Apollo.MutationFunction<NominalCodeCreationPageCreateNominalCodeMutation, NominalCodeCreationPageCreateNominalCodeMutationVariables>;

/**
 * __useNominalCodeCreationPageCreateNominalCodeMutation__
 *
 * To run a mutation, you first call `useNominalCodeCreationPageCreateNominalCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNominalCodeCreationPageCreateNominalCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nominalCodeCreationPageCreateNominalCodeMutation, { data, loading, error }] = useNominalCodeCreationPageCreateNominalCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNominalCodeCreationPageCreateNominalCodeMutation(baseOptions?: Apollo.MutationHookOptions<NominalCodeCreationPageCreateNominalCodeMutation, NominalCodeCreationPageCreateNominalCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NominalCodeCreationPageCreateNominalCodeMutation, NominalCodeCreationPageCreateNominalCodeMutationVariables>(NominalCodeCreationPageCreateNominalCodeDocument, options);
      }
export type NominalCodeCreationPageCreateNominalCodeMutationHookResult = ReturnType<typeof useNominalCodeCreationPageCreateNominalCodeMutation>;
export type NominalCodeCreationPageCreateNominalCodeMutationResult = Apollo.MutationResult<NominalCodeCreationPageCreateNominalCodeMutation>;
export type NominalCodeCreationPageCreateNominalCodeMutationOptions = Apollo.BaseMutationOptions<NominalCodeCreationPageCreateNominalCodeMutation, NominalCodeCreationPageCreateNominalCodeMutationVariables>;
export const NominalCodeEditPageGetNominalCodeDocument = gql`
    query NominalCodeEditPageGetNominalCode($nominalCodeId: String!) {
  nominalCode(where: {id: $nominalCodeId}) {
    id
    code
  }
}
    `;

/**
 * __useNominalCodeEditPageGetNominalCodeQuery__
 *
 * To run a query within a React component, call `useNominalCodeEditPageGetNominalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNominalCodeEditPageGetNominalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNominalCodeEditPageGetNominalCodeQuery({
 *   variables: {
 *      nominalCodeId: // value for 'nominalCodeId'
 *   },
 * });
 */
export function useNominalCodeEditPageGetNominalCodeQuery(baseOptions: Apollo.QueryHookOptions<NominalCodeEditPageGetNominalCodeQuery, NominalCodeEditPageGetNominalCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NominalCodeEditPageGetNominalCodeQuery, NominalCodeEditPageGetNominalCodeQueryVariables>(NominalCodeEditPageGetNominalCodeDocument, options);
      }
export function useNominalCodeEditPageGetNominalCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NominalCodeEditPageGetNominalCodeQuery, NominalCodeEditPageGetNominalCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NominalCodeEditPageGetNominalCodeQuery, NominalCodeEditPageGetNominalCodeQueryVariables>(NominalCodeEditPageGetNominalCodeDocument, options);
        }
export type NominalCodeEditPageGetNominalCodeQueryHookResult = ReturnType<typeof useNominalCodeEditPageGetNominalCodeQuery>;
export type NominalCodeEditPageGetNominalCodeLazyQueryHookResult = ReturnType<typeof useNominalCodeEditPageGetNominalCodeLazyQuery>;
export type NominalCodeEditPageGetNominalCodeQueryResult = Apollo.QueryResult<NominalCodeEditPageGetNominalCodeQuery, NominalCodeEditPageGetNominalCodeQueryVariables>;
export const NominalCodeEditPageUpdateNominalCodeDocument = gql`
    mutation NominalCodeEditPageUpdateNominalCode($input: UpdateNominalCodeInput!) {
  updateNominalCode(input: $input) {
    id
  }
}
    `;
export type NominalCodeEditPageUpdateNominalCodeMutationFn = Apollo.MutationFunction<NominalCodeEditPageUpdateNominalCodeMutation, NominalCodeEditPageUpdateNominalCodeMutationVariables>;

/**
 * __useNominalCodeEditPageUpdateNominalCodeMutation__
 *
 * To run a mutation, you first call `useNominalCodeEditPageUpdateNominalCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNominalCodeEditPageUpdateNominalCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nominalCodeEditPageUpdateNominalCodeMutation, { data, loading, error }] = useNominalCodeEditPageUpdateNominalCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNominalCodeEditPageUpdateNominalCodeMutation(baseOptions?: Apollo.MutationHookOptions<NominalCodeEditPageUpdateNominalCodeMutation, NominalCodeEditPageUpdateNominalCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NominalCodeEditPageUpdateNominalCodeMutation, NominalCodeEditPageUpdateNominalCodeMutationVariables>(NominalCodeEditPageUpdateNominalCodeDocument, options);
      }
export type NominalCodeEditPageUpdateNominalCodeMutationHookResult = ReturnType<typeof useNominalCodeEditPageUpdateNominalCodeMutation>;
export type NominalCodeEditPageUpdateNominalCodeMutationResult = Apollo.MutationResult<NominalCodeEditPageUpdateNominalCodeMutation>;
export type NominalCodeEditPageUpdateNominalCodeMutationOptions = Apollo.BaseMutationOptions<NominalCodeEditPageUpdateNominalCodeMutation, NominalCodeEditPageUpdateNominalCodeMutationVariables>;
export const GetNominalCodesDocument = gql`
    query GetNominalCodes($offset: Int, $limit: Int) {
  nominalCodes(orderBy: {code: asc}, skip: $offset, take: $limit) {
    id
    code
  }
  nominalCodeCount
}
    `;

/**
 * __useGetNominalCodesQuery__
 *
 * To run a query within a React component, call `useGetNominalCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNominalCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNominalCodesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNominalCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetNominalCodesQuery, GetNominalCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNominalCodesQuery, GetNominalCodesQueryVariables>(GetNominalCodesDocument, options);
      }
export function useGetNominalCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNominalCodesQuery, GetNominalCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNominalCodesQuery, GetNominalCodesQueryVariables>(GetNominalCodesDocument, options);
        }
export type GetNominalCodesQueryHookResult = ReturnType<typeof useGetNominalCodesQuery>;
export type GetNominalCodesLazyQueryHookResult = ReturnType<typeof useGetNominalCodesLazyQuery>;
export type GetNominalCodesQueryResult = Apollo.QueryResult<GetNominalCodesQuery, GetNominalCodesQueryVariables>;
export const SupplierCreationPageCreateSupplierDocument = gql`
    mutation SupplierCreationPageCreateSupplier($input: CreateSupplierInput!) {
  createSupplier(input: $input) {
    id
    name
    slug
    vat
    currency
  }
}
    `;
export type SupplierCreationPageCreateSupplierMutationFn = Apollo.MutationFunction<SupplierCreationPageCreateSupplierMutation, SupplierCreationPageCreateSupplierMutationVariables>;

/**
 * __useSupplierCreationPageCreateSupplierMutation__
 *
 * To run a mutation, you first call `useSupplierCreationPageCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierCreationPageCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierCreationPageCreateSupplierMutation, { data, loading, error }] = useSupplierCreationPageCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierCreationPageCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<SupplierCreationPageCreateSupplierMutation, SupplierCreationPageCreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierCreationPageCreateSupplierMutation, SupplierCreationPageCreateSupplierMutationVariables>(SupplierCreationPageCreateSupplierDocument, options);
      }
export type SupplierCreationPageCreateSupplierMutationHookResult = ReturnType<typeof useSupplierCreationPageCreateSupplierMutation>;
export type SupplierCreationPageCreateSupplierMutationResult = Apollo.MutationResult<SupplierCreationPageCreateSupplierMutation>;
export type SupplierCreationPageCreateSupplierMutationOptions = Apollo.BaseMutationOptions<SupplierCreationPageCreateSupplierMutation, SupplierCreationPageCreateSupplierMutationVariables>;
export const SupplierEditPageGetSupplierDocument = gql`
    query SupplierEditPageGetSupplier($supplierSlug: String!) {
  supplier(where: {slug: $supplierSlug}) {
    id
    name
    slug
    vat
    sageReference
    currency
  }
}
    `;

/**
 * __useSupplierEditPageGetSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierEditPageGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierEditPageGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierEditPageGetSupplierQuery({
 *   variables: {
 *      supplierSlug: // value for 'supplierSlug'
 *   },
 * });
 */
export function useSupplierEditPageGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<SupplierEditPageGetSupplierQuery, SupplierEditPageGetSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierEditPageGetSupplierQuery, SupplierEditPageGetSupplierQueryVariables>(SupplierEditPageGetSupplierDocument, options);
      }
export function useSupplierEditPageGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierEditPageGetSupplierQuery, SupplierEditPageGetSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierEditPageGetSupplierQuery, SupplierEditPageGetSupplierQueryVariables>(SupplierEditPageGetSupplierDocument, options);
        }
export type SupplierEditPageGetSupplierQueryHookResult = ReturnType<typeof useSupplierEditPageGetSupplierQuery>;
export type SupplierEditPageGetSupplierLazyQueryHookResult = ReturnType<typeof useSupplierEditPageGetSupplierLazyQuery>;
export type SupplierEditPageGetSupplierQueryResult = Apollo.QueryResult<SupplierEditPageGetSupplierQuery, SupplierEditPageGetSupplierQueryVariables>;
export const SupplierEditPageUpdateSupplierDocument = gql`
    mutation SupplierEditPageUpdateSupplier($input: UpdateSupplierInput!) {
  updateSupplier(input: $input) {
    id
    name
    slug
    vat
    sageReference
    currency
  }
}
    `;
export type SupplierEditPageUpdateSupplierMutationFn = Apollo.MutationFunction<SupplierEditPageUpdateSupplierMutation, SupplierEditPageUpdateSupplierMutationVariables>;

/**
 * __useSupplierEditPageUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useSupplierEditPageUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierEditPageUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierEditPageUpdateSupplierMutation, { data, loading, error }] = useSupplierEditPageUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierEditPageUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<SupplierEditPageUpdateSupplierMutation, SupplierEditPageUpdateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierEditPageUpdateSupplierMutation, SupplierEditPageUpdateSupplierMutationVariables>(SupplierEditPageUpdateSupplierDocument, options);
      }
export type SupplierEditPageUpdateSupplierMutationHookResult = ReturnType<typeof useSupplierEditPageUpdateSupplierMutation>;
export type SupplierEditPageUpdateSupplierMutationResult = Apollo.MutationResult<SupplierEditPageUpdateSupplierMutation>;
export type SupplierEditPageUpdateSupplierMutationOptions = Apollo.BaseMutationOptions<SupplierEditPageUpdateSupplierMutation, SupplierEditPageUpdateSupplierMutationVariables>;
export const SupplierListGetSuppliersDocument = gql`
    query SupplierListGetSuppliers($input: ListSupplierInput!, $countInput: ListSupplierCountInput!) {
  suppliers(input: $input) {
    id
    name
    slug
    vat
    sageReference
    currency
  }
  supplierCount(input: $countInput)
}
    `;

/**
 * __useSupplierListGetSuppliersQuery__
 *
 * To run a query within a React component, call `useSupplierListGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierListGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierListGetSuppliersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      countInput: // value for 'countInput'
 *   },
 * });
 */
export function useSupplierListGetSuppliersQuery(baseOptions: Apollo.QueryHookOptions<SupplierListGetSuppliersQuery, SupplierListGetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierListGetSuppliersQuery, SupplierListGetSuppliersQueryVariables>(SupplierListGetSuppliersDocument, options);
      }
export function useSupplierListGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierListGetSuppliersQuery, SupplierListGetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierListGetSuppliersQuery, SupplierListGetSuppliersQueryVariables>(SupplierListGetSuppliersDocument, options);
        }
export type SupplierListGetSuppliersQueryHookResult = ReturnType<typeof useSupplierListGetSuppliersQuery>;
export type SupplierListGetSuppliersLazyQueryHookResult = ReturnType<typeof useSupplierListGetSuppliersLazyQuery>;
export type SupplierListGetSuppliersQueryResult = Apollo.QueryResult<SupplierListGetSuppliersQuery, SupplierListGetSuppliersQueryVariables>;
export const SupplierPageGetSupplierDocument = gql`
    query SupplierPageGetSupplier($supplierSlug: String!) {
  supplier(where: {slug: $supplierSlug}) {
    id
    name
    slug
    vat
    sageReference
    currency
  }
}
    `;

/**
 * __useSupplierPageGetSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierPageGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPageGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPageGetSupplierQuery({
 *   variables: {
 *      supplierSlug: // value for 'supplierSlug'
 *   },
 * });
 */
export function useSupplierPageGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<SupplierPageGetSupplierQuery, SupplierPageGetSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierPageGetSupplierQuery, SupplierPageGetSupplierQueryVariables>(SupplierPageGetSupplierDocument, options);
      }
export function useSupplierPageGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPageGetSupplierQuery, SupplierPageGetSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierPageGetSupplierQuery, SupplierPageGetSupplierQueryVariables>(SupplierPageGetSupplierDocument, options);
        }
export type SupplierPageGetSupplierQueryHookResult = ReturnType<typeof useSupplierPageGetSupplierQuery>;
export type SupplierPageGetSupplierLazyQueryHookResult = ReturnType<typeof useSupplierPageGetSupplierLazyQuery>;
export type SupplierPageGetSupplierQueryResult = Apollo.QueryResult<SupplierPageGetSupplierQuery, SupplierPageGetSupplierQueryVariables>;
export const CollectionNoteCreationPageGetDataDocument = gql`
    query CollectionNoteCreationPageGetData($supplierSlug: String!) {
  supplierAddresses(
    where: {supplier: {slug: {equals: $supplierSlug}}, effectiveTo: {equals: null}}
    orderBy: [{type: desc}, {siteName: asc}]
  ) {
    id
    type
    siteName
    building
    postalCode
  }
  ewcCodes(orderBy: {code: asc}) {
    id
    code
    product
  }
  nominalCodes(orderBy: {code: asc}) {
    id
    code
  }
}
    `;

/**
 * __useCollectionNoteCreationPageGetDataQuery__
 *
 * To run a query within a React component, call `useCollectionNoteCreationPageGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNoteCreationPageGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNoteCreationPageGetDataQuery({
 *   variables: {
 *      supplierSlug: // value for 'supplierSlug'
 *   },
 * });
 */
export function useCollectionNoteCreationPageGetDataQuery(baseOptions: Apollo.QueryHookOptions<CollectionNoteCreationPageGetDataQuery, CollectionNoteCreationPageGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectionNoteCreationPageGetDataQuery, CollectionNoteCreationPageGetDataQueryVariables>(CollectionNoteCreationPageGetDataDocument, options);
      }
export function useCollectionNoteCreationPageGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionNoteCreationPageGetDataQuery, CollectionNoteCreationPageGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectionNoteCreationPageGetDataQuery, CollectionNoteCreationPageGetDataQueryVariables>(CollectionNoteCreationPageGetDataDocument, options);
        }
export type CollectionNoteCreationPageGetDataQueryHookResult = ReturnType<typeof useCollectionNoteCreationPageGetDataQuery>;
export type CollectionNoteCreationPageGetDataLazyQueryHookResult = ReturnType<typeof useCollectionNoteCreationPageGetDataLazyQuery>;
export type CollectionNoteCreationPageGetDataQueryResult = Apollo.QueryResult<CollectionNoteCreationPageGetDataQuery, CollectionNoteCreationPageGetDataQueryVariables>;
export const CreateCollectionNoteDocument = gql`
    mutation CreateCollectionNote($input: CreateCollectionNoteInput!) {
  createCollectionNote(input: $input) {
    id
  }
}
    `;
export type CreateCollectionNoteMutationFn = Apollo.MutationFunction<CreateCollectionNoteMutation, CreateCollectionNoteMutationVariables>;

/**
 * __useCreateCollectionNoteMutation__
 *
 * To run a mutation, you first call `useCreateCollectionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionNoteMutation, { data, loading, error }] = useCreateCollectionNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollectionNoteMutation, CreateCollectionNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollectionNoteMutation, CreateCollectionNoteMutationVariables>(CreateCollectionNoteDocument, options);
      }
export type CreateCollectionNoteMutationHookResult = ReturnType<typeof useCreateCollectionNoteMutation>;
export type CreateCollectionNoteMutationResult = Apollo.MutationResult<CreateCollectionNoteMutation>;
export type CreateCollectionNoteMutationOptions = Apollo.BaseMutationOptions<CreateCollectionNoteMutation, CreateCollectionNoteMutationVariables>;
export const CollectionNoteEditPageGetDataDocument = gql`
    query CollectionNoteEditPageGetData($collectionNoteId: String!, $supplierSlug: String!) {
  collectionNote(where: {id: $collectionNoteId}) {
    id
    displayReference
    creationDate
    return
    supplierAddress {
      id
    }
    collectionNoteLines(orderBy: {createdAt: asc}) {
      id
      quantity
      description
      invoiced
      value
      vat
      total
      ewcCode {
        id
      }
      nominalCode {
        id
      }
    }
  }
  supplierAddresses(
    where: {supplier: {slug: {equals: $supplierSlug}}, effectiveTo: {equals: null}}
    orderBy: [{type: desc}, {siteName: asc}]
  ) {
    id
    type
    siteName
    building
    postalCode
  }
  ewcCodes(orderBy: {code: asc}) {
    id
    code
    product
  }
  nominalCodes(orderBy: {code: asc}) {
    id
    code
  }
}
    `;

/**
 * __useCollectionNoteEditPageGetDataQuery__
 *
 * To run a query within a React component, call `useCollectionNoteEditPageGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNoteEditPageGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNoteEditPageGetDataQuery({
 *   variables: {
 *      collectionNoteId: // value for 'collectionNoteId'
 *      supplierSlug: // value for 'supplierSlug'
 *   },
 * });
 */
export function useCollectionNoteEditPageGetDataQuery(baseOptions: Apollo.QueryHookOptions<CollectionNoteEditPageGetDataQuery, CollectionNoteEditPageGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectionNoteEditPageGetDataQuery, CollectionNoteEditPageGetDataQueryVariables>(CollectionNoteEditPageGetDataDocument, options);
      }
export function useCollectionNoteEditPageGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionNoteEditPageGetDataQuery, CollectionNoteEditPageGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectionNoteEditPageGetDataQuery, CollectionNoteEditPageGetDataQueryVariables>(CollectionNoteEditPageGetDataDocument, options);
        }
export type CollectionNoteEditPageGetDataQueryHookResult = ReturnType<typeof useCollectionNoteEditPageGetDataQuery>;
export type CollectionNoteEditPageGetDataLazyQueryHookResult = ReturnType<typeof useCollectionNoteEditPageGetDataLazyQuery>;
export type CollectionNoteEditPageGetDataQueryResult = Apollo.QueryResult<CollectionNoteEditPageGetDataQuery, CollectionNoteEditPageGetDataQueryVariables>;
export const CollectionNoteEditPageUpdateCollectionNoteDocument = gql`
    mutation CollectionNoteEditPageUpdateCollectionNote($input: UpdateCollectionNoteInput!) {
  updateCollectionNote(input: $input) {
    id
  }
}
    `;
export type CollectionNoteEditPageUpdateCollectionNoteMutationFn = Apollo.MutationFunction<CollectionNoteEditPageUpdateCollectionNoteMutation, CollectionNoteEditPageUpdateCollectionNoteMutationVariables>;

/**
 * __useCollectionNoteEditPageUpdateCollectionNoteMutation__
 *
 * To run a mutation, you first call `useCollectionNoteEditPageUpdateCollectionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectionNoteEditPageUpdateCollectionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectionNoteEditPageUpdateCollectionNoteMutation, { data, loading, error }] = useCollectionNoteEditPageUpdateCollectionNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionNoteEditPageUpdateCollectionNoteMutation(baseOptions?: Apollo.MutationHookOptions<CollectionNoteEditPageUpdateCollectionNoteMutation, CollectionNoteEditPageUpdateCollectionNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectionNoteEditPageUpdateCollectionNoteMutation, CollectionNoteEditPageUpdateCollectionNoteMutationVariables>(CollectionNoteEditPageUpdateCollectionNoteDocument, options);
      }
export type CollectionNoteEditPageUpdateCollectionNoteMutationHookResult = ReturnType<typeof useCollectionNoteEditPageUpdateCollectionNoteMutation>;
export type CollectionNoteEditPageUpdateCollectionNoteMutationResult = Apollo.MutationResult<CollectionNoteEditPageUpdateCollectionNoteMutation>;
export type CollectionNoteEditPageUpdateCollectionNoteMutationOptions = Apollo.BaseMutationOptions<CollectionNoteEditPageUpdateCollectionNoteMutation, CollectionNoteEditPageUpdateCollectionNoteMutationVariables>;
export const GetCollectionNotesDocument = gql`
    query GetCollectionNotes($input: ListCollectionNotesInput!, $countInput: ListCollectionNoteCountInput!) {
  collectionNotes(input: $input) {
    id
    displayReference
    linesCount
    creationDate
    printed
    net
    vat
    gross
    invoiced
    closed
    collectionNoteLines(orderBy: {createdAt: asc}) {
      id
      quantity
      description
      invoiced
      value
      vat
      total
    }
  }
  collectionNoteCount(input: $countInput)
}
    `;

/**
 * __useGetCollectionNotesQuery__
 *
 * To run a query within a React component, call `useGetCollectionNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      countInput: // value for 'countInput'
 *   },
 * });
 */
export function useGetCollectionNotesQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionNotesQuery, GetCollectionNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionNotesQuery, GetCollectionNotesQueryVariables>(GetCollectionNotesDocument, options);
      }
export function useGetCollectionNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionNotesQuery, GetCollectionNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionNotesQuery, GetCollectionNotesQueryVariables>(GetCollectionNotesDocument, options);
        }
export type GetCollectionNotesQueryHookResult = ReturnType<typeof useGetCollectionNotesQuery>;
export type GetCollectionNotesLazyQueryHookResult = ReturnType<typeof useGetCollectionNotesLazyQuery>;
export type GetCollectionNotesQueryResult = Apollo.QueryResult<GetCollectionNotesQuery, GetCollectionNotesQueryVariables>;
export const GetCollectionNoteDocument = gql`
    query GetCollectionNote($collectionNoteId: String!) {
  collectionNote(where: {id: $collectionNoteId}) {
    id
    linesCount
    creationDate
    printed
    printedAt
    net
    vat
    gross
    invoiced
    closed
    displayReference
    collectionNoteLines(orderBy: {createdAt: asc}) {
      id
      quantity
      description
      invoiced
      value
      vat
      total
      ewcCode {
        id
      }
      nominalCode {
        id
      }
      invoice {
        id
      }
    }
    supplierAddress {
      id
      type
      siteName
      building
      postalCode
    }
  }
  ewcCodes(orderBy: {code: asc}) {
    id
    code
    product
  }
  nominalCodes(orderBy: {code: asc}) {
    id
    code
  }
}
    `;

/**
 * __useGetCollectionNoteQuery__
 *
 * To run a query within a React component, call `useGetCollectionNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionNoteQuery({
 *   variables: {
 *      collectionNoteId: // value for 'collectionNoteId'
 *   },
 * });
 */
export function useGetCollectionNoteQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionNoteQuery, GetCollectionNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionNoteQuery, GetCollectionNoteQueryVariables>(GetCollectionNoteDocument, options);
      }
export function useGetCollectionNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionNoteQuery, GetCollectionNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionNoteQuery, GetCollectionNoteQueryVariables>(GetCollectionNoteDocument, options);
        }
export type GetCollectionNoteQueryHookResult = ReturnType<typeof useGetCollectionNoteQuery>;
export type GetCollectionNoteLazyQueryHookResult = ReturnType<typeof useGetCollectionNoteLazyQuery>;
export type GetCollectionNoteQueryResult = Apollo.QueryResult<GetCollectionNoteQuery, GetCollectionNoteQueryVariables>;
export const GetCollectionNotePdfDocument = gql`
    query GetCollectionNotePDF($collectionNoteId: String!) {
  collectionNote(where: {id: $collectionNoteId}) {
    displayReference
    pdf
  }
}
    `;

/**
 * __useGetCollectionNotePdfQuery__
 *
 * To run a query within a React component, call `useGetCollectionNotePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionNotePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionNotePdfQuery({
 *   variables: {
 *      collectionNoteId: // value for 'collectionNoteId'
 *   },
 * });
 */
export function useGetCollectionNotePdfQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionNotePdfQuery, GetCollectionNotePdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionNotePdfQuery, GetCollectionNotePdfQueryVariables>(GetCollectionNotePdfDocument, options);
      }
export function useGetCollectionNotePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionNotePdfQuery, GetCollectionNotePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionNotePdfQuery, GetCollectionNotePdfQueryVariables>(GetCollectionNotePdfDocument, options);
        }
export type GetCollectionNotePdfQueryHookResult = ReturnType<typeof useGetCollectionNotePdfQuery>;
export type GetCollectionNotePdfLazyQueryHookResult = ReturnType<typeof useGetCollectionNotePdfLazyQuery>;
export type GetCollectionNotePdfQueryResult = Apollo.QueryResult<GetCollectionNotePdfQuery, GetCollectionNotePdfQueryVariables>;
export const MarkCollectionNoteAsPrintedDocument = gql`
    mutation MarkCollectionNoteAsPrinted($collectionNoteId: String!) {
  markCollectionNoteAsPrinted(input: {collectionNoteId: $collectionNoteId}) {
    id
    printed
  }
}
    `;
export type MarkCollectionNoteAsPrintedMutationFn = Apollo.MutationFunction<MarkCollectionNoteAsPrintedMutation, MarkCollectionNoteAsPrintedMutationVariables>;

/**
 * __useMarkCollectionNoteAsPrintedMutation__
 *
 * To run a mutation, you first call `useMarkCollectionNoteAsPrintedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCollectionNoteAsPrintedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCollectionNoteAsPrintedMutation, { data, loading, error }] = useMarkCollectionNoteAsPrintedMutation({
 *   variables: {
 *      collectionNoteId: // value for 'collectionNoteId'
 *   },
 * });
 */
export function useMarkCollectionNoteAsPrintedMutation(baseOptions?: Apollo.MutationHookOptions<MarkCollectionNoteAsPrintedMutation, MarkCollectionNoteAsPrintedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCollectionNoteAsPrintedMutation, MarkCollectionNoteAsPrintedMutationVariables>(MarkCollectionNoteAsPrintedDocument, options);
      }
export type MarkCollectionNoteAsPrintedMutationHookResult = ReturnType<typeof useMarkCollectionNoteAsPrintedMutation>;
export type MarkCollectionNoteAsPrintedMutationResult = Apollo.MutationResult<MarkCollectionNoteAsPrintedMutation>;
export type MarkCollectionNoteAsPrintedMutationOptions = Apollo.BaseMutationOptions<MarkCollectionNoteAsPrintedMutation, MarkCollectionNoteAsPrintedMutationVariables>;
export const ToggleCollectionNoteClosedStatusDocument = gql`
    mutation ToggleCollectionNoteClosedStatus($collectionNoteId: String!) {
  toggleCollectionNoteClosedStatus(input: {collectionNoteId: $collectionNoteId}) {
    id
    closed
  }
}
    `;
export type ToggleCollectionNoteClosedStatusMutationFn = Apollo.MutationFunction<ToggleCollectionNoteClosedStatusMutation, ToggleCollectionNoteClosedStatusMutationVariables>;

/**
 * __useToggleCollectionNoteClosedStatusMutation__
 *
 * To run a mutation, you first call `useToggleCollectionNoteClosedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCollectionNoteClosedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCollectionNoteClosedStatusMutation, { data, loading, error }] = useToggleCollectionNoteClosedStatusMutation({
 *   variables: {
 *      collectionNoteId: // value for 'collectionNoteId'
 *   },
 * });
 */
export function useToggleCollectionNoteClosedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCollectionNoteClosedStatusMutation, ToggleCollectionNoteClosedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCollectionNoteClosedStatusMutation, ToggleCollectionNoteClosedStatusMutationVariables>(ToggleCollectionNoteClosedStatusDocument, options);
      }
export type ToggleCollectionNoteClosedStatusMutationHookResult = ReturnType<typeof useToggleCollectionNoteClosedStatusMutation>;
export type ToggleCollectionNoteClosedStatusMutationResult = Apollo.MutationResult<ToggleCollectionNoteClosedStatusMutation>;
export type ToggleCollectionNoteClosedStatusMutationOptions = Apollo.BaseMutationOptions<ToggleCollectionNoteClosedStatusMutation, ToggleCollectionNoteClosedStatusMutationVariables>;
export const CreateSupplierContactDocument = gql`
    mutation CreateSupplierContact($input: CreateSupplierContactInput!) {
  createSupplierContact(input: $input) {
    id
  }
}
    `;
export type CreateSupplierContactMutationFn = Apollo.MutationFunction<CreateSupplierContactMutation, CreateSupplierContactMutationVariables>;

/**
 * __useCreateSupplierContactMutation__
 *
 * To run a mutation, you first call `useCreateSupplierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierContactMutation, { data, loading, error }] = useCreateSupplierContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierContactMutation, CreateSupplierContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierContactMutation, CreateSupplierContactMutationVariables>(CreateSupplierContactDocument, options);
      }
export type CreateSupplierContactMutationHookResult = ReturnType<typeof useCreateSupplierContactMutation>;
export type CreateSupplierContactMutationResult = Apollo.MutationResult<CreateSupplierContactMutation>;
export type CreateSupplierContactMutationOptions = Apollo.BaseMutationOptions<CreateSupplierContactMutation, CreateSupplierContactMutationVariables>;
export const ContactEditGetSupplierContactDocument = gql`
    query ContactEditGetSupplierContact($contactId: String!) {
  supplierContact(where: {id: $contactId}) {
    id
    name
    email
    notes
    phoneNumber
  }
}
    `;

/**
 * __useContactEditGetSupplierContactQuery__
 *
 * To run a query within a React component, call `useContactEditGetSupplierContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEditGetSupplierContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEditGetSupplierContactQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useContactEditGetSupplierContactQuery(baseOptions: Apollo.QueryHookOptions<ContactEditGetSupplierContactQuery, ContactEditGetSupplierContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactEditGetSupplierContactQuery, ContactEditGetSupplierContactQueryVariables>(ContactEditGetSupplierContactDocument, options);
      }
export function useContactEditGetSupplierContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactEditGetSupplierContactQuery, ContactEditGetSupplierContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactEditGetSupplierContactQuery, ContactEditGetSupplierContactQueryVariables>(ContactEditGetSupplierContactDocument, options);
        }
export type ContactEditGetSupplierContactQueryHookResult = ReturnType<typeof useContactEditGetSupplierContactQuery>;
export type ContactEditGetSupplierContactLazyQueryHookResult = ReturnType<typeof useContactEditGetSupplierContactLazyQuery>;
export type ContactEditGetSupplierContactQueryResult = Apollo.QueryResult<ContactEditGetSupplierContactQuery, ContactEditGetSupplierContactQueryVariables>;
export const ContactEditUpdateSupplierContactDocument = gql`
    mutation ContactEditUpdateSupplierContact($input: UpdateSupplierContactInput!) {
  updateSupplierContact(input: $input) {
    id
  }
}
    `;
export type ContactEditUpdateSupplierContactMutationFn = Apollo.MutationFunction<ContactEditUpdateSupplierContactMutation, ContactEditUpdateSupplierContactMutationVariables>;

/**
 * __useContactEditUpdateSupplierContactMutation__
 *
 * To run a mutation, you first call `useContactEditUpdateSupplierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactEditUpdateSupplierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactEditUpdateSupplierContactMutation, { data, loading, error }] = useContactEditUpdateSupplierContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactEditUpdateSupplierContactMutation(baseOptions?: Apollo.MutationHookOptions<ContactEditUpdateSupplierContactMutation, ContactEditUpdateSupplierContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactEditUpdateSupplierContactMutation, ContactEditUpdateSupplierContactMutationVariables>(ContactEditUpdateSupplierContactDocument, options);
      }
export type ContactEditUpdateSupplierContactMutationHookResult = ReturnType<typeof useContactEditUpdateSupplierContactMutation>;
export type ContactEditUpdateSupplierContactMutationResult = Apollo.MutationResult<ContactEditUpdateSupplierContactMutation>;
export type ContactEditUpdateSupplierContactMutationOptions = Apollo.BaseMutationOptions<ContactEditUpdateSupplierContactMutation, ContactEditUpdateSupplierContactMutationVariables>;
export const DeleteSupplierContactDocument = gql`
    mutation DeleteSupplierContact($input: DeleteSupplierContactInput!) {
  deleteSupplierContact(input: $input) {
    id
  }
}
    `;
export type DeleteSupplierContactMutationFn = Apollo.MutationFunction<DeleteSupplierContactMutation, DeleteSupplierContactMutationVariables>;

/**
 * __useDeleteSupplierContactMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierContactMutation, { data, loading, error }] = useDeleteSupplierContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSupplierContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSupplierContactMutation, DeleteSupplierContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSupplierContactMutation, DeleteSupplierContactMutationVariables>(DeleteSupplierContactDocument, options);
      }
export type DeleteSupplierContactMutationHookResult = ReturnType<typeof useDeleteSupplierContactMutation>;
export type DeleteSupplierContactMutationResult = Apollo.MutationResult<DeleteSupplierContactMutation>;
export type DeleteSupplierContactMutationOptions = Apollo.BaseMutationOptions<DeleteSupplierContactMutation, DeleteSupplierContactMutationVariables>;
export const ContactListGetContactsDocument = gql`
    query ContactListGetContacts($slug: String!, $offset: Int, $limit: Int) {
  supplierContacts(
    where: {supplier: {slug: {equals: $slug}}}
    orderBy: [{name: asc}]
    skip: $offset
    take: $limit
  ) {
    id
    name
    email
    notes
    phoneNumber
  }
  supplierContactCount(slug: $slug)
}
    `;

/**
 * __useContactListGetContactsQuery__
 *
 * To run a query within a React component, call `useContactListGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListGetContactsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useContactListGetContactsQuery(baseOptions: Apollo.QueryHookOptions<ContactListGetContactsQuery, ContactListGetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactListGetContactsQuery, ContactListGetContactsQueryVariables>(ContactListGetContactsDocument, options);
      }
export function useContactListGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListGetContactsQuery, ContactListGetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactListGetContactsQuery, ContactListGetContactsQueryVariables>(ContactListGetContactsDocument, options);
        }
export type ContactListGetContactsQueryHookResult = ReturnType<typeof useContactListGetContactsQuery>;
export type ContactListGetContactsLazyQueryHookResult = ReturnType<typeof useContactListGetContactsLazyQuery>;
export type ContactListGetContactsQueryResult = Apollo.QueryResult<ContactListGetContactsQuery, ContactListGetContactsQueryVariables>;
export const GetInvoiceCollectionNoteMatchDocument = gql`
    query GetInvoiceCollectionNoteMatch($supplierSlug: String!, $invoiceId: String!) {
  invoice(where: {id: $invoiceId}) {
    id
    reference
    net
    vat
    gross
  }
  collectionNoteLines(where: {supplierSlug: $supplierSlug, invoiced: false}) {
    id
    quantity
    description
    value
    vat
    total
    collectionNote {
      id
      referenceId
      creationDate
    }
  }
}
    `;

/**
 * __useGetInvoiceCollectionNoteMatchQuery__
 *
 * To run a query within a React component, call `useGetInvoiceCollectionNoteMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceCollectionNoteMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceCollectionNoteMatchQuery({
 *   variables: {
 *      supplierSlug: // value for 'supplierSlug'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceCollectionNoteMatchQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceCollectionNoteMatchQuery, GetInvoiceCollectionNoteMatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceCollectionNoteMatchQuery, GetInvoiceCollectionNoteMatchQueryVariables>(GetInvoiceCollectionNoteMatchDocument, options);
      }
export function useGetInvoiceCollectionNoteMatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceCollectionNoteMatchQuery, GetInvoiceCollectionNoteMatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceCollectionNoteMatchQuery, GetInvoiceCollectionNoteMatchQueryVariables>(GetInvoiceCollectionNoteMatchDocument, options);
        }
export type GetInvoiceCollectionNoteMatchQueryHookResult = ReturnType<typeof useGetInvoiceCollectionNoteMatchQuery>;
export type GetInvoiceCollectionNoteMatchLazyQueryHookResult = ReturnType<typeof useGetInvoiceCollectionNoteMatchLazyQuery>;
export type GetInvoiceCollectionNoteMatchQueryResult = Apollo.QueryResult<GetInvoiceCollectionNoteMatchQuery, GetInvoiceCollectionNoteMatchQueryVariables>;
export const MatchInvoiceToCollectionNoteLinesDocument = gql`
    mutation MatchInvoiceToCollectionNoteLines($input: MatchCollectionNotesToInvoiceInput!) {
  matchCollectionNotesToInvoice(input: $input) {
    id
    completed
  }
}
    `;
export type MatchInvoiceToCollectionNoteLinesMutationFn = Apollo.MutationFunction<MatchInvoiceToCollectionNoteLinesMutation, MatchInvoiceToCollectionNoteLinesMutationVariables>;

/**
 * __useMatchInvoiceToCollectionNoteLinesMutation__
 *
 * To run a mutation, you first call `useMatchInvoiceToCollectionNoteLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchInvoiceToCollectionNoteLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchInvoiceToCollectionNoteLinesMutation, { data, loading, error }] = useMatchInvoiceToCollectionNoteLinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMatchInvoiceToCollectionNoteLinesMutation(baseOptions?: Apollo.MutationHookOptions<MatchInvoiceToCollectionNoteLinesMutation, MatchInvoiceToCollectionNoteLinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MatchInvoiceToCollectionNoteLinesMutation, MatchInvoiceToCollectionNoteLinesMutationVariables>(MatchInvoiceToCollectionNoteLinesDocument, options);
      }
export type MatchInvoiceToCollectionNoteLinesMutationHookResult = ReturnType<typeof useMatchInvoiceToCollectionNoteLinesMutation>;
export type MatchInvoiceToCollectionNoteLinesMutationResult = Apollo.MutationResult<MatchInvoiceToCollectionNoteLinesMutation>;
export type MatchInvoiceToCollectionNoteLinesMutationOptions = Apollo.BaseMutationOptions<MatchInvoiceToCollectionNoteLinesMutation, MatchInvoiceToCollectionNoteLinesMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($input: CreateInvoiceInput!) {
  createInvoice(input: $input) {
    id
  }
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const InvoiceEditPageGetInvoiceDocument = gql`
    query InvoiceEditPageGetInvoice($invoiceId: String!) {
  invoice(where: {id: $invoiceId}) {
    id
    reference
    supplierInvoiceNumber
    net
    vat
    gross
    comments
    completed
  }
}
    `;

/**
 * __useInvoiceEditPageGetInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceEditPageGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceEditPageGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceEditPageGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceEditPageGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceEditPageGetInvoiceQuery, InvoiceEditPageGetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceEditPageGetInvoiceQuery, InvoiceEditPageGetInvoiceQueryVariables>(InvoiceEditPageGetInvoiceDocument, options);
      }
export function useInvoiceEditPageGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceEditPageGetInvoiceQuery, InvoiceEditPageGetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceEditPageGetInvoiceQuery, InvoiceEditPageGetInvoiceQueryVariables>(InvoiceEditPageGetInvoiceDocument, options);
        }
export type InvoiceEditPageGetInvoiceQueryHookResult = ReturnType<typeof useInvoiceEditPageGetInvoiceQuery>;
export type InvoiceEditPageGetInvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceEditPageGetInvoiceLazyQuery>;
export type InvoiceEditPageGetInvoiceQueryResult = Apollo.QueryResult<InvoiceEditPageGetInvoiceQuery, InvoiceEditPageGetInvoiceQueryVariables>;
export const InvoiceEditPageUpdateInvoiceDocument = gql`
    mutation InvoiceEditPageUpdateInvoice($input: UpdateInvoiceInput!) {
  updateInvoice(input: $input) {
    id
  }
}
    `;
export type InvoiceEditPageUpdateInvoiceMutationFn = Apollo.MutationFunction<InvoiceEditPageUpdateInvoiceMutation, InvoiceEditPageUpdateInvoiceMutationVariables>;

/**
 * __useInvoiceEditPageUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useInvoiceEditPageUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceEditPageUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceEditPageUpdateInvoiceMutation, { data, loading, error }] = useInvoiceEditPageUpdateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceEditPageUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceEditPageUpdateInvoiceMutation, InvoiceEditPageUpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceEditPageUpdateInvoiceMutation, InvoiceEditPageUpdateInvoiceMutationVariables>(InvoiceEditPageUpdateInvoiceDocument, options);
      }
export type InvoiceEditPageUpdateInvoiceMutationHookResult = ReturnType<typeof useInvoiceEditPageUpdateInvoiceMutation>;
export type InvoiceEditPageUpdateInvoiceMutationResult = Apollo.MutationResult<InvoiceEditPageUpdateInvoiceMutation>;
export type InvoiceEditPageUpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<InvoiceEditPageUpdateInvoiceMutation, InvoiceEditPageUpdateInvoiceMutationVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices($slug: String!, $offset: Int, $limit: Int, $orderBy: [QueryInvoicesOrderByInput!], $referenceFilter: String, $supplierInvoiceNumberFilter: String, $commentsFilter: String, $filter: [FilterInput!]!) {
  invoices(
    where: {supplier: {slug: {equals: $slug}}, reference: {contains: $referenceFilter, mode: insensitive}, supplierInvoiceNumber: {contains: $supplierInvoiceNumberFilter, mode: insensitive}, comments: {contains: $commentsFilter, mode: insensitive}}
    orderBy: $orderBy
    skip: $offset
    take: $limit
  ) {
    id
    reference
    supplierInvoiceNumber
    net
    vat
    gross
    comments
    completed
  }
  invoiceCount(input: {supplierSlug: $slug, filter: $filter})
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      referenceFilter: // value for 'referenceFilter'
 *      supplierInvoiceNumberFilter: // value for 'supplierInvoiceNumberFilter'
 *      commentsFilter: // value for 'commentsFilter'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetInvoiceDocument = gql`
    query GetInvoice($invoiceId: String!) {
  invoice(where: {id: $invoiceId}) {
    id
    reference
    supplierInvoiceNumber
    net
    vat
    gross
    completed
    comments
    hasMatchedCollectionNotes
    collectionNoteLines {
      id
      collectionNote {
        id
        referenceId
      }
      quantity
      description
      value
      vat
      total
      ewcCode {
        code
      }
      nominalCode {
        code
      }
    }
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const RemoveMatchedCollectionNotesFromInvoiceDocument = gql`
    mutation RemoveMatchedCollectionNotesFromInvoice($input: RemoveMatchedCollectionNotesFromInvoiceInput!) {
  removeMatchedCollectionNotesFromInvoice(input: $input) {
    id
    completed
  }
}
    `;
export type RemoveMatchedCollectionNotesFromInvoiceMutationFn = Apollo.MutationFunction<RemoveMatchedCollectionNotesFromInvoiceMutation, RemoveMatchedCollectionNotesFromInvoiceMutationVariables>;

/**
 * __useRemoveMatchedCollectionNotesFromInvoiceMutation__
 *
 * To run a mutation, you first call `useRemoveMatchedCollectionNotesFromInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatchedCollectionNotesFromInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatchedCollectionNotesFromInvoiceMutation, { data, loading, error }] = useRemoveMatchedCollectionNotesFromInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMatchedCollectionNotesFromInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMatchedCollectionNotesFromInvoiceMutation, RemoveMatchedCollectionNotesFromInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMatchedCollectionNotesFromInvoiceMutation, RemoveMatchedCollectionNotesFromInvoiceMutationVariables>(RemoveMatchedCollectionNotesFromInvoiceDocument, options);
      }
export type RemoveMatchedCollectionNotesFromInvoiceMutationHookResult = ReturnType<typeof useRemoveMatchedCollectionNotesFromInvoiceMutation>;
export type RemoveMatchedCollectionNotesFromInvoiceMutationResult = Apollo.MutationResult<RemoveMatchedCollectionNotesFromInvoiceMutation>;
export type RemoveMatchedCollectionNotesFromInvoiceMutationOptions = Apollo.BaseMutationOptions<RemoveMatchedCollectionNotesFromInvoiceMutation, RemoveMatchedCollectionNotesFromInvoiceMutationVariables>;
export const InvoicePageDeleteInvoiceDocument = gql`
    mutation InvoicePageDeleteInvoice($input: DeleteInvoiceInput!) {
  deleteInvoice(input: $input) {
    id
  }
}
    `;
export type InvoicePageDeleteInvoiceMutationFn = Apollo.MutationFunction<InvoicePageDeleteInvoiceMutation, InvoicePageDeleteInvoiceMutationVariables>;

/**
 * __useInvoicePageDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useInvoicePageDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicePageDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicePageDeleteInvoiceMutation, { data, loading, error }] = useInvoicePageDeleteInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoicePageDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<InvoicePageDeleteInvoiceMutation, InvoicePageDeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoicePageDeleteInvoiceMutation, InvoicePageDeleteInvoiceMutationVariables>(InvoicePageDeleteInvoiceDocument, options);
      }
export type InvoicePageDeleteInvoiceMutationHookResult = ReturnType<typeof useInvoicePageDeleteInvoiceMutation>;
export type InvoicePageDeleteInvoiceMutationResult = Apollo.MutationResult<InvoicePageDeleteInvoiceMutation>;
export type InvoicePageDeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<InvoicePageDeleteInvoiceMutation, InvoicePageDeleteInvoiceMutationVariables>;
export const ToggleInvoiceCompletedStatusDocument = gql`
    mutation ToggleInvoiceCompletedStatus($invoiceId: String!) {
  toggleInvoiceCompletedStatus(input: {invoiceId: $invoiceId}) {
    id
    completed
  }
}
    `;
export type ToggleInvoiceCompletedStatusMutationFn = Apollo.MutationFunction<ToggleInvoiceCompletedStatusMutation, ToggleInvoiceCompletedStatusMutationVariables>;

/**
 * __useToggleInvoiceCompletedStatusMutation__
 *
 * To run a mutation, you first call `useToggleInvoiceCompletedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleInvoiceCompletedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleInvoiceCompletedStatusMutation, { data, loading, error }] = useToggleInvoiceCompletedStatusMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useToggleInvoiceCompletedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleInvoiceCompletedStatusMutation, ToggleInvoiceCompletedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleInvoiceCompletedStatusMutation, ToggleInvoiceCompletedStatusMutationVariables>(ToggleInvoiceCompletedStatusDocument, options);
      }
export type ToggleInvoiceCompletedStatusMutationHookResult = ReturnType<typeof useToggleInvoiceCompletedStatusMutation>;
export type ToggleInvoiceCompletedStatusMutationResult = Apollo.MutationResult<ToggleInvoiceCompletedStatusMutation>;
export type ToggleInvoiceCompletedStatusMutationOptions = Apollo.BaseMutationOptions<ToggleInvoiceCompletedStatusMutation, ToggleInvoiceCompletedStatusMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation CreateQuote($input: CreateQuoteInput!) {
  createQuote(input: $input) {
    id
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const QuoteEditPageGetQuoteDocument = gql`
    query QuoteEditPageGetQuote($quoteId: String!) {
  quote(where: {id: $quoteId}) {
    id
    quoteReference
    contact
    quotedBy
    creationDate
    closed
    notes
  }
}
    `;

/**
 * __useQuoteEditPageGetQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteEditPageGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteEditPageGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteEditPageGetQuoteQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteEditPageGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<QuoteEditPageGetQuoteQuery, QuoteEditPageGetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteEditPageGetQuoteQuery, QuoteEditPageGetQuoteQueryVariables>(QuoteEditPageGetQuoteDocument, options);
      }
export function useQuoteEditPageGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteEditPageGetQuoteQuery, QuoteEditPageGetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteEditPageGetQuoteQuery, QuoteEditPageGetQuoteQueryVariables>(QuoteEditPageGetQuoteDocument, options);
        }
export type QuoteEditPageGetQuoteQueryHookResult = ReturnType<typeof useQuoteEditPageGetQuoteQuery>;
export type QuoteEditPageGetQuoteLazyQueryHookResult = ReturnType<typeof useQuoteEditPageGetQuoteLazyQuery>;
export type QuoteEditPageGetQuoteQueryResult = Apollo.QueryResult<QuoteEditPageGetQuoteQuery, QuoteEditPageGetQuoteQueryVariables>;
export const QuoteEditPageUpdateQuoteDocument = gql`
    mutation QuoteEditPageUpdateQuote($input: UpdateQuoteInput!) {
  updateQuote(input: $input) {
    id
  }
}
    `;
export type QuoteEditPageUpdateQuoteMutationFn = Apollo.MutationFunction<QuoteEditPageUpdateQuoteMutation, QuoteEditPageUpdateQuoteMutationVariables>;

/**
 * __useQuoteEditPageUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useQuoteEditPageUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteEditPageUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteEditPageUpdateQuoteMutation, { data, loading, error }] = useQuoteEditPageUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteEditPageUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<QuoteEditPageUpdateQuoteMutation, QuoteEditPageUpdateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteEditPageUpdateQuoteMutation, QuoteEditPageUpdateQuoteMutationVariables>(QuoteEditPageUpdateQuoteDocument, options);
      }
export type QuoteEditPageUpdateQuoteMutationHookResult = ReturnType<typeof useQuoteEditPageUpdateQuoteMutation>;
export type QuoteEditPageUpdateQuoteMutationResult = Apollo.MutationResult<QuoteEditPageUpdateQuoteMutation>;
export type QuoteEditPageUpdateQuoteMutationOptions = Apollo.BaseMutationOptions<QuoteEditPageUpdateQuoteMutation, QuoteEditPageUpdateQuoteMutationVariables>;
export const GetQuotesDocument = gql`
    query GetQuotes($slug: String!, $offset: Int, $limit: Int) {
  quotes(
    where: {supplier: {slug: {equals: $slug}}}
    orderBy: {creationDate: desc}
    skip: $offset
    take: $limit
  ) {
    id
    quoteReference
    contact
    quotedBy
    creationDate
    closed
  }
  quoteCount(slug: $slug)
}
    `;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions: Apollo.QueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, options);
      }
export function useGetQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, options);
        }
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
export type GetQuotesQueryResult = Apollo.QueryResult<GetQuotesQuery, GetQuotesQueryVariables>;
export const GetQuoteDocument = gql`
    query GetQuote($quoteId: String!) {
  quote(where: {id: $quoteId}) {
    id
    quoteReference
    contact
    quotedBy
    creationDate
    closed
    notes
    quoteLines(orderBy: {material: asc}) {
      id
      material
      tonnage
      price
    }
  }
}
    `;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export const GetQuotePdfDocument = gql`
    query GetQuotePDF($quoteId: String!) {
  quote(where: {id: $quoteId}) {
    quoteReference
    pdf
  }
}
    `;

/**
 * __useGetQuotePdfQuery__
 *
 * To run a query within a React component, call `useGetQuotePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotePdfQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuotePdfQuery(baseOptions: Apollo.QueryHookOptions<GetQuotePdfQuery, GetQuotePdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotePdfQuery, GetQuotePdfQueryVariables>(GetQuotePdfDocument, options);
      }
export function useGetQuotePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotePdfQuery, GetQuotePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotePdfQuery, GetQuotePdfQueryVariables>(GetQuotePdfDocument, options);
        }
export type GetQuotePdfQueryHookResult = ReturnType<typeof useGetQuotePdfQuery>;
export type GetQuotePdfLazyQueryHookResult = ReturnType<typeof useGetQuotePdfLazyQuery>;
export type GetQuotePdfQueryResult = Apollo.QueryResult<GetQuotePdfQuery, GetQuotePdfQueryVariables>;
export const DuplicateQuoteDocument = gql`
    mutation DuplicateQuote($input: DuplicateQuoteInput!) {
  duplicateQuote(input: $input) {
    id
  }
}
    `;
export type DuplicateQuoteMutationFn = Apollo.MutationFunction<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>;

/**
 * __useDuplicateQuoteMutation__
 *
 * To run a mutation, you first call `useDuplicateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateQuoteMutation, { data, loading, error }] = useDuplicateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>(DuplicateQuoteDocument, options);
      }
export type DuplicateQuoteMutationHookResult = ReturnType<typeof useDuplicateQuoteMutation>;
export type DuplicateQuoteMutationResult = Apollo.MutationResult<DuplicateQuoteMutation>;
export type DuplicateQuoteMutationOptions = Apollo.BaseMutationOptions<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>;
export const ToggleQuoteClosedStatusDocument = gql`
    mutation ToggleQuoteClosedStatus($quoteId: String!) {
  toggleQuoteClosedStatus(input: {quoteId: $quoteId}) {
    id
    closed
  }
}
    `;
export type ToggleQuoteClosedStatusMutationFn = Apollo.MutationFunction<ToggleQuoteClosedStatusMutation, ToggleQuoteClosedStatusMutationVariables>;

/**
 * __useToggleQuoteClosedStatusMutation__
 *
 * To run a mutation, you first call `useToggleQuoteClosedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleQuoteClosedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleQuoteClosedStatusMutation, { data, loading, error }] = useToggleQuoteClosedStatusMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useToggleQuoteClosedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleQuoteClosedStatusMutation, ToggleQuoteClosedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleQuoteClosedStatusMutation, ToggleQuoteClosedStatusMutationVariables>(ToggleQuoteClosedStatusDocument, options);
      }
export type ToggleQuoteClosedStatusMutationHookResult = ReturnType<typeof useToggleQuoteClosedStatusMutation>;
export type ToggleQuoteClosedStatusMutationResult = Apollo.MutationResult<ToggleQuoteClosedStatusMutation>;
export type ToggleQuoteClosedStatusMutationOptions = Apollo.BaseMutationOptions<ToggleQuoteClosedStatusMutation, ToggleQuoteClosedStatusMutationVariables>;
export const CreateSiteVisitDocument = gql`
    mutation CreateSiteVisit($input: CreateSiteVisitInput!) {
  createSiteVisit(input: $input) {
    id
  }
}
    `;
export type CreateSiteVisitMutationFn = Apollo.MutationFunction<CreateSiteVisitMutation, CreateSiteVisitMutationVariables>;

/**
 * __useCreateSiteVisitMutation__
 *
 * To run a mutation, you first call `useCreateSiteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteVisitMutation, { data, loading, error }] = useCreateSiteVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteVisitMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteVisitMutation, CreateSiteVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteVisitMutation, CreateSiteVisitMutationVariables>(CreateSiteVisitDocument, options);
      }
export type CreateSiteVisitMutationHookResult = ReturnType<typeof useCreateSiteVisitMutation>;
export type CreateSiteVisitMutationResult = Apollo.MutationResult<CreateSiteVisitMutation>;
export type CreateSiteVisitMutationOptions = Apollo.BaseMutationOptions<CreateSiteVisitMutation, CreateSiteVisitMutationVariables>;
export const SiteVisitEditPageGetSiteVisitDocument = gql`
    query SiteVisitEditPageGetSiteVisit($siteVisitId: String!) {
  siteVisit(where: {id: $siteVisitId}) {
    id
    visitor
    visitDate
    comments
  }
}
    `;

/**
 * __useSiteVisitEditPageGetSiteVisitQuery__
 *
 * To run a query within a React component, call `useSiteVisitEditPageGetSiteVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteVisitEditPageGetSiteVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteVisitEditPageGetSiteVisitQuery({
 *   variables: {
 *      siteVisitId: // value for 'siteVisitId'
 *   },
 * });
 */
export function useSiteVisitEditPageGetSiteVisitQuery(baseOptions: Apollo.QueryHookOptions<SiteVisitEditPageGetSiteVisitQuery, SiteVisitEditPageGetSiteVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteVisitEditPageGetSiteVisitQuery, SiteVisitEditPageGetSiteVisitQueryVariables>(SiteVisitEditPageGetSiteVisitDocument, options);
      }
export function useSiteVisitEditPageGetSiteVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteVisitEditPageGetSiteVisitQuery, SiteVisitEditPageGetSiteVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteVisitEditPageGetSiteVisitQuery, SiteVisitEditPageGetSiteVisitQueryVariables>(SiteVisitEditPageGetSiteVisitDocument, options);
        }
export type SiteVisitEditPageGetSiteVisitQueryHookResult = ReturnType<typeof useSiteVisitEditPageGetSiteVisitQuery>;
export type SiteVisitEditPageGetSiteVisitLazyQueryHookResult = ReturnType<typeof useSiteVisitEditPageGetSiteVisitLazyQuery>;
export type SiteVisitEditPageGetSiteVisitQueryResult = Apollo.QueryResult<SiteVisitEditPageGetSiteVisitQuery, SiteVisitEditPageGetSiteVisitQueryVariables>;
export const SiteVisitEditPageUpdateSiteVisitDocument = gql`
    mutation SiteVisitEditPageUpdateSiteVisit($input: UpdateSiteVisitInput!) {
  updateSiteVisit(input: $input) {
    id
  }
}
    `;
export type SiteVisitEditPageUpdateSiteVisitMutationFn = Apollo.MutationFunction<SiteVisitEditPageUpdateSiteVisitMutation, SiteVisitEditPageUpdateSiteVisitMutationVariables>;

/**
 * __useSiteVisitEditPageUpdateSiteVisitMutation__
 *
 * To run a mutation, you first call `useSiteVisitEditPageUpdateSiteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteVisitEditPageUpdateSiteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteVisitEditPageUpdateSiteVisitMutation, { data, loading, error }] = useSiteVisitEditPageUpdateSiteVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteVisitEditPageUpdateSiteVisitMutation(baseOptions?: Apollo.MutationHookOptions<SiteVisitEditPageUpdateSiteVisitMutation, SiteVisitEditPageUpdateSiteVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteVisitEditPageUpdateSiteVisitMutation, SiteVisitEditPageUpdateSiteVisitMutationVariables>(SiteVisitEditPageUpdateSiteVisitDocument, options);
      }
export type SiteVisitEditPageUpdateSiteVisitMutationHookResult = ReturnType<typeof useSiteVisitEditPageUpdateSiteVisitMutation>;
export type SiteVisitEditPageUpdateSiteVisitMutationResult = Apollo.MutationResult<SiteVisitEditPageUpdateSiteVisitMutation>;
export type SiteVisitEditPageUpdateSiteVisitMutationOptions = Apollo.BaseMutationOptions<SiteVisitEditPageUpdateSiteVisitMutation, SiteVisitEditPageUpdateSiteVisitMutationVariables>;
export const GetSiteVisitsDocument = gql`
    query GetSiteVisits($slug: String!, $offset: Int, $limit: Int) {
  siteVisits(
    where: {supplier: {slug: {equals: $slug}}}
    orderBy: {visitDate: desc}
    skip: $offset
    take: $limit
  ) {
    id
    visitor
    visitDate
    comments
    truncatedComments(input: {max: 25})
  }
  siteVisitCount(slug: $slug)
}
    `;

/**
 * __useGetSiteVisitsQuery__
 *
 * To run a query within a React component, call `useGetSiteVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteVisitsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSiteVisitsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteVisitsQuery, GetSiteVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteVisitsQuery, GetSiteVisitsQueryVariables>(GetSiteVisitsDocument, options);
      }
export function useGetSiteVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteVisitsQuery, GetSiteVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteVisitsQuery, GetSiteVisitsQueryVariables>(GetSiteVisitsDocument, options);
        }
export type GetSiteVisitsQueryHookResult = ReturnType<typeof useGetSiteVisitsQuery>;
export type GetSiteVisitsLazyQueryHookResult = ReturnType<typeof useGetSiteVisitsLazyQuery>;
export type GetSiteVisitsQueryResult = Apollo.QueryResult<GetSiteVisitsQuery, GetSiteVisitsQueryVariables>;
export const CreateSupplierGetContainersDocument = gql`
    query CreateSupplierGetContainers {
  containers(orderBy: {name: asc}) {
    id
    name
    type
  }
}
    `;

/**
 * __useCreateSupplierGetContainersQuery__
 *
 * To run a query within a React component, call `useCreateSupplierGetContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierGetContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateSupplierGetContainersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateSupplierGetContainersQuery(baseOptions?: Apollo.QueryHookOptions<CreateSupplierGetContainersQuery, CreateSupplierGetContainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateSupplierGetContainersQuery, CreateSupplierGetContainersQueryVariables>(CreateSupplierGetContainersDocument, options);
      }
export function useCreateSupplierGetContainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateSupplierGetContainersQuery, CreateSupplierGetContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateSupplierGetContainersQuery, CreateSupplierGetContainersQueryVariables>(CreateSupplierGetContainersDocument, options);
        }
export type CreateSupplierGetContainersQueryHookResult = ReturnType<typeof useCreateSupplierGetContainersQuery>;
export type CreateSupplierGetContainersLazyQueryHookResult = ReturnType<typeof useCreateSupplierGetContainersLazyQuery>;
export type CreateSupplierGetContainersQueryResult = Apollo.QueryResult<CreateSupplierGetContainersQuery, CreateSupplierGetContainersQueryVariables>;
export const CreateSupplierContainerDocument = gql`
    mutation CreateSupplierContainer($input: CreateSupplierContainerInput!) {
  createSupplierContainer(input: $input) {
    id
  }
}
    `;
export type CreateSupplierContainerMutationFn = Apollo.MutationFunction<CreateSupplierContainerMutation, CreateSupplierContainerMutationVariables>;

/**
 * __useCreateSupplierContainerMutation__
 *
 * To run a mutation, you first call `useCreateSupplierContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierContainerMutation, { data, loading, error }] = useCreateSupplierContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierContainerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierContainerMutation, CreateSupplierContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierContainerMutation, CreateSupplierContainerMutationVariables>(CreateSupplierContainerDocument, options);
      }
export type CreateSupplierContainerMutationHookResult = ReturnType<typeof useCreateSupplierContainerMutation>;
export type CreateSupplierContainerMutationResult = Apollo.MutationResult<CreateSupplierContainerMutation>;
export type CreateSupplierContainerMutationOptions = Apollo.BaseMutationOptions<CreateSupplierContainerMutation, CreateSupplierContainerMutationVariables>;
export const UpdateSupplierGetDataDocument = gql`
    query UpdateSupplierGetData($supplierContainerId: String!) {
  containers(orderBy: {name: asc}) {
    id
    name
    type
  }
  supplierContainer(where: {id: $supplierContainerId}) {
    id
    quantity
    container {
      id
    }
  }
}
    `;

/**
 * __useUpdateSupplierGetDataQuery__
 *
 * To run a query within a React component, call `useUpdateSupplierGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateSupplierGetDataQuery({
 *   variables: {
 *      supplierContainerId: // value for 'supplierContainerId'
 *   },
 * });
 */
export function useUpdateSupplierGetDataQuery(baseOptions: Apollo.QueryHookOptions<UpdateSupplierGetDataQuery, UpdateSupplierGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateSupplierGetDataQuery, UpdateSupplierGetDataQueryVariables>(UpdateSupplierGetDataDocument, options);
      }
export function useUpdateSupplierGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateSupplierGetDataQuery, UpdateSupplierGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateSupplierGetDataQuery, UpdateSupplierGetDataQueryVariables>(UpdateSupplierGetDataDocument, options);
        }
export type UpdateSupplierGetDataQueryHookResult = ReturnType<typeof useUpdateSupplierGetDataQuery>;
export type UpdateSupplierGetDataLazyQueryHookResult = ReturnType<typeof useUpdateSupplierGetDataLazyQuery>;
export type UpdateSupplierGetDataQueryResult = Apollo.QueryResult<UpdateSupplierGetDataQuery, UpdateSupplierGetDataQueryVariables>;
export const UpdateSupplierContainerDocument = gql`
    mutation UpdateSupplierContainer($input: UpdateSupplierContainerInput!) {
  updateSupplierContainer(input: $input) {
    id
  }
}
    `;
export type UpdateSupplierContainerMutationFn = Apollo.MutationFunction<UpdateSupplierContainerMutation, UpdateSupplierContainerMutationVariables>;

/**
 * __useUpdateSupplierContainerMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierContainerMutation, { data, loading, error }] = useUpdateSupplierContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierContainerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierContainerMutation, UpdateSupplierContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierContainerMutation, UpdateSupplierContainerMutationVariables>(UpdateSupplierContainerDocument, options);
      }
export type UpdateSupplierContainerMutationHookResult = ReturnType<typeof useUpdateSupplierContainerMutation>;
export type UpdateSupplierContainerMutationResult = Apollo.MutationResult<UpdateSupplierContainerMutation>;
export type UpdateSupplierContainerMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierContainerMutation, UpdateSupplierContainerMutationVariables>;
export const GetSupplierContainersDocument = gql`
    query GetSupplierContainers($slug: String!, $offset: Int, $limit: Int) {
  supplierContainers(
    where: {supplier: {slug: {equals: $slug}}}
    orderBy: {createdAt: desc}
    skip: $offset
    take: $limit
  ) {
    id
    quantity
    container {
      name
    }
  }
  supplierContainerCount(slug: $slug)
}
    `;

/**
 * __useGetSupplierContainersQuery__
 *
 * To run a query within a React component, call `useGetSupplierContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierContainersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSupplierContainersQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierContainersQuery, GetSupplierContainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierContainersQuery, GetSupplierContainersQueryVariables>(GetSupplierContainersDocument, options);
      }
export function useGetSupplierContainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierContainersQuery, GetSupplierContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierContainersQuery, GetSupplierContainersQueryVariables>(GetSupplierContainersDocument, options);
        }
export type GetSupplierContainersQueryHookResult = ReturnType<typeof useGetSupplierContainersQuery>;
export type GetSupplierContainersLazyQueryHookResult = ReturnType<typeof useGetSupplierContainersLazyQuery>;
export type GetSupplierContainersQueryResult = Apollo.QueryResult<GetSupplierContainersQuery, GetSupplierContainersQueryVariables>;