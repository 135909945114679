import React from "react";

import { Status } from "./Status";

interface QuoteStatusProps {
  closed: boolean;
}

export const QuoteStatus: React.FC<QuoteStatusProps> = (props) => {
  return <Status active={props.closed}>{props.closed ? "Closed" : "Open"}</Status>;
};
