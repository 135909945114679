import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, PlusIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Pagination } from "../../components/Pagination";
import { Panel } from "../../components/Panel";
import { Table } from "../../components/Table";
import { buildNewContactPath, RouteParams } from "../../config/routes";
import { useContactListGetContactsQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CONTACT_LIST_GET_CONTACTS = gql`
  query ContactListGetContacts($slug: String!, $offset: Int, $limit: Int) {
    supplierContacts(
      where: { supplier: { slug: { equals: $slug } } }
      orderBy: [{ name: asc }]
      skip: $offset
      take: $limit
    ) {
      id
      name
      email
      notes
      phoneNumber
    }

    supplierContactCount(slug: $slug)
  }
`;

export const ContactListPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useContactListGetContactsQuery({
    variables: {
      slug: supplierSlug,

      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the contacts</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Contact List</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Contacts</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewContactPath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new contact
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="contacts-list"
            data={data?.supplierContacts ?? []}
            columns={contactTableColumns}
            rowActions={({ row }) => {
              return (
                <Stack direction="row">
                  <Link to={`${row.id}/edit`}>
                    <IconButton colorScheme="green" aria-label="Edit contact" icon={<EditIcon />} />
                  </Link>
                </Stack>
              );
            }}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.supplierContactCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const contactTableColumns = [
  { Header: "Name", accessor: "name" },
  { Header: "Email", accessor: "email" },
  { Header: "Notes", accessor: "notes" },
  { Header: "Phone Number", accessor: "phoneNumber" },
];
