import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, PlusIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Pagination } from "../../components/Pagination";
import { Panel } from "../../components/Panel";
import { Table } from "../../components/Table";
import { buildNewSupplierContainerPath, RouteParams } from "../../config/routes";
import { useGetSupplierContainersQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const SUPPLIER_CONTAINERS_LIST_GET_SUPPLIER_CONTAINERS = gql`
  query GetSupplierContainers($slug: String!, $offset: Int, $limit: Int) {
    supplierContainers(
      where: { supplier: { slug: { equals: $slug } } }
      orderBy: { createdAt: desc }
      skip: $offset
      take: $limit
    ) {
      id
      quantity
      container {
        name
      }
    }

    supplierContainerCount(slug: $slug)
  }
`;

export const SupplierContainerListPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useGetSupplierContainersQuery({
    variables: {
      slug: supplierSlug,
      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the containers</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Containers List</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Containers</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewSupplierContainerPath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new container
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="container-list"
            data={data?.supplierContainers ?? []}
            columns={containerTableColumns}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.supplierContainerCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const containerTableColumns = [
  {
    Header: "Container",
    accessor: "container.name",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Link to={`${props.value}/edit`}>
          <IconButton colorScheme="green" aria-label="Edit container" icon={<EditIcon />} />
        </Link>
      );
    },
  },
];
