import { IconButton } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/react";
import currency from "currency.js";
import { useFormikContext } from "formik";
import React from "react";
import { Link } from "react-router-dom";

import { buildInvoicePagePath, RouteParams } from "../config/routes";
import { useSupplier } from "../context/SupplierContext";
import { useSingleParam } from "../hooks/useSingleParam";
import { SelectField } from "./form/Select";
import { Link as LinkIcon, TrashIcon } from "./icons";
import { CheckboxCell, EditableTextCell, Table } from "./Table";

interface CollectionNoteLine {
  id: string | null;
  quantity: string;
  description: string;
  value: string;
  vat: string;
  total: string;
  invoiced: boolean;
  invoiceId?: string;
}

interface EWCCode {
  id: string;
  code: string;
  product: string;
}

interface NominalCode {
  id: string;
  code: string;
}

interface CollectionNotesTableFieldProps {
  name: string;
  data: CollectionNoteLine[];
  ewcCodes: EWCCode[];
  nominalCodes: NominalCode[];
  onRemoveRow: (index: number) => void;
}

export const CollectionNotesTableField: React.FC<CollectionNotesTableFieldProps> = (props) => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);

  const instanceProps = React.useMemo(() => {
    return {
      tableFieldName: props.name,
      ewcCodes: props.ewcCodes,
      nominalCodes: props.nominalCodes,
    };
  }, [props.name, props.ewcCodes, props.nominalCodes]);

  return (
    <Box
      sx={{
        "table tbody input,select": {
          fontSize: "0.8rem !important",
          pl: 2,
          pr: 2,
        },
      }}
    >
      <Table
        mode="compact"
        data-testid="collection-note-lines"
        data={props.data}
        columns={collectionNoteLinesTableColumns}
        instanceProps={instanceProps}
        rowActions={({ index, row }) => {
          return (
            <Flex alignItems="center">
              <IconButton
                aria-label="Delete collection note line"
                icon={<TrashIcon color="red.700" />}
                onClick={() => {
                  props.onRemoveRow(index);
                }}
              />

              {row.invoiceId ? (
                <Box ml={2}>
                  <Link to={buildInvoicePagePath(supplierSlug, row.invoiceId)}>
                    <LinkIcon />
                  </Link>
                </Box>
              ) : null}
            </Flex>
          );
        }}
      />
    </Box>
  );
};

interface CellProps {
  tableFieldName: string;
  ewcCodes: EWCCode[];
  nominalCodes: NominalCode[];
  row: { index: number };
  column: {
    id: string;
    placeholder: string;
  };
}

const collectionNoteLinesTableColumns = [
  {
    id: "quantity",
    accessor: "quantity",
    Header: "Quantity",
    placeholder: "Quantity",
    Cell: EditableTextCell,
    width: "10%",
  },
  {
    id: "description",
    accessor: "description",
    Header: "Description",
    placeholder: "Description",
    Cell: EditableTextCell,
    width: "30%",
  },
  {
    id: "ewcCodeId",
    accessor: "ewcCodeId",
    Header: "EWC Code",
    width: "10%",
    Cell: (props: CellProps) => {
      const fieldKey = `${props.tableFieldName}[${props.row.index}].${props.column.id}`;

      return (
        <SelectField name={fieldKey}>
          <option value=""></option>
          {props.ewcCodes.map((code) => {
            return (
              <option key={code.id} value={code.id}>
                {code.code}: {code.product}
              </option>
            );
          })}
        </SelectField>
      );
    },
  },
  {
    id: "nominalCodeId",
    accessor: "nominalCodeId",
    Header: "Nominal Code",
    width: "10%",
    Cell: (props: CellProps) => {
      const fieldKey = `${props.tableFieldName}[${props.row.index}].${props.column.id}`;

      return (
        <SelectField name={fieldKey}>
          <option value=""></option>
          {props.nominalCodes.map((code) => {
            return (
              <option key={code.id} value={code.id}>
                {code.code}
              </option>
            );
          })}
        </SelectField>
      );
    },
  },
  {
    id: "value",
    accessor: "value",
    Header: "Value",
    placeholder: "20.00",
    Cell: (props: CellProps) => {
      const { supplier } = useSupplier();

      const { setFieldValue, handleChange } = useFormikContext<any>();

      return (
        <EditableTextCell
          {...props}
          onChange={(e: React.ChangeEvent<any>) => {
            handleChange(e);

            const inputValue = e.currentTarget.value;

            if (!inputValue || inputValue.trim() === "") {
              return;
            }

            const vat = currency(inputValue).multiply(supplier.vat / 100);
            const total = currency(inputValue).add(vat);

            const fieldRowKey = `${props.tableFieldName}[${props.row.index}]`;

            setFieldValue(`${fieldRowKey}.vat`, vat.value.toString());
            setFieldValue(`${fieldRowKey}.total`, total.value.toString());
          }}
        />
      );
    },
  },
  {
    id: "vat",
    accessor: "vat",
    Header: "VAT",
    Cell: EditableTextCell,
    placeholder: "4.00",
  },
  {
    id: "total",
    accessor: "total",
    Header: "Total",
    Cell: EditableTextCell,
    placeholder: "24.00",
  },
  {
    id: "invoiced",
    accessor: "invoiced",
    Header: "Invoiced",
    Cell: CheckboxCell,
    readonly: true,
    width: "7.5%",
  },
];
