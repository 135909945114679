import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { ContainerForm } from "../../../components/ContainerForm";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Panel } from "../../../components/Panel";
import { buildAdminContainerListPath, RouteParams } from "../../../config/routes";
import {
  useContainerEditPageGetContainerQuery,
  useContainerEditPageUpdateContainerMutation,
} from "../../../generated/graphql";
import { useSingleParam } from "../../../hooks/useSingleParam";

export const CONTAINER_EDIT_PAGE_GET_CONTAINER = gql`
  query ContainerEditPageGetContainer($containerId: String!) {
    container(where: { id: $containerId }) {
      id
      name
      type
    }
  }
`;

export const CONTAINER_EDIT_PAGE_UPDATE_CONTAINER = gql`
  mutation ContainerEditPageUpdateContainer($input: UpdateContainerInput!) {
    updateContainer(input: $input) {
      id
      name
      type
    }
  }
`;

export const ContainerEditPage = () => {
  const navigate = useNavigate();
  const containerId = useSingleParam(RouteParams.ContainerId);

  const [updateContainer] = useContainerEditPageUpdateContainerMutation();

  const { loading, error, data } = useContainerEditPageGetContainerQuery({
    variables: { containerId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the container</Text>
      </Stack>
    );
  }

  if (!data || !data.container) {
    return <Navigate to={buildAdminContainerListPath()} />;
  }

  const { container } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Container {container.name}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Container{" "}
            <Text as="span" color="blue.500">
              {container.name}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <ContainerForm
              initialValues={{
                name: container.name,
                type: container.type,
              }}
              onSubmit={async (values) => {
                await updateContainer({
                  variables: {
                    input: {
                      ...values,
                      containerId,
                    },
                  },
                });

                navigate(buildAdminContainerListPath());
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
