import { gql } from "@apollo/client";
import { Button, Flex, IconButton, useToast } from "@chakra-ui/react";
import { FieldArray, Form, Formik } from "formik";
import { Fragment, useMemo } from "react";
import * as yup from "yup";

import { useSupplier } from "../context/SupplierContext";
import { Currency, useQuoteLinesUpdateQuoteLinesMutation } from "../generated/graphql";
import { getCurrencySymbol } from "../models/currency";
import { TrashIcon } from "./icons";
import { Panel } from "./Panel";
import { EditableTextCell, Table } from "./Table";

export const UPDATE_QUOTE_LINES = gql`
  mutation QuoteLinesUpdateQuoteLines($input: UpdateQuoteLinesInput!) {
    updateQuoteLines(input: $input) {
      id
      quoteLines {
        id
        material
        tonnage
        price
      }
    }
  }
`;

const QuoteLinesSchema = yup.object().shape({
  quoteLines: yup.array(
    yup.object().shape({
      id: yup.string().nullable(),
      material: yup.string().required("material is required"),
      tonnage: yup.string().required("tonnage is required"),
      price: yup.string().required("price is required"),
    })
  ),
});

interface QuoteLine {
  id: string | null;
  material: string;
  tonnage: string;
  price: string;
}

interface QuoteLinesProps {
  quoteId: string;
  quoteLines: QuoteLine[];
}

export const QuoteLines: React.FC<QuoteLinesProps> = ({ quoteId, quoteLines }) => {
  const toast = useToast();
  const { supplier } = useSupplier();
  const [updateQuoteLines] = useQuoteLinesUpdateQuoteLinesMutation({
    onCompleted: () => {
      toast({ title: "Quote lines saved", status: "success" });
    },
  });

  return (
    <Panel overflowY="auto">
      <Formik
        enableReinitialize
        initialValues={{
          quoteLines: quoteLines.length > 0 ? quoteLines : [makeNewQuoteLine(supplier.currency)],
        }}
        validationSchema={QuoteLinesSchema}
        onSubmit={async (values) => {
          const formattedQuoteLines = values.quoteLines.map((line) => {
            return {
              id: line.id,
              material: line.material,
              tonnage: line.tonnage,
              price: line.price,
            };
          });

          await updateQuoteLines({
            variables: {
              input: {
                quoteId,
                quoteLines: formattedQuoteLines,
              },
            },
          });
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <FieldArray name="quoteLines">
                {(arrayHelpers) => {
                  return (
                    <Fragment>
                      <QuoteLinesTableField
                        name="quoteLines"
                        data={values.quoteLines}
                        onRemoveRow={arrayHelpers.remove}
                      />

                      <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
                        <Button
                          tye="button"
                          colorScheme="teal"
                          onClick={() => {
                            arrayHelpers.push(makeNewQuoteLine(supplier.currency));
                          }}
                        >
                          Add a new row
                        </Button>

                        <Button type="submit" colorScheme="blue" isDisabled={isSubmitting}>
                          Save changes
                        </Button>
                      </Flex>
                    </Fragment>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
};

const makeNewQuoteLine = (currencyCode: Currency) => {
  return {
    id: null,
    material: "",
    tonnage: "",
    price: getCurrencySymbol(currencyCode),
  };
};

interface QuoteLinesTableFieldProps {
  name: string;
  data: QuoteLine[];
  onRemoveRow: (index: number) => void;
}

const QuoteLinesTableField: React.FC<QuoteLinesTableFieldProps> = (props) => {
  const instanceProps = useMemo(() => {
    return {
      tableFieldName: props.name,
    };
  }, [props.name]);

  return (
    <Table
      data-testid="quote-lines"
      data={props.data}
      columns={quoteLinesTableColumns}
      instanceProps={instanceProps}
      rowActions={({ index }) => {
        return (
          <IconButton
            aria-label="Delete quote line"
            icon={<TrashIcon color="red.700" />}
            onClick={() => {
              props.onRemoveRow(index);
            }}
          />
        );
      }}
    />
  );
};

const quoteLinesTableColumns = [
  {
    id: "material",
    Header: "Material",
    accessor: "material",
    Cell: EditableTextCell,
    placeholder: "Material",
    width: "40%",
  },
  {
    id: "tonnage",
    Header: "Tonnage",
    accessor: "tonnage",
    Cell: EditableTextCell,
    placeholder: "Tonnage",
    width: "30%",
  },
  {
    id: "price",
    Header: "Price",
    accessor: "price",
    Cell: EditableTextCell,
    placeholder: "Price",
    width: "30%",
  },
];
