import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { msalInstance } from "../AppProviders";
import { loginRequest } from "../auth/authConfig";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const withToken = setContext(async () => {
  if (process.env.NODE_ENV === "development") {
    return;
  }

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  return { token: response.accessToken };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

export const graphQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([withToken, authMiddleware.concat(httpLink)]),
});

graphQLClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
  },
  query: {
    fetchPolicy: "network-only",
  },
};
