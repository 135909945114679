import "@reach/menu-button/styles.css";
import "./ColumnMenu.css";

import { Box, chakra, Flex, Input } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuItem as ReachMenuItem,
  MenuItems as ReachMenuItems,
  MenuPopover as ReachMenuPopover,
} from "@reach/menu-button";
import React from "react";

import { SortAscending, SortDescending } from "../icons";

interface ColumnMenuProps {
  children: React.ReactNode;
  isSortable: boolean;
  isSortedDesc?: boolean;
  isFilterable: boolean;
  filterValue?: string;
  setFilterValue: (value: string) => void;
  toggleSortBy: (descending: boolean) => void;
}

export const ColumnMenu = (props: ColumnMenuProps) => {
  if (!props.isSortable && !props.isFilterable) {
    return <Box fontWeight={400}>{props.children}</Box>;
  }

  return (
    <Menu>
      <MenuButton>
        {props.children}
        {props.filterValue ? (
          <Box mt="1">
            <ActiveFilterValue>{props.filterValue}</ActiveFilterValue>
          </Box>
        ) : null}
      </MenuButton>
      <MenuPopover>
        <MenuItems>
          {props.isSortable ? (
            <React.Fragment>
              <MenuItem
                onSelect={() => {
                  if (typeof props.isSortedDesc === "undefined" || props.isSortedDesc) {
                    props.toggleSortBy(false);
                  }
                }}
              >
                <Flex direction="row" gridColumnGap={2}>
                  <Flex direction="row" gridColumnGap={2}>
                    <SortAscending /> Sort Asc
                  </Flex>
                </Flex>
              </MenuItem>
              <MenuItem
                onSelect={() => {
                  if (typeof props.isSortedDesc === "undefined" || !props.isSortedDesc) {
                    props.toggleSortBy(true);
                  }
                }}
              >
                <Flex direction="row" gridColumnGap={2}>
                  <SortDescending /> Sort Desc
                </Flex>
              </MenuItem>
            </React.Fragment>
          ) : null}

          {props.isSortable && props.isFilterable ? <HorizontalRule /> : null}

          {props.isFilterable ? (
            <Box px={4} pb={2}>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Filter column"
                value={props.filterValue}
                onChange={(e) => {
                  props.setFilterValue(e.currentTarget.value);
                }}
              />
            </Box>
          ) : null}
        </MenuItems>
      </MenuPopover>
    </Menu>
  );
};

const MenuPopover = chakra(ReachMenuPopover, {
  baseStyle: {
    zIndex: 3,
    padding: 0,
    width: "250px",
    borderRadius: "6px",
    boxShadow: "0 2px 6px 2px rgb(60 64 67 / 15%)",
  },
});

const MenuItems = chakra(ReachMenuItems, {
  baseStyle: {
    paddingX: 0,
    paddingY: 2,
    borderRadius: "6px",
  },
});

const MenuItem = chakra(ReachMenuItem, {
  baseStyle: {
    paddingX: 2,
    paddingY: 1,
  },
});

const HorizontalRule = chakra("hr", {
  baseStyle: {
    paddingY: 1,
    marginY: 2,
  },
});

interface ActiveFilterValueProps {
  children: React.ReactNode;
}

const ActiveFilterValue = (props: ActiveFilterValueProps) => {
  return (
    <Box display="inline" backgroundColor="blue.300" color="white" p={1} borderRadius="lg">
      {props.children}
    </Box>
  );
};
