import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text, useToast } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, EyeIcon, PlusIcon } from "../../components/icons";
import { InvoiceCompletedStatus } from "../../components/InvoiceCompletedStatus";
import { PageTitle } from "../../components/Page";
import { StyledLink } from "../../components/StyledLink";
import { FinancialFigureCell } from "../../components/Table";
import { FetchDataCallbackOptions, Filters, Table } from "../../components/TableV2/Table";
import { buildNewInvoicePath, RouteParams } from "../../config/routes";
import { Invoice, useGetInvoicesLazyQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const INVOICE_LIST_GET_INVOICES = gql`
  query GetInvoices(
    $slug: String!
    $offset: Int
    $limit: Int
    $orderBy: [QueryInvoicesOrderByInput!]
    $referenceFilter: String
    $supplierInvoiceNumberFilter: String
    $commentsFilter: String
    $filter: [FilterInput!]!
  ) {
    invoices(
      where: {
        supplier: { slug: { equals: $slug } }
        reference: { contains: $referenceFilter, mode: insensitive }
        supplierInvoiceNumber: { contains: $supplierInvoiceNumberFilter, mode: insensitive }
        comments: { contains: $commentsFilter, mode: insensitive }
      }
      orderBy: $orderBy
      skip: $offset
      take: $limit
    ) {
      id
      reference
      supplierInvoiceNumber
      net
      vat
      gross
      comments
      completed
    }

    invoiceCount(input: { supplierSlug: $slug, filter: $filter })
  }
`;

const defaultSortBy = [{ id: "createdAt", desc: true }];
const defaultPagination = {
  pageIndex: 0,
  pageSize: 25,
};
const defaultTableFilters: Filters = [];
const defaultFilters = {
  referenceFilter: undefined,
  supplierInvoiceNumberFilter: undefined,
  commentsFilter: undefined,
};

export const InvoiceListPage = () => {
  const toast = useToast();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [pageCount, setPageCount] = React.useState(-1);
  const [data, setData] = React.useState<any[]>([]);

  const [getInvoices, { error }] = useGetInvoicesLazyQuery({
    onCompleted: (res) => {
      setData(res.invoices ?? []);
      setPageCount(res?.invoiceCount ?? 0);
    },
    onError: () => {
      toast({ title: "Encountered error whilst fetching", status: "error" });
    },
  });

  const onFetchData = React.useCallback(
    (opts: FetchDataCallbackOptions) => {
      const sortByField = opts.sortBy[0] ?? defaultSortBy[0];
      const orderBy = { [sortByField.id]: sortByField.desc ? "desc" : "asc" };

      const optionFilters = { ...defaultFilters };
      for (const filter of opts.filters) {
        (optionFilters as any)[`${filter.id}Filter`] = filter.value;
      }

      getInvoices({
        variables: {
          slug: supplierSlug,
          offset: opts.pageIndex * opts.pageSize,
          limit: opts.pageSize,
          orderBy: [orderBy],
          filter: opts.filters,
          ...optionFilters,
        },
      });
    },
    [supplierSlug, getInvoices]
  );

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the invoices</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Invoice List</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Invoices</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewInvoicePath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new invoice
            </Button>
          </ButtonGroup>
        </Stack>

        <Table
          data-testid="invoice-list"
          data={data}
          columns={invoiceTableColumns}
          sortBy={defaultSortBy}
          fetchData={onFetchData}
          pageCount={pageCount}
          defaultPageIndex={defaultPagination.pageIndex}
          defaultPageSize={defaultPagination.pageSize}
          filters={defaultTableFilters}
        />
      </Stack>
    </Fragment>
  );
};

const ReferenceAccessor = (invoice: Invoice) => {
  return {
    invoiceId: invoice.id,
    reference: invoice.reference,
  };
};

const StatusAccessor = (invoice: Invoice) => {
  return {
    completed: Boolean(invoice.completed),
  };
};

const invoiceTableColumns = [
  {
    id: "reference",
    Header: "File Reference",
    accessor: ReferenceAccessor,
    Cell: (props: { value: ReturnType<typeof ReferenceAccessor> }) => {
      const { invoiceId, reference } = props.value;

      if (!invoiceId) {
        return <Text>{reference}</Text>;
      }

      return <StyledLink to={invoiceId}>{reference}</StyledLink>;
    },
  },
  {
    id: "supplierInvoiceNumber",
    Header: "Supplier Invoice Number",
    accessor: "supplierInvoiceNumber",
  },
  { id: "net", Header: "Net", accessor: "net", Cell: FinancialFigureCell, disableFilters: true },
  { id: "vat", Header: "VAT", accessor: "vat", Cell: FinancialFigureCell, disableFilters: true },
  {
    id: "gross",
    Header: "Gross",
    accessor: "gross",
    Cell: FinancialFigureCell,
    disableFilters: true,
  },
  {
    id: "completed",
    Header: "Status",
    accessor: StatusAccessor,
    Cell: (props: { value: ReturnType<typeof StatusAccessor> }) => {
      return <InvoiceCompletedStatus completed={props.value.completed} />;
    },
    disableFilters: true,
  },
  {
    Header: "Comments",
    accessor: "comments",
    Cell: (props: { value: string }) => {
      const maxLength = 25;

      if (props.value.length <= maxLength) {
        return props.value;
      }

      return <Text title={props.value}>{props.value.substr(0, 22)}...</Text>;
    },
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Stack direction="row">
          <Link to={props.value}>
            <IconButton aria-label="View invoice" icon={<EyeIcon />} />
          </Link>

          <Link to={`${props.value}/edit`}>
            <IconButton colorScheme="green" aria-label="Edit invoice" icon={<EditIcon />} />
          </Link>
        </Stack>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
];
