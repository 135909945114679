import { useMsal } from "@azure/msal-react";
import { Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import { loginRequest } from "../../auth/authConfig";
import { Panel } from "../../components/Panel";

export const LoginPage = () => {
  const { instance } = useMsal();

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Log in</title>
      </Helmet>
      <Flex justifyContent="center" alignItems="center" width="100%" height="100vh" bg="gray.50">
        <Panel px={5} py={5} minWidth="400px">
          <Stack spacing={4}>
            <Heading textAlign="center">WMS</Heading>

            <Flex
              justifyContent="center"
              onClick={() => {
                instance.loginRedirect(loginRequest);
              }}
            >
              <Button type="submit" colorScheme="blue">
                Sign in
              </Button>
            </Flex>
          </Stack>
        </Panel>
      </Flex>
    </Fragment>
  );
};
