import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";

import { AuthenticatedApp } from "./AuthenticatedApp";
import { LoginPage } from "./pages/Auth/LoginPage";

function App() {
  if (process.env.NODE_ENV === "development") {
    return <AuthenticatedApp />;
  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <AuthenticatedApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
