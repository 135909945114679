import { Box, Stack, Text } from "@chakra-ui/react";

interface DataFieldProps {
  title: string;
}

export const DataField: React.FC<DataFieldProps> = (props) => {
  return (
    <Stack spacing={1}>
      <Text color="gray.600" fontSize="sm">
        {props.title}
      </Text>
      <Box>{props.children}</Box>
    </Stack>
  );
};
