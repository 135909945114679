import { gql } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { ContainerForm } from "../../../components/ContainerForm";
import { PageTitle } from "../../../components/Page";
import { Panel } from "../../../components/Panel";
import { buildAdminContainerListPath } from "../../../config/routes";
import { useContainerCreationPageCreateContainerMutation } from "../../../generated/graphql";

export const CONTAINER_CREATION_PAGE_CREATE_CONTAINER = gql`
  mutation ContainerCreationPageCreateContainer($input: CreateContainerInput!) {
    createContainer(input: $input) {
      id
      name
      type
    }
  }
`;

export const ContainerCreationPage = () => {
  const navigate = useNavigate();

  const [createContainer] = useContainerCreationPageCreateContainerMutation({
    refetchQueries: ["GetContainers"],
  });

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Container</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Container</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <ContainerForm
              onSubmit={async (values) => {
                await createContainer({
                  variables: {
                    input: values,
                  },
                });

                navigate(buildAdminContainerListPath());
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
