import { gql } from "@apollo/client";
import { Button, Flex, useToast } from "@chakra-ui/react";
import { FieldArray, Form, Formik } from "formik";
import { Fragment } from "react";
import * as yup from "yup";

import { useCollectionNoteLinesUpdateLinesMutation } from "../generated/graphql";
import { CollectionNotesTableField } from "./CollectionNotesTableField";
import { Panel } from "./Panel";

export const UPDATE_COLLECTION_NOTE_LINES = gql`
  mutation CollectionNoteLinesUpdateLines($input: UpdateCollectionNoteLinesInput!) {
    updateCollectionNoteLines(input: $input) {
      id
      collectionNoteLines {
        id
        quantity
        description
        invoiced
        value
        vat
        total

        ewcCode {
          id
          code
          product
        }

        nominalCode {
          id
          code
        }
      }
    }
  }
`;

const CollectionNoteLinesSchema = yup.object().shape({
  collectionNoteLines: yup.array(
    yup.object().shape({
      id: yup.string().nullable(),
      quantity: yup.string().nullable(),
      description: yup.string().nullable(),
      ewcCodeId: yup.string().nullable(),
      nominalCodeId: yup.string().nullable(),
      value: yup.number().nullable(),
      vat: yup.number().nullable(),
      total: yup.number().nullable(),
    })
  ),
});

interface EWCCode {
  id: string;
  code: string;
  product: string;
}

interface NominalCode {
  id: string;
  code: string;
}

interface CollectionNoteLine {
  id: string | null;
  quantity: string;
  description: string;
  ewcCodeId: string;
  nominalCodeId: string;
  value: string;
  vat: string;
  total: string;
  invoiced: boolean;
  invoiceId?: string;
}

interface CollectionNoteLinesProps {
  isEditable: boolean;
  collectionNoteId: string;
  collectionNoteLines: CollectionNoteLine[];
  ewcCodes: EWCCode[];
  nominalCodes: NominalCode[];
}

export const CollectionNoteLines: React.FC<CollectionNoteLinesProps> = ({
  isEditable,
  collectionNoteId,
  collectionNoteLines,
  ewcCodes,
  nominalCodes,
}) => {
  const toast = useToast();
  const [updateCollectionNoteLines] = useCollectionNoteLinesUpdateLinesMutation({
    onCompleted: () => {
      toast({ title: "Note lines saved", status: "success" });
    },
  });

  return (
    <Panel overflowY="auto">
      <Formik
        enableReinitialize
        initialValues={{
          collectionNoteLines:
            collectionNoteLines.length > 0 ? collectionNoteLines : [makeNewCollectionNoteLine()],
        }}
        validationSchema={CollectionNoteLinesSchema}
        onSubmit={async (values) => {
          if (!isEditable) {
            return;
          }

          const formattedLines = values.collectionNoteLines.map((line) => {
            return {
              id: line.id,
              quantity: line.quantity,
              description: line.description,
              ewcCodeId: line.ewcCodeId,
              nominalCodeId: line.nominalCodeId,
              value: line.value,
              vat: line.vat,
              total: line.total,
            };
          });

          await updateCollectionNoteLines({
            variables: {
              input: {
                collectionNoteId,
                collectionNoteLines: formattedLines,
              },
            },
          });
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <FieldArray name="collectionNoteLines">
                {(arrayHelpers) => {
                  return (
                    <Fragment>
                      <CollectionNotesTableField
                        name="collectionNoteLines"
                        data={values.collectionNoteLines}
                        ewcCodes={ewcCodes}
                        nominalCodes={nominalCodes}
                        onRemoveRow={arrayHelpers.remove}
                      />

                      {isEditable ? (
                        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
                          <Button
                            tye="button"
                            colorScheme="teal"
                            onClick={() => {
                              arrayHelpers.push(makeNewCollectionNoteLine());
                            }}
                          >
                            Add a new row
                          </Button>

                          <Button type="submit" colorScheme="blue" isDisabled={isSubmitting}>
                            Save changes
                          </Button>
                        </Flex>
                      ) : null}
                    </Fragment>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
};

const makeNewCollectionNoteLine = () => {
  return {
    id: null,
    quantity: "",
    description: "",
    ewcCodeId: "",
    nominalCodeId: "",
    value: "",
    vat: "",
    total: "",
    invoiced: false,
  };
};
