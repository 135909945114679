import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, EyeIcon, PlusIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Pagination } from "../../components/Pagination";
import { Panel } from "../../components/Panel";
import { QuoteStatus } from "../../components/QuoteStatus";
import { StyledLink } from "../../components/StyledLink";
import { DateCell, Table } from "../../components/Table";
import { buildNewQuotePath, RouteParams } from "../../config/routes";
import { Quote, useGetQuotesQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const QUOTES_LIST_GET_QUOTES = gql`
  query GetQuotes($slug: String!, $offset: Int, $limit: Int) {
    quotes(
      where: { supplier: { slug: { equals: $slug } } }
      orderBy: { creationDate: desc }
      skip: $offset
      take: $limit
    ) {
      id
      quoteReference
      contact
      quotedBy
      creationDate
      closed
    }

    quoteCount(slug: $slug)
  }
`;

export const QuoteListPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useGetQuotesQuery({
    variables: {
      slug: supplierSlug,
      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the quote</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Quote List</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Quotes</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewQuotePath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new quote
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table data-testid="quotes-list" data={data?.quotes ?? []} columns={quotesTableColumns} />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.quoteCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const QuoteRefAccessor = (quote: Quote) => {
  return {
    quoteId: quote.id,
    quoteReference: quote.quoteReference,
  };
};

const StatusAccessor = (quote: Quote) => {
  return {
    closed: Boolean(quote.closed),
  };
};

const quotesTableColumns = [
  {
    Header: "Quote Ref",
    accessor: QuoteRefAccessor,
    Cell: (props: { value: ReturnType<typeof QuoteRefAccessor> }) => {
      const { quoteId, quoteReference } = props.value;

      if (!quoteId) {
        return <Text>{quoteReference}</Text>;
      }

      return <StyledLink to={quoteId}>{quoteReference}</StyledLink>;
    },
  },
  { Header: "Dated", accessor: "creationDate", Cell: DateCell },
  { Header: "Contact", accessor: "contact" },
  { Header: "Quoted By", accessor: "quotedBy" },
  {
    Header: "Status",
    accessor: StatusAccessor,
    Cell: (props: { value: ReturnType<typeof StatusAccessor> }) => {
      const { closed } = props.value;

      return <QuoteStatus closed={closed} />;
    },
  },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Stack direction="row">
          <Link to={props.value}>
            <IconButton aria-label="View quote" icon={<EyeIcon />} />
          </Link>

          <Link to={`${props.value}/edit`}>
            <IconButton colorScheme="green" aria-label="Edit quote" icon={<EditIcon />} />
          </Link>
        </Stack>
      );
    },
  },
];
