import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { ContainerTypeEnum } from "../generated/graphql";
import { SelectField } from "./form/Select";
import { TextField } from "./form/TextField";

const ContainerFormSchema = yup.object().shape({
  name: yup.string().required("name is required").min(2).max(150),
  type: yup.string().required().oneOf(Object.values(ContainerTypeEnum)),
});

interface FormValues {
  name: string;
  type: ContainerTypeEnum;
}

interface ContainerFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const ContainerForm: React.FC<ContainerFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        name: "",
        type: ContainerTypeEnum.Bins,
      };
    }

    return props.initialValues;
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContainerFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="name" label="Name" />
              </Box>
              <Box>
                <SelectField name="type" label="Type">
                  <option key={ContainerTypeEnum.Bins} value={ContainerTypeEnum.Bins}>
                    {ContainerTypeEnum.Bins}
                  </option>
                  <option key={ContainerTypeEnum.Hl_5Skips} value={ContainerTypeEnum.Hl_5Skips}>
                    {ContainerTypeEnum.Hl_5Skips}
                  </option>
                  <option key={ContainerTypeEnum.Hl20Skips} value={ContainerTypeEnum.Hl20Skips}>
                    {ContainerTypeEnum.Hl20Skips}
                  </option>
                </SelectField>
              </Box>
            </Grid>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
