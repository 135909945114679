import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text, useToast } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, EyeIcon, PlusIcon } from "../../../components/icons";
import { PageTitle } from "../../../components/Page";
import { StyledLink } from "../../../components/StyledLink";
import { FetchDataCallbackOptions, Filters, Table } from "../../../components/TableV2/Table";
import { buildNewSupplierPath, buildSupplierPagePath } from "../../../config/routes";
import {
  SupplierListGetSuppliersQuery,
  useSupplierListGetSuppliersLazyQuery,
} from "../../../generated/graphql";

export const SUPPLIER_LIST_GET_SUPPLIERS = gql`
  query SupplierListGetSuppliers($input: ListSupplierInput!, $countInput: ListSupplierCountInput!) {
    suppliers(input: $input) {
      id
      name
      slug
      vat
      sageReference
      currency
    }

    supplierCount(input: $countInput)
  }
`;

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};
const defaultTableFilters: Filters = [];

export const SupplierListPage = () => {
  const toast = useToast();
  const [pageCount, setPageCount] = React.useState(-1);

  const [data, setData] = React.useState<any[]>([]);

  const [fetchSuppliers, { error }] = useSupplierListGetSuppliersLazyQuery({
    onCompleted: (res) => {
      setData(res.suppliers ?? []);
      setPageCount(res?.supplierCount ?? 0);
    },
    onError: () => {
      toast({ title: "Encountered error whilst fetching", status: "error" });
    },
  });

  const onFetchData = React.useCallback(
    (opts: FetchDataCallbackOptions) => {
      fetchSuppliers({
        variables: {
          input: {
            offset: opts.pageIndex * opts.pageSize,
            limit: opts.pageSize,
            filters: opts.filters,
          },
          countInput: {
            filters: opts.filters,
          },
        },
      });
    },
    [fetchSuppliers]
  );

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the suppliers</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Supplier List</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Suppliers</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewSupplierPath()}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new supplier
            </Button>
          </ButtonGroup>
        </Stack>

        <Table
          data-testid="suppliers-list"
          data={data}
          columns={supplierTableColumns}
          fetchData={onFetchData}
          pageCount={pageCount}
          defaultPageIndex={defaultPagination.pageIndex}
          defaultPageSize={defaultPagination.pageSize}
          filters={defaultTableFilters}
        />
      </Stack>
    </Fragment>
  );
};

type SupplierListPageSupplier = SupplierListGetSuppliersQuery["suppliers"][0];

const NameAccessor = (supplier: SupplierListPageSupplier) => {
  return {
    supplierSlug: supplier.slug,
    supplierName: supplier.name,
  };
};

const supplierTableColumns = [
  {
    id: "name",
    Header: "Name",
    accessor: NameAccessor,
    Cell: (props: { value: ReturnType<typeof NameAccessor> }) => {
      const { supplierSlug, supplierName } = props.value;

      return <StyledLink to={buildSupplierPagePath(supplierSlug)}>{supplierName}</StyledLink>;
    },
    disableSortBy: true,
  },
  {
    Header: "VAT",
    accessor: (supplier: SupplierListPageSupplier) => {
      return `${supplier.vat}%`;
    },
    disableFilters: true,
    disableSortBy: true,
  },
  { Header: "Sage Reference", accessor: "sageReference", disableSortBy: true },
  { Header: "Currency", accessor: "currency", disableSortBy: true },
  {
    id: "actions",
    Header: "",
    accessor: "slug",
    Cell: (props: { value: string }) => {
      return (
        <Stack direction="row">
          <Link to={props.value}>
            <IconButton aria-label="View supplier" icon={<EyeIcon />} />
          </Link>

          <Link to={`${props.value}/edit`}>
            <IconButton colorScheme="green" aria-label="Edit supplier" icon={<EditIcon />} />
          </Link>
        </Stack>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
];
