import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { SiteVisitForm } from "../../components/SiteVisitForm";
import { buildSiteVisitListPath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useSiteVisitEditPageGetSiteVisitQuery,
  useSiteVisitEditPageUpdateSiteVisitMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const SITE_VISIT_EDIT_PAGE_GET_SITE_VISIT = gql`
  query SiteVisitEditPageGetSiteVisit($siteVisitId: String!) {
    siteVisit(where: { id: $siteVisitId }) {
      id
      visitor
      visitDate
      comments
    }
  }
`;

export const UPDATE_SITE_VISIT = gql`
  mutation SiteVisitEditPageUpdateSiteVisit($input: UpdateSiteVisitInput!) {
    updateSiteVisit(input: $input) {
      id
    }
  }
`;

export const SiteVisitEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);
  const siteVisitId = useSingleParam(RouteParams.SiteVisitId, { required: true });

  const [updateSiteVisit] = useSiteVisitEditPageUpdateSiteVisitMutation();

  const { loading, error, data } = useSiteVisitEditPageGetSiteVisitQuery({
    variables: { siteVisitId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the site visit</Text>
      </Stack>
    );
  }

  if (!data || !data.siteVisit) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { siteVisit } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Visit {siteVisit.id}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Visit{" "}
            <Text as="span" color="blue.500">
              {siteVisit.id}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SiteVisitForm
              initialValues={{
                visitDate: siteVisit.visitDate,
                visitor: siteVisit.visitor,
                comments: siteVisit.comments,
              }}
              onSubmit={async (values) => {
                const { data } = await updateSiteVisit({
                  variables: {
                    input: {
                      ...values,
                      siteVisitId,
                    },
                  },
                });

                if (data?.updateSiteVisit?.id) {
                  navigate(buildSiteVisitListPath(supplierSlug));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
