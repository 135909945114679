import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { TextField } from "./form/TextField";

const SupplierContactFormSchema = yup.object().shape({
  name: yup.string().required("name is required"),
  email: yup.string(),
  notes: yup.string(),
  phoneNumber: yup.string(),
});

interface FormValues {
  name: string;
  email: string;
  notes: string;
  phoneNumber: string;
}

interface SupplierContactFormProps {
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const SupplierContactForm: React.FC<SupplierContactFormProps> = (props) => {
  const initialValues = useMemo(() => {
    return {
      name: "",
      email: "",
      notes: "",
      phoneNumber: "",
      ...props.initialValues,
    };
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SupplierContactFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="name" label="Name" />
              </Box>

              <Box>
                <TextField name="email" label="Email" />
              </Box>

              <Box>
                <TextField name="notes" label="Notes" />
              </Box>

              <Box>
                <TextField name="phoneNumber" label="Phone number" />
              </Box>
            </Grid>
            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
