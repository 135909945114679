import { Box, Button, Flex, IconButton } from "@chakra-ui/react";
import React from "react";

import { ChevronLeft, ChevronRight } from "./icons";

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  onGoToPage: (page: number) => void;
}

export const Pagination = (props: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage: props.currentPage,
    totalCount: props.totalCount,
    siblingCount: 1,
    pageSize: props.pageSize,
  });

  if (!paginationRange) {
    return null;
  }

  const onNext = () => {
    props.onGoToPage(props.currentPage + 1);
  };

  const onPrevious = () => {
    props.onGoToPage(props.currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Flex justifyContent="center" alignItems="center" gridColumnGap="5">
      <IconButton
        icon={<ChevronLeft />}
        aria-label="Go to the previous page"
        aria-disabled={props.currentPage === 1}
        disabled={props.currentPage === 1}
        onClick={onPrevious}
      />

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === null) {
          return <Box key={index}>&#8230;</Box>;
        }

        return (
          <Button
            key={index}
            colorScheme={props.currentPage === pageNumber ? "blue" : "gray"}
            onClick={() => props.onGoToPage(pageNumber)}
          >
            {pageNumber}
          </Button>
        );
      })}

      <IconButton
        icon={<ChevronRight />}
        aria-label="Go to the next page"
        aria-disabled={props.currentPage === lastPage}
        disabled={props.currentPage === lastPage}
        onClick={onNext}
      />
    </Flex>
  );
};

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface usePaginationOptions {
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: usePaginationOptions) => {
  const paginationRange = React.useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2 * placeholder separators
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, null, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, null, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, null, ...middleRange, null, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
