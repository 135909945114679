import { gql } from "@apollo/client";
import { Button, ButtonGroup, Grid, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate } from "react-router-dom";

import { DataField } from "../../components/DataField";
import { EditIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { buildSupplierRootPath, RouteParams } from "../../config/routes";
import { useGetSupplierAddressQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const ADDRESS_PAGE_GET_SUPPLIER_ADDRESS = gql`
  query GetSupplierAddress($addressId: String!) {
    supplierAddress(where: { id: $addressId }) {
      id
      type
      siteName
      building
      street
      district
      town
      county
      postalCode
      country
      notes
    }
  }
`;

export const AddressPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const addressId = useSingleParam(RouteParams.AddressId, { required: true });

  const { loading, error, data } = useGetSupplierAddressQuery({ variables: { addressId } });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the address</Text>
      </Stack>
    );
  }

  if (!data || !data.supplierAddress) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { supplierAddress } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Address</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1000px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Address</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to="edit"
              colorScheme="teal"
              leftIcon={<EditIcon height="16px" width="16px" mr={1} />}
            >
              Edit
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel data-testid="address-details">
          <Stack p={4} spacing={6}>
            <Grid gridTemplateColumns="50% 50%" gridRowGap={6}>
              <DataField title="Type">
                <Text>{supplierAddress.type}</Text>
              </DataField>

              <DataField title="Site Name">
                <Text>{supplierAddress.siteName}</Text>
              </DataField>

              <DataField title="Building">
                <Text>{supplierAddress.building}</Text>
              </DataField>

              <DataField title="Street">
                <Text>{supplierAddress.street}</Text>
              </DataField>

              <DataField title="District">
                <Text>{supplierAddress.district}</Text>
              </DataField>

              <DataField title="Town">
                <Text>{supplierAddress.town}</Text>
              </DataField>

              <DataField title="County">
                <Text>{supplierAddress.county}</Text>
              </DataField>

              <DataField title="Post Code">
                <Text>{supplierAddress.postalCode}</Text>
              </DataField>

              <DataField title="Country">
                <Text>{supplierAddress.country}</Text>
              </DataField>
            </Grid>

            <DataField title="Notes">
              {supplierAddress.notes ? (
                <Text>{supplierAddress.notes}</Text>
              ) : (
                <Text fontStyle="italic" fontSize="sm">
                  Not provided
                </Text>
              )}
            </DataField>
          </Stack>
        </Panel>
      </Stack>
    </Fragment>
  );
};
