import { FormControl, FormErrorMessage, FormLabel, Select, SelectProps } from "@chakra-ui/react";
import { FieldHookConfig, useField } from "formik";

type SelectFieldProps = FieldHookConfig<any> & {
  label?: string;
  selectProps?: SelectProps;
};

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  selectProps,
  children,
  ...props
}) => {
  const [field, meta] = useField(props);

  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <FormControl isInvalid={isInvalid}>
      {label ? <FormLabel htmlFor={field.name}>{label}</FormLabel> : null}
      <Select {...field} id={field.name} {...selectProps}>
        {children}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
