import { useNavigate } from "react-router-dom";

import { buildSupplierRootPath, RouteParams } from "../config/routes";
import { useSingleParam } from "../hooks/useSingleParam";
import { SupplierPicker } from "./SupplierPicker";

export const LocationAwareSupplierPicker = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);

  return (
    <SupplierPicker
      // Using `key` to reset the picker when the slug changes to undefined
      key={typeof supplierSlug === "undefined" ? -1 : 1}
      onSupplierSelected={(value: string) => {
        navigate(buildSupplierRootPath(value));
      }}
    />
  );
};
