import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { Currency } from "../generated/graphql";
import { currencyOptions } from "../models/currency";
import { SelectField } from "./form/Select";
import { TextField } from "./form/TextField";

const SupplierFormSchema = yup.object().shape({
  name: yup.string().required(),
  vat: yup.number().required(),
  sageReference: yup.string(),
  currency: yup.string().oneOf(currencyOptions),
});

interface FormValues {
  name: string;
  vat: number;
  sageReference: string;
  currency: Currency;
}

interface SupplierFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const SupplierForm: React.FC<SupplierFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        name: "",
        vat: 20,
        sageReference: "",
        currency: Currency.Gbp,
      };
    }

    return props.initialValues;
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SupplierFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="name" label="Name" />
              </Box>
              <Box>
                <TextField name="vat" label="VAT" type="number" />
              </Box>

              <Box>
                <TextField name="sageReference" label="Sage Reference" />
              </Box>

              <Box>
                <SelectField name="currency" label="Currency">
                  {currencyOptions.map((opt) => {
                    return (
                      <option key={opt} value={opt}>
                        {opt.toUpperCase()}
                      </option>
                    );
                  })}
                </SelectField>
              </Box>
            </Grid>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
