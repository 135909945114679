import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { NominalCodeForm } from "../../../components/NominalCodeForm";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Panel } from "../../../components/Panel";
import { buildAdminNominalCodeListPath, RouteParams } from "../../../config/routes";
import {
  useNominalCodeEditPageGetNominalCodeQuery,
  useNominalCodeEditPageUpdateNominalCodeMutation,
} from "../../../generated/graphql";
import { useSingleParam } from "../../../hooks/useSingleParam";

export const NOMINAL_CODE_EDIT_PAGE_GET_NOMINAL_CODE = gql`
  query NominalCodeEditPageGetNominalCode($nominalCodeId: String!) {
    nominalCode(where: { id: $nominalCodeId }) {
      id
      code
    }
  }
`;

export const NOMINAL_CODE_EDIT_PAGE_EDIT_NOMINAL_CODE = gql`
  mutation NominalCodeEditPageUpdateNominalCode($input: UpdateNominalCodeInput!) {
    updateNominalCode(input: $input) {
      id
    }
  }
`;

export const NominalCodeEditPage = () => {
  const navigate = useNavigate();
  const nominalCodeId = useSingleParam(RouteParams.NominalCodeId);

  const [updateNominalCode] = useNominalCodeEditPageUpdateNominalCodeMutation({
    refetchQueries: ["GetNominalCodes"],
  });

  const { loading, error, data } = useNominalCodeEditPageGetNominalCodeQuery({
    variables: { nominalCodeId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the Nominal Code</Text>
      </Stack>
    );
  }

  if (!data || !data.nominalCode) {
    return <Navigate to={buildAdminNominalCodeListPath()} />;
  }

  const { nominalCode } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Nominal Code {nominalCode.id} </title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Nominal Code{" "}
            <Text as="span" color="blue.500">
              {nominalCode.code}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <NominalCodeForm
              initialValues={{
                code: nominalCode.code,
              }}
              onSubmit={async (values) => {
                await updateNominalCode({
                  variables: {
                    input: {
                      ...values,
                      nominalCodeId,
                    },
                  },
                });

                navigate(buildAdminNominalCodeListPath());
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
