import { gql } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { Panel } from "../../components/Panel";
import { SupplierContactForm } from "../../components/SupplierContactForm";
import { buildEditContactPagePath, RouteParams } from "../../config/routes";
import { useCreateSupplierContactMutation } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const CONTACT_CREATION_CREATE_SUPPLIER_CONTACT = gql`
  mutation CreateSupplierContact($input: CreateSupplierContactInput!) {
    createSupplierContact(input: $input) {
      id
    }
  }
`;

export const ContactCreationPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [createSupplierContact] = useCreateSupplierContactMutation();

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New Contact</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New Contact</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierContactForm
              onSubmit={async (values) => {
                const { data } = await createSupplierContact({
                  variables: {
                    input: {
                      ...values,
                      supplierSlug,
                    },
                  },
                });

                if (data?.createSupplierContact?.id) {
                  navigate(buildEditContactPagePath(supplierSlug, data.createSupplierContact.id));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
