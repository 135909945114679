import { gql } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { FinancialFigure } from "../../components/FinancialFigure";
import { ChevronDown, EyeIcon, InfoIcon, PlusIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { Panel } from "../../components/Panel";
import { Status } from "../../components/Status";
import { StyledLink } from "../../components/StyledLink";
import { BooleanCell, DateCell, FinancialFigureCell } from "../../components/Table";
import {
  FetchDataCallbackOptions,
  StyledTable,
  StyledTableBodyCell,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableRow,
  Table,
} from "../../components/TableV2/Table";
import { buildNewCollectionNotePath, RouteParams } from "../../config/routes";
import { useSupplier } from "../../context/SupplierContext";
import {
  CollectionNote,
  CollectionNotesFilterOption,
  useGetCollectionNotesLazyQuery,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const COLLECTION_NOTES_LIST_GET_COLLECTION_NOTES = gql`
  query GetCollectionNotes(
    $input: ListCollectionNotesInput!
    $countInput: ListCollectionNoteCountInput!
  ) {
    collectionNotes(input: $input) {
      id
      displayReference
      linesCount
      creationDate
      printed
      net
      vat
      gross
      invoiced
      closed

      collectionNoteLines(orderBy: { createdAt: asc }) {
        id
        quantity
        description
        invoiced
        value
        vat
        total
      }
    }

    collectionNoteCount(input: $countInput)
  }
`;

const defaultSortBy = [{ id: "creationDate", desc: true }];
const defaultPagination = {
  pageIndex: 0,
  pageSize: 25,
};
const defaultFilters = {
  id: undefined,
  status: CollectionNotesFilterOption.All,
};

export const CollectionNoteListPage = () => {
  const toast = useToast();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [pageCount, setPageCount] = React.useState(-1);
  const [filters] = React.useState([]);
  const [globalInvoiceStatusFilter, setGlobalFilter] = React.useState<CollectionNotesFilterOption>(
    defaultFilters.status
  );
  const [data, setData] = React.useState<any[]>([]);

  const [activeCollectionNoteId, setActiveCollectionNoteId] = React.useState<string | undefined>(
    undefined
  );

  const instanceProps = React.useMemo(() => {
    return {
      activeCollectionNoteId: activeCollectionNoteId,
      setActiveCollectionNoteId: (id: string) => {
        if (id === activeCollectionNoteId) {
          setActiveCollectionNoteId(undefined);
          return;
        }

        setActiveCollectionNoteId(id);
      },
    };
  }, [activeCollectionNoteId, setActiveCollectionNoteId]);

  const [getCollectionNotes, { error }] = useGetCollectionNotesLazyQuery({
    onCompleted: (res) => {
      // reset the active collection note
      setActiveCollectionNoteId(undefined);
      setData(res.collectionNotes ?? []);
      setPageCount(res?.collectionNoteCount ?? 0);
    },
    onError: () => {
      toast({ title: "Encountered error whilst fetching", status: "error" });
    },
  });

  const fetchData = React.useCallback(
    (opts: FetchDataCallbackOptions) => {
      const sortByField = opts.sortBy[0] ?? defaultSortBy[0];
      const orderBy = { [sortByField.id]: sortByField.desc ? "desc" : "asc" };

      const initialFilters = opts.filters;
      const updatedFilters = [];

      let hasStatusFilter = false;
      for (const filter of initialFilters) {
        if (filter.id === "status") {
          hasStatusFilter = true;
          updatedFilters.push({ id: "status", value: globalInvoiceStatusFilter });
          continue;
        }

        updatedFilters.push(filter);
      }

      if (!hasStatusFilter) {
        updatedFilters.push({ id: "status", value: globalInvoiceStatusFilter });
      }

      getCollectionNotes({
        variables: {
          input: {
            supplierSlug,
            offset: opts.pageIndex * opts.pageSize,
            limit: opts.pageSize,
            filter: updatedFilters,
            orderBy: orderBy,
          },
          countInput: {
            supplierSlug,
            filter: updatedFilters,
          },
        },
      });
    },
    [getCollectionNotes, globalInvoiceStatusFilter, supplierSlug]
  );

  const setFilterOption = (option: CollectionNotesFilterOption) => {
    setGlobalFilter(option);
  };

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the collection notes</Text>
      </Stack>
    );
  }

  const activeCollectionNote = data.find((datum) => datum.id === activeCollectionNoteId);

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Collection Note List</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <SimpleGrid columns={3} width="100%">
          <PageTitle>Collection Notes</PageTitle>
          <Flex justifyContent="center">
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDown />}>
                Display{" "}
                {globalInvoiceStatusFilter === CollectionNotesFilterOption.All
                  ? "All"
                  : globalInvoiceStatusFilter === CollectionNotesFilterOption.Open
                  ? "Open"
                  : globalInvoiceStatusFilter === CollectionNotesFilterOption.Invoiced
                  ? "Invoiced"
                  : globalInvoiceStatusFilter === CollectionNotesFilterOption.Closed
                  ? "Closed"
                  : ""}{" "}
                Notes
              </MenuButton>
              <MenuList zIndex={3}>
                <MenuItem
                  onClick={() => {
                    setFilterOption(CollectionNotesFilterOption.All);
                  }}
                >
                  Display All Notes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterOption(CollectionNotesFilterOption.Open);
                  }}
                >
                  Display Open Notes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterOption(CollectionNotesFilterOption.Invoiced);
                  }}
                >
                  Display Invoiced Notes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterOption(CollectionNotesFilterOption.Closed);
                  }}
                >
                  Display Closed Notes
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <ButtonGroup justifyContent="flex-end">
            <Button
              as={Link}
              to={buildNewCollectionNotePath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new note
            </Button>
          </ButtonGroup>
        </SimpleGrid>

        <Table
          data-testid="collection-notes-list"
          data={data}
          columns={collectionNotesTableColumns}
          instanceProps={instanceProps}
          sortBy={defaultSortBy}
          fetchData={fetchData}
          pageCount={pageCount}
          defaultPageIndex={defaultPagination.pageIndex}
          defaultPageSize={defaultPagination.pageSize}
          filters={filters}
          tableHeight={activeCollectionNote ? "30vh" : undefined}
          mode={activeCollectionNote ? "compact" : "default"}
        />

        {activeCollectionNote ? (
          <Panel height="25vh" overflowY="auto">
            <CollectionNoteLines lines={activeCollectionNote.collectionNoteLines} />
          </Panel>
        ) : undefined}
      </Stack>
    </Fragment>
  );
};

interface CellProps {
  activeCollectionNoteId: string;
  setActiveCollectionNoteId: (id: string) => void;
  row: { index: number };
  column: {
    id: string;
    placeholder: string;
  };
  value: string;
}

const IdFieldAccessor = (collectionNote: CollectionNote) => {
  return {
    id: collectionNote.id,
    displayReference: collectionNote.displayReference,
  };
};

const collectionNotesTableColumns = [
  {
    id: "displayReference",
    Header: "ID",
    accessor: IdFieldAccessor,
    Cell: (props: { value: ReturnType<typeof IdFieldAccessor> }) => {
      const { id, displayReference } = props.value;

      return <StyledLink to={id}>{displayReference}</StyledLink>;
    },
  },
  { Header: "Lines", accessor: "linesCount", disableSortBy: true, disableFilters: true },
  { Header: "Date", accessor: "creationDate", Cell: DateCell, disableFilters: true },
  { id: "value", Header: "Net", accessor: "net", Cell: FinancialFigureCell, disableFilters: true },
  { id: "vat", Header: "VAT", accessor: "vat", Cell: FinancialFigureCell, disableFilters: true },
  {
    id: "total",
    Header: "Gross",
    accessor: "gross",
    Cell: FinancialFigureCell,
    disableFilters: true,
  },
  { Header: "Invoiced", accessor: "invoiced", Cell: BooleanCell, disableFilters: true },
  { Header: "Closed", accessor: "closed", Cell: BooleanCell, disableFilters: true },
  {
    id: "printedAt",
    Header: "Printed",
    accessor: "printed",
    Cell: BooleanCell,
    disableFilters: true,
  },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: CellProps) => {
      const isActiveRow = props.activeCollectionNoteId === props.value;

      return (
        <Stack direction="row">
          <Link to={props.value}>
            <IconButton aria-label="View collection note" icon={<EyeIcon />} />
          </Link>

          <IconButton
            aria-label="View lines"
            colorScheme={isActiveRow ? "green" : undefined}
            icon={<InfoIcon />}
            onClick={() => {
              props.setActiveCollectionNoteId(props.value);
            }}
          />
        </Stack>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
];

interface CollectionNoteLinesProps {
  lines: {
    id: string;
    quantity: string;
    description: string;
    invoiced: boolean;
    value: string;
    vat: string;
    total: string;
  }[];
}

const CollectionNoteLines = (props: CollectionNoteLinesProps) => {
  const { supplier } = useSupplier();

  return (
    <StyledTable>
      <StyledTableHeader>
        <StyledTableRow>
          <StyledTableHeaderCell mode="compact">Quantity</StyledTableHeaderCell>
          <StyledTableHeaderCell mode="compact">Description</StyledTableHeaderCell>
          <StyledTableHeaderCell mode="compact">Value</StyledTableHeaderCell>
          <StyledTableHeaderCell mode="compact">VAT</StyledTableHeaderCell>
          <StyledTableHeaderCell mode="compact">Total</StyledTableHeaderCell>
          <StyledTableHeaderCell mode="compact">Invoiced</StyledTableHeaderCell>
        </StyledTableRow>
      </StyledTableHeader>
      <tbody>
        {props.lines.map((line) => {
          return (
            <StyledTableRow key={line.id}>
              <StyledTableBodyCell mode="compact">{line.quantity}</StyledTableBodyCell>
              <StyledTableBodyCell mode="compact">{line.description}</StyledTableBodyCell>
              <StyledTableBodyCell mode="compact">
                {line.value ? (
                  <FinancialFigure
                    value={(line.value as unknown) as number}
                    currency={supplier.currency}
                  />
                ) : null}
              </StyledTableBodyCell>
              <StyledTableBodyCell mode="compact">
                {line.vat ? (
                  <FinancialFigure
                    value={(line.vat as unknown) as number}
                    currency={supplier.currency}
                  />
                ) : null}
              </StyledTableBodyCell>
              <StyledTableBodyCell mode="compact">
                {line.total ? (
                  <FinancialFigure
                    value={(line.total as unknown) as number}
                    currency={supplier.currency}
                  />
                ) : null}
              </StyledTableBodyCell>
              <StyledTableBodyCell mode="compact">
                <Status active={Boolean(line.invoiced)} />
              </StyledTableBodyCell>
            </StyledTableRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
