import { Box, Button, Flex, Grid, Stack } from "@chakra-ui/react";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { FieldArray, Form, Formik } from "formik";
import { Fragment, useMemo } from "react";
import * as yup from "yup";

import { CollectionNotesTableField } from "./CollectionNotesTableField";
import { CheckboxField } from "./form/CheckboxField";
import { DatePickerField } from "./form/DatePickerField";
import { SelectField } from "./form/Select";
import { Panel } from "./Panel";

const CollectionNoteFormSchema = yup.object().shape({
  supplierAddressId: yup.string().required(),
  creationDate: yup.date().required(),
  isReturn: yup.bool().required(),
  collectionNoteLines: yup.array(
    yup.object().shape({
      id: yup.string().nullable(),
      quantity: yup.string().nullable(),
      description: yup.string().nullable(),
      value: yup.number().nullable(),
      vat: yup.number().nullable(),
      total: yup.number().nullable(),
    })
  ),
});

interface CollectionNoteLine {
  id: string | null;
  quantity: string;
  description: string;
  value: string;
  vat: string;
  total: string;
  invoiced: boolean;
}

interface FormValues {
  supplierAddressId: string;
  creationDate: string;
  isReturn: boolean;
  collectionNoteLines: CollectionNoteLine[];
}

interface Address {
  id: string;
  type: string;
  siteName?: string | null;
  building?: string | null;
  postalCode?: string | null;
}

interface EWCCode {
  id: string;
  code: string;
  product: string;
}

interface NominalCode {
  id: string;
  code: string;
}

interface CollectionNoteFormProps {
  initialValues?: FormValues;
  addresses: Address[];
  ewcCodes: EWCCode[];
  nominalCodes: NominalCode[];
  onSubmit: (values: FormValues) => Promise<void>;
}

export const CollectionNoteForm: React.FC<CollectionNoteFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        creationDate: format(new Date(), "yyyy-LL-dd"),
        supplierAddressId: props.addresses[0].id,
        isReturn: false,
        collectionNoteLines: [makeNewCollectionNoteLine()],
      };
    }

    return {
      ...props.initialValues,
      creationDate: format(parseISO(props.initialValues.creationDate), "yyyy-LL-dd"),
    };
  }, [props.initialValues, props.addresses]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CollectionNoteFormSchema}
      onSubmit={async (values) => {
        const formattedValues: FormValues = {
          ...values,
          creationDate: formatISO(parseISO(values.creationDate)),
        };

        await props.onSubmit(formattedValues);
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <Stack spacing={6}>
              <Panel maxWidth="1500px">
                <Box px={8} py={5}>
                  <Stack spacing={6}>
                    <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
                      <Box>
                        <DatePickerField name="creationDate" label="Creation Date" />
                      </Box>

                      <Box>
                        <SelectField name="supplierAddressId" label="Address">
                          {props.addresses.map((address) => {
                            return (
                              <option key={address.id} value={address.id}>
                                {buildAddressLabel(address)}
                              </option>
                            );
                          })}
                        </SelectField>
                      </Box>

                      <Box>
                        <CheckboxField name="isReturn" label="Return note" />
                      </Box>
                    </Grid>
                  </Stack>
                </Box>
              </Panel>

              <Panel maxWidth="1500px">
                <FieldArray name="collectionNoteLines">
                  {(arrayHelpers) => {
                    return (
                      <Fragment>
                        <CollectionNotesTableField
                          name="collectionNoteLines"
                          data={values.collectionNoteLines}
                          ewcCodes={props.ewcCodes}
                          nominalCodes={props.nominalCodes}
                          onRemoveRow={arrayHelpers.remove}
                        />

                        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
                          <Button
                            tye="button"
                            colorScheme="teal"
                            onClick={() => {
                              arrayHelpers.push(makeNewCollectionNoteLine());
                            }}
                          >
                            Add a new row
                          </Button>
                        </Flex>
                      </Fragment>
                    );
                  }}
                </FieldArray>
              </Panel>
              <Panel maxWidth="1500px" display="flex" justifyContent="flex-end">
                <Box px={8} py={5}>
                  <Box>
                    <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </Panel>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

const buildAddressLabel = (address: Address) => {
  const parts = [address.siteName, address.building, address.postalCode].filter(Boolean).join(", ");

  return `${address.type}: ${parts}`;
};

const makeNewCollectionNoteLine = () => {
  return {
    id: null,
    quantity: "",
    description: "",
    ewcCodeId: "",
    nominalCodeId: "",
    value: "",
    vat: "",
    total: "",
    invoiced: false,
  };
};
