import { Box, Checkbox, Input, Text } from "@chakra-ui/react";
import { FormikHandlers, useField } from "formik";
import React from "react";
import { FormattedDate } from "react-intl";
import { Column, useTable } from "react-table";

import { useSupplier } from "../context/SupplierContext";
import { FinancialFigure } from "./FinancialFigure";
import { Status } from "./Status";

type Mode = "default" | "compact";

interface RowAction<T> {
  row: T;
  index: number;
}

interface TableProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  rowActions?: (rowAction: RowAction<T>) => React.ReactNode;
  "data-testid"?: string;
  instanceProps?: Record<string, any>;
  mode?: Mode;
}

export const Table: React.FC<TableProps<any>> = (props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columns,
    data: props.data,
    ...props.instanceProps,
  });

  const mode = props.mode ?? "default";

  return (
    <StyledTable {...getTableProps()} data-testid={props["data-testid"]}>
      <StyledTableHeader>
        {headerGroups.map((headerGroup) => (
          <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableHeaderCell {...column.getHeaderProps()} mode={mode}>
                {column.render("Header")}
              </StyledTableHeaderCell>
            ))}
            {props.rowActions && (
              <StyledTableHeaderCell mode={mode}>
                {/* Row actions placeholder */}
                &nbsp;
              </StyledTableHeaderCell>
            )}
          </StyledTableRow>
        ))}
      </StyledTableHeader>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <StyledTableRow {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <StyledTableBodyCell
                    {...cell.getCellProps()}
                    width={cell.column.width}
                    mode={mode}
                  >
                    {cell.render("Cell")}
                  </StyledTableBodyCell>
                );
              })}
              {props.rowActions && (
                <StyledTableBodyCell mode={mode}>
                  {props.rowActions({ row: row.original, index })}
                </StyledTableBodyCell>
              )}
            </StyledTableRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledTable: React.FC = (props) => {
  return <Box width="100%" {...props} as="table" />;
};

const StyledTableHeader: React.FC = (props) => {
  return <Box bg="gray.50" {...props} as="thead" />;
};

const StyledTableRow: React.FC = (props) => {
  return <Box borderBottomWidth="1px" borderBottomColor="gray.200" {...props} as="tr" />;
};

interface StyledTableHeaderCellProps {
  mode: Mode;
}

const StyledTableHeaderCell: React.FC<StyledTableHeaderCellProps> = (props) => {
  return (
    <Box
      bg="gray.50"
      textTransform="capitalize"
      fontSize="sm"
      textAlign="left"
      color="#7C7F88"
      pl={props.mode === "compact" ? 2 : 4}
      py={5}
      position="sticky"
      top={0}
      zIndex={1}
      {...props}
      as="th"
    />
  );
};

interface StyledTableBodyCellProps {
  width?: string | number;
  mode?: Mode;
}

const StyledTableBodyCell: React.FC<StyledTableBodyCellProps> = (props) => {
  return (
    <Box
      fontSize="sm"
      textAlign="left"
      px={props.mode === "compact" ? 2 : 4}
      py={props.mode === "compact" ? 2 : 4}
      {...props}
      as="td"
    />
  );
};

interface DateCellProps {
  cell: {
    value: Date;
  };
}

export const DateCell = ({ cell }: DateCellProps) => {
  return (
    <Text>
      <FormattedDate value={cell.value} year="2-digit" month="2-digit" day="2-digit" />
    </Text>
  );
};

interface BooleanCellProps {
  cell: {
    value: boolean;
  };
}

export const BooleanCell = ({ cell }: BooleanCellProps) => {
  return <Status active={cell.value}>{cell.value ? "Yes" : "No"}</Status>;
};

interface EditableTextCellProps {
  tableFieldName: string;
  row: { index: number };
  column: { id: string; placeholder: string };
  onChange?: FormikHandlers["handleChange"];
}

export const EditableTextCell: React.FC<EditableTextCellProps> = (props) => {
  const fieldKey = `${props.tableFieldName}[${props.row.index}].${props.column.id}`;

  const [field, meta] = useField(fieldKey);

  return (
    <Box pt="15px">
      <Input
        type="text"
        placeholder={props.column.placeholder}
        {...field}
        onChange={props.onChange ?? field.onChange}
      />
      <Box height="15px" pt="3px">
        {Boolean(meta.touched && meta.error) ? <Text color="red.700">{meta.error}</Text> : null}
      </Box>
    </Box>
  );
};

interface CheckboxCellProps {
  tableFieldName: string;
  row: { index: number };
  column: { id: string; placeholder: string; readonly: boolean };
}

export const CheckboxCell: React.FC<CheckboxCellProps> = (props) => {
  const fieldKey = `${props.tableFieldName}[${props.row.index}].${props.column.id}`;

  const [field, meta, helper] = useField(fieldKey);

  return (
    <Box pt="15px">
      <Checkbox
        {...field}
        id={field.name}
        isChecked={field.value}
        isDisabled={props.column.readonly}
        onChange={(e) => helper.setValue(e.target.checked)}
      />
      <Box height="15px" pt="3px">
        {Boolean(meta.touched && meta.error) ? <Text color="red.700">{meta.error}</Text> : null}
      </Box>
    </Box>
  );
};

interface FinancialFigureCellProps {
  cell: {
    value: number;
  };
}

export const FinancialFigureCell = (props: FinancialFigureCellProps) => {
  const { supplier } = useSupplier();

  return <FinancialFigure value={props.cell.value} currency={supplier.currency} />;
};
