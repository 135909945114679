import { gql } from "@apollo/client";
import { Button, ButtonGroup, Grid, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate } from "react-router-dom";

import { DataField } from "../../../components/DataField";
import { EditIcon } from "../../../components/icons";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Panel } from "../../../components/Panel";
import { buildSupplierEditPath, buildSupplierListPath, RouteParams } from "../../../config/routes";
import { useSupplierPageGetSupplierQuery } from "../../../generated/graphql";
import { useSingleParam } from "../../../hooks/useSingleParam";

export const SUPPLIER_PAGE_GET_SUPPLIER = gql`
  query SupplierPageGetSupplier($supplierSlug: String!) {
    supplier(where: { slug: $supplierSlug }) {
      id
      name
      slug
      vat
      sageReference
      currency
    }
  }
`;

export const SupplierPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const { loading, error, data } = useSupplierPageGetSupplierQuery({ variables: { supplierSlug } });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the supplier</Text>
      </Stack>
    );
  }

  if (!data || !data.supplier) {
    return <Navigate to={buildSupplierListPath()} />;
  }

  const { supplier } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Supplier {supplier.name}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1000px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>
            Supplier{" "}
            <Text as="span" color="blue.500">
              {supplier.name}
            </Text>
          </PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildSupplierEditPath(supplierSlug)}
              colorScheme="teal"
              leftIcon={<EditIcon height="16px" width="16px" mr={1} />}
            >
              Edit
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel data-testid="supplier-details">
          <Stack p={4} spacing={6}>
            <Grid gridTemplateColumns="50% 50%" gridRowGap={6}>
              <DataField title="Name">
                <Text>{supplier.name}</Text>
              </DataField>

              <DataField title="VAT">
                <Text>{supplier.vat}%</Text>
              </DataField>

              <DataField title="Sage Reference">
                <Text>{supplier.sageReference}</Text>
              </DataField>

              <DataField title="Currency">
                <Text>{supplier.currency}</Text>
              </DataField>
            </Grid>
          </Stack>
        </Panel>
      </Stack>
    </Fragment>
  );
};
