import { gql } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useActor, useMachine } from "@xstate/react";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedDate } from "react-intl";
import * as yup from "yup";

import { useSupplierFilterFormGetSuppliersQuery } from "../generated/graphql";
import {
  CollectionNoteIDRangeFilter,
  generateReportConfigMachine,
  generateReportConfigModel,
  SelectedSupplier,
  SupplierFilter,
} from "../machines/generateReportConfig.machine";
import {
  BlankNotesReportActor,
  blankNotesReportModel,
} from "../machines/reports/blankNoteReport.machine";
import {
  DeliveryNotesReportActor,
  deliveryNotesReportModel,
} from "../machines/reports/deliveryNoteReport.machine";
import {
  FileReferenceFilter,
  InvoiceReportActor,
  invoiceReportModel,
} from "../machines/reports/invoiceReport.machine";
import { NotesReportActor, notesReportModel } from "../machines/reports/noteReport.machine";
import {
  OutstandingInvoiceReportActor,
  outstandingInvoiceReportModel,
} from "../machines/reports/outstandingInvoiceReport.machine";
import {
  SupplierReengagementReportActor,
  supplierReengagementReportModel,
} from "../machines/reports/supplierReengagementReport.machine";
import { DataField } from "./DataField";
import { CheckboxField } from "./form/CheckboxField";
import { DatePickerField } from "./form/DatePickerField";
import { SelectField } from "./form/Select";
import { TextField } from "./form/TextField";
import { ArrowRightIcon } from "./icons";
import { PageSpinner } from "./PageSpinner";
import { Status } from "./Status";

interface GenerateReportConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GenerateReportConfigurationModal = (props: GenerateReportConfigurationModalProps) => {
  const [state, send] = useMachine(generateReportConfigMachine, {
    devTools: true,
  });

  const reportOptions: SelectReportTypeProps["reports"] = [
    {
      name: "Notes",
      callback: () => send(generateReportConfigModel.events.startNotesReportFlow()),
    },
    {
      name: "Blank notes",
      callback: () => send(generateReportConfigModel.events.startBlankNotesReportFlow()),
    },
    {
      name: "Delivery",
      callback: () => send(generateReportConfigModel.events.startDeliveryNotesReportFlow()),
    },
    {
      name: "Supplier re-engagement",
      callback: () => send(generateReportConfigModel.events.startSupplierReengagementReportFlow()),
    },
    {
      name: "Invoices",
      callback: () => send(generateReportConfigModel.events.startInvoiceReportFlow()),
    },
    {
      name: "Outstanding invoices",
      callback: () => send(generateReportConfigModel.events.startOutstandingInvoiceReportFlow()),
    },
  ];

  React.useEffect(() => {
    if (!props.isOpen) {
      send(generateReportConfigModel.events.restart());
    }
  }, [props.isOpen, send]);

  return (
    <Modal isOpen={props.isOpen} size="lg" onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate report configuration</ModalHeader>
        <ModalCloseButton />
        {state.matches("selectingReportType") ? (
          <SelectReportType reports={reportOptions} />
        ) : state.matches("blankNotesReportFlow") ? (
          <BlankNoteReportConfiguration
            service={state.context.activeFlow}
            onClose={props.onClose}
          />
        ) : state.matches("notesReportFlow") ? (
          <NotesReportConfiguration service={state.context.activeFlow} onClose={props.onClose} />
        ) : state.matches("deliveryNotesReportFlow") ? (
          <DeliveryNoteReportConfiguration
            service={state.context.activeFlow}
            onClose={props.onClose}
          />
        ) : state.matches("supplierReengagementReportFlow") ? (
          <SupplierReengagementReportConfiguration
            service={state.context.activeFlow}
            onClose={props.onClose}
          />
        ) : state.matches("invoiceReportFlow") ? (
          <InvoiceReportConfiguration service={state.context.activeFlow} onClose={props.onClose} />
        ) : state.matches("outstandingInvoiceReportFlow") ? (
          <OutstandingInvoiceReportConfigurationSummary service={state.context.activeFlow} />
        ) : null}
      </ModalContent>
    </Modal>
  );
};

interface SelectReportTypeProps {
  reports: {
    name: string;
    callback: () => void;
  }[];
}

const SelectReportType = (props: SelectReportTypeProps) => {
  return (
    <React.Fragment>
      <ModalBody>
        <List spacing={3}>
          {props.reports.map((report) => (
            <ListItem key={report.name}>
              <Button
                colorScheme="teal"
                variant="outline"
                rightIcon={<ArrowRightIcon />}
                onClick={report.callback}
              >
                {report.name}
              </Button>
            </ListItem>
          ))}
        </List>
      </ModalBody>

      <ModalFooter />
    </React.Fragment>
  );
};

interface BlankNoteReportConfigurationProps {
  service: BlankNotesReportActor;
  onClose: () => void;
}

const BlankNoteReportConfiguration = (props: BlankNoteReportConfigurationProps) => {
  const [state, send] = useActor(props.service);

  switch (true) {
    case state.matches("configuringDateRange"):
      return (
        <DateRangeForm
          initialValues={{
            startDate: format(state.context.dateRange.startDate, "yyyy-LL-dd"),
            endDate: format(state.context.dateRange.endDate, "yyyy-LL-dd"),
          }}
          onSubmit={(values) => {
            send(blankNotesReportModel.events.provideReportDates(values));
          }}
        />
      );

    case state.matches("configuringSupplierFilter"):
      return (
        <SupplierFilterForm
          initialValues={{
            fromID: state.context.supplierFilter?.from.internalId,
            toID: state.context.supplierFilter?.to.internalId,
          }}
          onSubmit={(values) => {
            send(blankNotesReportModel.events.provideSupplierFilter(values));
          }}
          onSkip={() => {
            send(blankNotesReportModel.events.skipStep());
          }}
          onBack={() => {
            send(blankNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("configuringNoteIDRange"):
      return (
        <CollectionNoteIDFilterForm
          initialValues={{
            fromID: state.context.collectionNoteIDRangeFilter?.fromID.toString(),
            toID: state.context.collectionNoteIDRangeFilter?.toID.toString(),
          }}
          onSubmit={(values) => {
            send(blankNotesReportModel.events.provideCollectionNoteIDRangeFilter(values));
          }}
          onSkip={() => {
            send(blankNotesReportModel.events.skipStep());
          }}
          onBack={() => {
            send(blankNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("showingReportConfigSummary"):
      return (
        <BlankNoteReportConfigurationSummary
          isGeneratingReport={state.matches({ showingReportConfigSummary: "generatingReport" })}
          startDate={state.context.dateRange.startDate}
          endDate={state.context.dateRange.endDate}
          supplierFilter={state.context.supplierFilter}
          collectionNoteIDRangeFilter={state.context.collectionNoteIDRangeFilter}
          onGenerateReport={() => {
            send(blankNotesReportModel.events.generateReport());
          }}
          onBack={() => {
            send(blankNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("reportGenerated"):
      return <ReportGenerated onClose={props.onClose} />;

    default:
      return null;
  }
};

interface BlankNoteReportConfigurationSummaryProps {
  isGeneratingReport: boolean;
  startDate: Date;
  endDate: Date;
  supplierFilter?: SupplierFilter;
  collectionNoteIDRangeFilter?: CollectionNoteIDRangeFilter;
  onGenerateReport: () => void;
  onBack: () => void;
}

const BlankNoteReportConfigurationSummary = (props: BlankNoteReportConfigurationSummaryProps) => {
  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Blank note report</Heading>

          <SimpleGrid columns={2} spacing={4}>
            <DataField title="Start date">
              <Text>
                <FormattedDate value={props.startDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            <DataField title="End date">
              <Text>
                <FormattedDate value={props.endDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            {props.supplierFilter ? (
              <React.Fragment>
                <DataField title="From supplier">
                  <Text>{props.supplierFilter.from.name}</Text>
                </DataField>

                <DataField title="To supplier">
                  <Text>{props.supplierFilter.to.name}</Text>
                </DataField>
              </React.Fragment>
            ) : null}

            {props.collectionNoteIDRangeFilter ? (
              <React.Fragment>
                <DataField title="From Note ID">
                  <Text>{props.collectionNoteIDRangeFilter.fromID}</Text>
                </DataField>

                <DataField title="To Note ID">
                  <Text>{props.collectionNoteIDRangeFilter.toID}</Text>
                </DataField>
              </React.Fragment>
            ) : null}
          </SimpleGrid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            <Button type="button" colorScheme="gray" onClick={props.onBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={props.isGeneratingReport}
              onClick={props.onGenerateReport}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

interface NotesReportConfigurationProps {
  service: NotesReportActor;
  onClose: () => void;
}

const NotesReportConfiguration = (props: NotesReportConfigurationProps) => {
  const [state, send] = useActor(props.service);

  switch (true) {
    case state.matches("configuringDateRange"):
      return (
        <DateRangeForm
          initialValues={{
            startDate: format(state.context.dateRange.startDate, "yyyy-LL-dd"),
            endDate: format(state.context.dateRange.endDate, "yyyy-LL-dd"),
          }}
          onSubmit={(values) => {
            send(notesReportModel.events.provideReportDates(values));
          }}
        />
      );

    case state.matches("configuringSupplierFilter"):
      return (
        <SupplierFilterForm
          initialValues={{
            fromID: state.context.supplierFilter?.from.internalId,
            toID: state.context.supplierFilter?.to.internalId,
          }}
          onSubmit={(values) => {
            send(notesReportModel.events.provideSupplierFilter(values));
          }}
          onSkip={() => {
            send(notesReportModel.events.skipStep());
          }}
          onBack={() => {
            send(notesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("configuringNoteIDRange"):
      return (
        <CollectionNoteIDFilterForm
          initialValues={{
            fromID: state.context.collectionNoteIDRangeFilter?.fromID.toString(),
            toID: state.context.collectionNoteIDRangeFilter?.toID.toString(),
          }}
          onSubmit={(values) => {
            send(notesReportModel.events.provideCollectionNoteIDRangeFilter(values));
          }}
          onSkip={() => {
            send(notesReportModel.events.skipStep());
          }}
          onBack={() => {
            send(notesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("configuringAdditionalFilters"):
      return (
        <NotesReportAdditionalFiltersForm
          initialValues={{
            includeCollectionNoteValues: state.context.includeCollectionNoteValues,
            includeInvoicedCollectionNotes: state.context.includeInvoicedCollectionNotes,
          }}
          onSubmit={(values) => {
            send(notesReportModel.events.provideAdditionalFilterSettings(values));
          }}
          onBack={() => {
            send(notesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("showingReportConfigSummary"):
      return (
        <NotesReportConfigurationSummary
          isGeneratingReport={state.matches({ showingReportConfigSummary: "generatingReport" })}
          supplierFilter={state.context.supplierFilter}
          collectionNoteIDRangeFilter={state.context.collectionNoteIDRangeFilter}
          startDate={state.context.dateRange.startDate}
          endDate={state.context.dateRange.endDate}
          includeCollectionNoteValues={state.context.includeCollectionNoteValues}
          includeInvoicedCollectionNotes={state.context.includeInvoicedCollectionNotes}
          onGenerateReport={() => {
            send(notesReportModel.events.generateReport());
          }}
          onBack={() => {
            send(blankNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("reportGenerated"):
      return <ReportGenerated onClose={props.onClose} />;

    default:
      return null;
  }
};

const NotesReportAdditionalFiltersFormSchema = yup.object().shape({
  includeCollectionNoteValues: yup.boolean().required(),
  includeInvoicedCollectionNotes: yup.boolean().required(),
});

interface NotesReportAdditionalFiltersFormValues {
  includeCollectionNoteValues: boolean;
  includeInvoicedCollectionNotes: boolean;
}

interface NotesReportAdditionalFiltersFormProps {
  initialValues: NotesReportAdditionalFiltersFormValues;
  onSubmit: (values: NotesReportAdditionalFiltersFormValues) => void;
  onBack: () => void;
}

const NotesReportAdditionalFiltersForm = (props: NotesReportAdditionalFiltersFormProps) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={NotesReportAdditionalFiltersFormSchema}
      onSubmit={props.onSubmit}
    >
      {() => {
        return (
          <Form>
            <ModalBody>
              <Stack spacing={4}>
                <Box>
                  <CheckboxField name="includeCollectionNoteValues" label="Include values" />
                </Box>

                <Box>
                  <CheckboxField
                    name="includeInvoicedCollectionNotes"
                    label="Include invoiced collection notes"
                  />
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <ButtonGroup>
                  <Button type="button" colorScheme="gray" onClick={props.onBack}>
                    Back
                  </Button>
                </ButtonGroup>

                <ButtonGroup>
                  <Button type="submit" colorScheme="blue">
                    Next
                  </Button>
                </ButtonGroup>
              </Flex>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

interface NotesReportConfigurationSummaryProps {
  isGeneratingReport: boolean;
  startDate: Date;
  endDate: Date;
  supplierFilter?: SupplierFilter;
  collectionNoteIDRangeFilter?: CollectionNoteIDRangeFilter;
  includeCollectionNoteValues: boolean;
  includeInvoicedCollectionNotes: boolean;
  onGenerateReport: () => void;
  onBack: () => void;
}

const NotesReportConfigurationSummary = (props: NotesReportConfigurationSummaryProps) => {
  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Note report</Heading>

          <SimpleGrid columns={2} spacing={4}>
            <DataField title="Start date">
              <Text>
                <FormattedDate value={props.startDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            <DataField title="End date">
              <Text>
                <FormattedDate value={props.endDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            {props.supplierFilter ? (
              <React.Fragment>
                <DataField title="From supplier">
                  <Text>{props.supplierFilter.from.name}</Text>
                </DataField>

                <DataField title="To supplier">
                  <Text>{props.supplierFilter.to.name}</Text>
                </DataField>
              </React.Fragment>
            ) : null}

            {props.collectionNoteIDRangeFilter ? (
              <React.Fragment>
                <DataField title="From Note ID">
                  <Text>{props.collectionNoteIDRangeFilter.fromID}</Text>
                </DataField>

                <DataField title="To Note ID">
                  <Text>{props.collectionNoteIDRangeFilter.toID}</Text>
                </DataField>
              </React.Fragment>
            ) : null}

            <DataField title="Include values">
              <Status active={props.includeCollectionNoteValues} />
            </DataField>

            <DataField title="Include invoiced collection notes">
              <Status active={props.includeInvoicedCollectionNotes} />
            </DataField>
          </SimpleGrid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            <Button type="button" colorScheme="gray" onClick={props.onBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={props.isGeneratingReport}
              onClick={props.onGenerateReport}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

interface DeliveryNoteReportConfigurationProps {
  service: DeliveryNotesReportActor;
  onClose: () => void;
}

const DeliveryNoteReportConfiguration = (props: DeliveryNoteReportConfigurationProps) => {
  const [state, send] = useActor(props.service);

  switch (true) {
    case state.matches("configuringDateRange"):
      return (
        <DateRangeForm
          initialValues={{
            startDate: format(state.context.dateRange.startDate, "yyyy-LL-dd"),
            endDate: format(state.context.dateRange.endDate, "yyyy-LL-dd"),
          }}
          onSubmit={(values) => {
            send(deliveryNotesReportModel.events.provideReportDates(values));
          }}
        />
      );

    case state.matches("configuringSupplierFilter"):
      return (
        <SupplierFilterForm
          initialValues={{
            fromID: state.context.supplierFilter?.from.internalId,
            toID: state.context.supplierFilter?.to.internalId,
          }}
          onSubmit={(values) => {
            send(deliveryNotesReportModel.events.provideSupplierFilter(values));
          }}
          onSkip={() => {
            send(deliveryNotesReportModel.events.skipStep());
          }}
          onBack={() => {
            send(deliveryNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("showingReportConfigSummary"):
      return (
        <DeliveryNoteReportConfigurationSummary
          isGeneratingReport={state.matches({ showingReportConfigSummary: "generatingReport" })}
          startDate={state.context.dateRange.startDate}
          endDate={state.context.dateRange.endDate}
          supplierFilter={state.context.supplierFilter}
          onGenerateReport={() => {
            send(deliveryNotesReportModel.events.generateReport());
          }}
          onBack={() => {
            send(deliveryNotesReportModel.events.goBack());
          }}
        />
      );

    case state.matches("reportGenerated"):
      return <ReportGenerated onClose={props.onClose} />;

    default:
      return null;
  }
};

interface DeliveryNoteReportConfigurationSummaryProps {
  isGeneratingReport: boolean;
  startDate: Date;
  endDate: Date;
  supplierFilter?: SupplierFilter;
  onGenerateReport: () => void;
  onBack: () => void;
}

const DeliveryNoteReportConfigurationSummary = (
  props: DeliveryNoteReportConfigurationSummaryProps
) => {
  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Delivery note report</Heading>

          <SimpleGrid columns={2} spacing={4}>
            <DataField title="Start date">
              <Text>
                <FormattedDate value={props.startDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            <DataField title="End date">
              <Text>
                <FormattedDate value={props.endDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            {props.supplierFilter ? (
              <React.Fragment>
                <DataField title="From supplier">
                  <Text>{props.supplierFilter.from.name}</Text>
                </DataField>

                <DataField title="To supplier">
                  <Text>{props.supplierFilter.to.name}</Text>
                </DataField>
              </React.Fragment>
            ) : null}
          </SimpleGrid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            <Button type="button" colorScheme="gray" onClick={props.onBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={props.isGeneratingReport}
              onClick={props.onGenerateReport}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

interface SupplierReengagementReportConfigurationProps {
  service: SupplierReengagementReportActor;
  onClose: () => void;
}

const SupplierReengagementReportConfiguration = (
  props: SupplierReengagementReportConfigurationProps
) => {
  const [state, send] = useActor(props.service);

  switch (true) {
    case state.matches("configuringDateRange"):
      return (
        <DateRangeForm
          initialValues={{
            startDate: format(state.context.dateRange.startDate, "yyyy-LL-dd"),
            endDate: format(state.context.dateRange.endDate, "yyyy-LL-dd"),
          }}
          onSubmit={(values) => {
            send(supplierReengagementReportModel.events.provideReportDates(values));
          }}
        />
      );

    case state.matches("showingReportConfigSummary"):
      return (
        <SupplierReengagementReportConfigurationSummary
          isGeneratingReport={state.matches({ showingReportConfigSummary: "generatingReport" })}
          startDate={state.context.dateRange.startDate}
          endDate={state.context.dateRange.endDate}
          onGenerateReport={() => {
            send(supplierReengagementReportModel.events.generateReport());
          }}
          onBack={() => {
            send(supplierReengagementReportModel.events.goBack());
          }}
        />
      );

    case state.matches("reportGenerated"):
      return <ReportGenerated onClose={props.onClose} />;

    default:
      return null;
  }
};

interface SupplierReengagementReportConfigurationSummaryProps {
  isGeneratingReport: boolean;
  startDate: Date;
  endDate: Date;
  onGenerateReport: () => void;
  onBack: () => void;
}

const SupplierReengagementReportConfigurationSummary = (
  props: SupplierReengagementReportConfigurationSummaryProps
) => {
  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Supplier re-engagement report</Heading>

          <SimpleGrid columns={2} spacing={4}>
            <DataField title="Start date">
              <Text>
                <FormattedDate value={props.startDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>

            <DataField title="End date">
              <Text>
                <FormattedDate value={props.endDate} year="numeric" month="long" day="2-digit" />
              </Text>
            </DataField>
          </SimpleGrid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            <Button type="button" colorScheme="gray" onClick={props.onBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={props.isGeneratingReport}
              onClick={props.onGenerateReport}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

interface InvoiceReportConfigurationProps {
  service: InvoiceReportActor;
  onClose: () => void;
}

const InvoiceReportConfiguration = (props: InvoiceReportConfigurationProps) => {
  const [state, send] = useActor(props.service);

  switch (true) {
    case state.matches("configuringSupplierFilter"):
      return (
        <SupplierFilterForm
          initialValues={{
            fromID: state.context.supplierFilter?.from.internalId,
            toID: state.context.supplierFilter?.to.internalId,
          }}
          onSubmit={(values) => {
            send(invoiceReportModel.events.provideSupplierFilter(values));
          }}
        />
      );

    case state.matches("configuringFileReferenceFilter"):
      return (
        <FileReferenceFilterForm
          initialValues={{
            from: state.context.fileReference?.from,
            to: state.context.fileReference?.to,
          }}
          onSubmit={(values) => {
            send(invoiceReportModel.events.provideFileReferenceFilter(values));
          }}
          onBack={() => {
            send(invoiceReportModel.events.goBack());
          }}
        />
      );

    case state.matches("showingReportConfigSummary"):
      return (
        <InvoiceReportConfigurationSummary
          isGeneratingReport={state.matches({ showingReportConfigSummary: "generatingReport" })}
          supplierFilter={state.context.supplierFilter}
          fileReferenceFilter={state.context.fileReference}
          onGenerateReport={() => {
            send(invoiceReportModel.events.generateReport());
          }}
          onBack={() => {
            send(invoiceReportModel.events.goBack());
          }}
        />
      );

    case state.matches("reportGenerated"):
      return <ReportGenerated onClose={props.onClose} />;

    default:
      return null;
  }
};

interface InvoiceReportConfigurationSummaryProps {
  isGeneratingReport: boolean;
  supplierFilter?: SupplierFilter;
  fileReferenceFilter?: FileReferenceFilter;
  onGenerateReport: () => void;
  onBack: () => void;
}

const InvoiceReportConfigurationSummary = (props: InvoiceReportConfigurationSummaryProps) => {
  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Invoice report</Heading>

          <SimpleGrid columns={2} spacing={4}>
            {props.supplierFilter ? (
              <React.Fragment>
                <DataField title="From supplier">
                  <Text>{props.supplierFilter.from.name}</Text>
                </DataField>

                <DataField title="To supplier">
                  <Text>{props.supplierFilter.to.name}</Text>
                </DataField>
              </React.Fragment>
            ) : null}

            {props.fileReferenceFilter ? (
              <React.Fragment>
                <DataField title="From file reference">
                  <Text>{props.fileReferenceFilter.from}</Text>
                </DataField>

                <DataField title="To file reference">
                  <Text>{props.fileReferenceFilter.to}</Text>
                </DataField>
              </React.Fragment>
            ) : null}
          </SimpleGrid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            <Button type="button" colorScheme="gray" onClick={props.onBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={props.isGeneratingReport}
              onClick={props.onGenerateReport}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

interface OutstandingInvoiceReportConfigurationSummaryProps {
  service: OutstandingInvoiceReportActor;
}

const OutstandingInvoiceReportConfigurationSummary = (
  props: OutstandingInvoiceReportConfigurationSummaryProps
) => {
  const [state, send] = useActor(props.service);

  console.log(state);

  const isGeneratingReport = state.matches({ showingReportConfigSummary: "generatingReport" });

  return (
    <React.Fragment>
      <ModalBody>
        <Stack spacing={6} pb={6}>
          <Heading fontSize="md">Outstanding invoice report</Heading>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup></ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              colorScheme="teal"
              isLoading={isGeneratingReport}
              onClick={() => {
                send(outstandingInvoiceReportModel.events.generateReport());
              }}
            >
              Generate
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};

const DateRangeFormSchema = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required().min(yup.ref("startDate"), "end date can't be before start date"),
});

interface DateRangeFormValues {
  startDate: string;
  endDate: string;
}

interface DateRangeFormResponse {
  startDate: Date;
  endDate: Date;
}

interface DateRangeFormProps {
  initialValues: DateRangeFormValues;
  onSubmit: (values: DateRangeFormResponse) => void;
}

const DateRangeForm = (props: DateRangeFormProps) => {
  return (
    <Formik
      initialValues={{
        startDate: format(new Date(), "yyyy-LL-dd"),
        endDate: format(new Date(), "yyyy-LL-dd"),
      }}
      validationSchema={DateRangeFormSchema}
      onSubmit={async (values) => {
        const formattedValues: DateRangeFormResponse = {
          startDate: startOfDay(parseISO(values.startDate)),
          endDate: endOfDay(parseISO(values.endDate)),
        };

        props.onSubmit(formattedValues);
      }}
    >
      {() => {
        return (
          <Form>
            <ModalBody>
              <Heading size="sm" pb={5}>
                Date range
              </Heading>

              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <DatePickerField name="startDate" label="Start date" />
                </Box>

                <Box>
                  <DatePickerField name="endDate" label="End date" />
                </Box>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" colorScheme="blue">
                Next
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export const SUPPLIER_FILTER_FORM_GET_SUPPLIERS = gql`
  query SupplierFilterFormGetSuppliers($input: ListSupplierInput!) {
    suppliers(input: $input) {
      id
      name
      internalId
    }
  }
`;

const SupplierFilterFormSchema = yup.object().shape({
  fromID: yup.string().required(),
  toID: yup.string().required(),
});

interface SupplierFilterFormValues {
  fromID?: number;
  toID?: number;
}

interface SupplierFilterFormResponse {
  from: SelectedSupplier;
  to: SelectedSupplier;
}

interface SupplierFilterFormProps {
  initialValues: SupplierFilterFormValues;
  onSubmit: (values: SupplierFilterFormResponse) => void;
  onSkip?: () => void;
  onBack?: () => void;
}

const SupplierFilterForm = (props: SupplierFilterFormProps) => {
  const { loading, error, data } = useSupplierFilterFormGetSuppliersQuery({
    variables: {
      input: {
        offset: 0,
        filters: [],
      },
    },
  });

  const idToSupplierMap = React.useMemo(() => {
    if (!data?.suppliers) {
      return {};
    }

    return data.suppliers.reduce<{ [key: number]: SelectedSupplier }>((acc, supplier) => {
      acc[supplier.internalId] = supplier;
      return acc;
    }, {});
  }, [data?.suppliers]);

  const header = (
    <Heading size="sm" pb={5}>
      Supplier filter
    </Heading>
  );

  const loadingButtons = (
    <React.Fragment>
      <ModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup>
            {props.onBack ? (
              <Button type="button" colorScheme="gray" onClick={props.onBack}>
                Back
              </Button>
            ) : null}
          </ButtonGroup>

          <ButtonGroup>
            {props.onSkip ? (
              <Button type="button" colorScheme="gray" onClick={props.onSkip}>
                Skip
              </Button>
            ) : null}
            <Button type="button" colorScheme="blue" isDisabled>
              Next
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );

  if (loading || error) {
    return (
      <React.Fragment>
        <ModalBody>
          {header}
          {loading ? <PageSpinner /> : error ? <Text>{error.message}</Text> : null}
        </ModalBody>
        {loadingButtons}
      </React.Fragment>
    );
  }

  if (!data || data?.suppliers.length === 0) {
    return (
      <React.Fragment>
        <ModalBody>
          {header}
          <Text>No suppliers found</Text>
        </ModalBody>
        {loadingButtons}
      </React.Fragment>
    );
  }

  const firstSupplier = data.suppliers[0];
  const lastSupplier = data.suppliers[data.suppliers.length - 1];

  return (
    <Formik
      initialValues={{
        fromID: firstSupplier.internalId,
        toID: lastSupplier.internalId,
      }}
      validationSchema={SupplierFilterFormSchema}
      onSubmit={(values) => {
        const fromSupplier = idToSupplierMap[values.fromID];
        const toSupplier = idToSupplierMap[values.toID];

        props.onSubmit({ from: fromSupplier, to: toSupplier });
      }}
    >
      {() => {
        return (
          <Form>
            <ModalBody>
              {header}
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>From</FormLabel>
                  <SelectField name="fromID">
                    {data.suppliers.map((supplier) => {
                      return (
                        <option key={supplier.internalId} value={supplier.internalId}>
                          {supplier.name}
                        </option>
                      );
                    })}
                  </SelectField>
                </Box>

                <Box>
                  <FormLabel>To</FormLabel>
                  <SelectField name="toID">
                    {data.suppliers.map((supplier) => {
                      return (
                        <option key={supplier.internalId} value={supplier.internalId}>
                          {supplier.name}
                        </option>
                      );
                    })}
                  </SelectField>
                </Box>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <ButtonGroup>
                  {props.onBack ? (
                    <Button type="button" colorScheme="gray" onClick={props.onBack}>
                      Back
                    </Button>
                  ) : null}
                </ButtonGroup>

                <ButtonGroup>
                  {props.onSkip ? (
                    <Button type="button" colorScheme="gray" onClick={props.onSkip}>
                      Skip
                    </Button>
                  ) : null}
                  <Button type="submit" colorScheme="blue">
                    Next
                  </Button>
                </ButtonGroup>
              </Flex>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

const CollectionNoteIDFilterFormSchema = yup.object().shape({
  fromID: yup.number().required(),
  toID: yup.number().required(),
});

interface CollectionNoteIDFilterFormValues {
  fromID?: string;
  toID?: string;
}

interface CollectionNoteIDFilterFormResponse {
  fromID: number;
  toID: number;
}

interface CollectionNoteIDFilterFormProps {
  initialValues: CollectionNoteIDFilterFormValues;
  onSubmit: (values: CollectionNoteIDFilterFormResponse) => void;
  onSkip: () => void;
  onBack: () => void;
}

const CollectionNoteIDFilterForm = (props: CollectionNoteIDFilterFormProps) => {
  return (
    <Formik
      initialValues={{
        fromID: props.initialValues.fromID ?? "",
        toID: props.initialValues.toID ?? "",
      }}
      validationSchema={CollectionNoteIDFilterFormSchema}
      onSubmit={(values) => {
        props.onSubmit({ fromID: parseInt(values.fromID, 10), toID: parseInt(values.toID, 10) });
      }}
    >
      {() => {
        return (
          <Form>
            <ModalBody>
              <Heading size="sm" pb={5}>
                Collection Note filter
              </Heading>
              <SimpleGrid columns={2} spacing={4}>
                <TextField name="fromID" label="From" />
                <TextField name="toID" label="To" />
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <ButtonGroup>
                  <Button type="button" colorScheme="gray" onClick={props.onBack}>
                    Back
                  </Button>
                </ButtonGroup>

                <ButtonGroup>
                  <Button type="button" colorScheme="gray" onClick={props.onSkip}>
                    Skip
                  </Button>
                  <Button type="submit" colorScheme="blue">
                    Next
                  </Button>
                </ButtonGroup>
              </Flex>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

const FileReferenceFilterFormSchema = yup.object().shape({
  from: yup.string().required(),
  to: yup.string().required(),
});

interface FileReferenceFilterFormValues {
  from?: string;
  to?: string;
}

interface FileReferenceFilterFormResponse {
  from: string;
  to: string;
}

interface FileReferenceFilterFormProps {
  initialValues: FileReferenceFilterFormValues;
  onSubmit: (values: FileReferenceFilterFormResponse) => void;
  onBack: () => void;
}

const FileReferenceFilterForm = (props: FileReferenceFilterFormProps) => {
  return (
    <Formik
      initialValues={{
        from: props.initialValues.from ?? "",
        to: props.initialValues.to ?? "",
      }}
      validationSchema={FileReferenceFilterFormSchema}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
    >
      {() => {
        return (
          <Form>
            <ModalBody>
              <Heading size="sm" pb={5}>
                File reference
              </Heading>
              <SimpleGrid columns={2} spacing={4}>
                <TextField name="from" label="From" />
                <TextField name="to" label="To" />
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <ButtonGroup>
                  <Button type="button" colorScheme="gray" onClick={props.onBack}>
                    Back
                  </Button>
                </ButtonGroup>

                <ButtonGroup>
                  <Button type="submit" colorScheme="blue">
                    Next
                  </Button>
                </ButtonGroup>
              </Flex>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

interface ReportGeneratedProps {
  onClose: () => void;
}

const ReportGenerated = (props: ReportGeneratedProps) => {
  return (
    <React.Fragment>
      <ModalBody>Your report will be downloaded shortly.</ModalBody>
      <ModalFooter>
        <Button colorScheme="teal" onClick={props.onClose}>
          Close
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
};
