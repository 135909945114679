import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { SupplierAddressForm } from "../../components/SupplierAddressForm";
import { buildAddressPagePath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useAddressEditGetSupplierAddressQuery,
  useAddressEditUpdateSupplierAddressMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const ADDRESS_PAGE_GET_SUPPLIER_ADDRESS = gql`
  query AddressEditGetSupplierAddress($addressId: String!) {
    supplierAddress(where: { id: $addressId }) {
      id
      type
      siteName
      building
      street
      district
      town
      county
      postalCode
      country
      notes
    }
  }
`;

export const UPDATE_SUPPLIER_ADDRESS = gql`
  mutation AddressEditUpdateSupplierAddress($input: UpdateSupplierAddressInput!) {
    updateSupplierAddress(input: $input) {
      id
    }
  }
`;

export const AddressEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);
  const addressId = useSingleParam(RouteParams.AddressId, { required: true });

  const [updateAddress] = useAddressEditUpdateSupplierAddressMutation();

  const { loading, error, data } = useAddressEditGetSupplierAddressQuery({
    variables: { addressId },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the address</Text>
      </Stack>
    );
  }

  if (!data || !data.supplierAddress) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { supplierAddress } = data;

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Address</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>Edit Address</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <SupplierAddressForm
              initialValues={{
                type: supplierAddress.type,
                siteName: supplierAddress.siteName ?? "",
                building: supplierAddress.building ?? "",
                street: supplierAddress.street ?? "",
                district: supplierAddress.district ?? "",
                town: supplierAddress.town ?? "",
                county: supplierAddress.county ?? "",
                postalCode: supplierAddress.postalCode ?? "",
                country: supplierAddress.country ?? "",
                notes: supplierAddress.notes ?? "",
              }}
              onSubmit={async (values) => {
                const { data } = await updateAddress({
                  variables: {
                    input: {
                      ...values,
                      supplierAddressId: addressId,
                    },
                  },
                });

                if (data?.updateSupplierAddress?.id) {
                  navigate(buildAddressPagePath(supplierSlug, data.updateSupplierAddress.id));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
