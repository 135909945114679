import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo, useRef } from "react";
import * as yup from "yup";

import { SupplierAddressTypeEnum } from "../generated/graphql";
import { SelectField } from "./form/Select";
import { TextareaField } from "./form/TextareaField";
import { TextField } from "./form/TextField";

const SupplierAddressFormSchema = yup.object().shape({
  type: yup.string().oneOf([SupplierAddressTypeEnum.Invoice, SupplierAddressTypeEnum.Collection]),
  siteName: yup.string(),
  building: yup.string(),
  street: yup.string(),
  district: yup.string(),
  town: yup.string(),
  county: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
});

interface FormValues {
  type: SupplierAddressTypeEnum;
  siteName: string;
  building: string;
  street: string;
  district: string;
  town: string;
  county: string;
  postalCode: string;
  country: string;
  notes: string;
}

interface SupplierAddressFormProps {
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const SupplierAddressForm: React.FC<SupplierAddressFormProps> = (props) => {
  const initialValues = useMemo(() => {
    return {
      type: SupplierAddressTypeEnum.Invoice,
      siteName: "",
      building: "",
      street: "",
      district: "",
      town: "",
      county: "",
      postalCode: "",
      country: "",
      notes: "",
      ...props.initialValues,
    };
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SupplierAddressFormSchema}
      onSubmit={async (values) => {
        await props.onSubmit(values);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <SelectField name="type" label="Type">
                  <option
                    key={SupplierAddressTypeEnum.Invoice}
                    value={SupplierAddressTypeEnum.Invoice}
                  >
                    {SupplierAddressTypeEnum.Invoice}
                  </option>
                  <option
                    key={SupplierAddressTypeEnum.Collection}
                    value={SupplierAddressTypeEnum.Collection}
                  >
                    {SupplierAddressTypeEnum.Collection}
                  </option>
                </SelectField>
              </Box>

              <Box>
                <TextField name="siteName" label="Site Name" />
              </Box>

              <Box>
                <TextField name="building" label="Building" />
              </Box>

              <Box>
                <TextField name="street" label="Street" />
              </Box>

              <Box>
                <TextField name="district" label="District" />
              </Box>

              <Box>
                <TextField name="town" label="Town" />
              </Box>

              <Box>
                <TextField name="county" label="County" />
              </Box>

              <Box>
                <TextField name="postalCode" label="Post Code" />
              </Box>

              <Box>
                <TextField name="country" label="Country" />
              </Box>
            </Grid>

            <Box>
              <TextareaField name="notes" label="Notes" />
            </Box>

            <Box>
              {values.type === SupplierAddressTypeEnum.Invoice ? (
                <ReplaceInvoiceAddressConfirmationButton isSubmitting={isSubmitting} />
              ) : (
                <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                  Submit
                </Button>
              )}
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

interface ReplaceInvoiceAddressConfirmationButtonProps {
  isSubmitting: boolean;
}

const ReplaceInvoiceAddressConfirmationButton = (
  props: ReplaceInvoiceAddressConfirmationButtonProps
) => {
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover initialFocusRef={initialFocusRef}>
      <PopoverTrigger>
        <Button type="button" colorScheme="blue" disabled={props.isSubmitting}>
          Submit
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Replace this supplier's invoice address?
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverFooter>
          <ButtonGroup size="sm" w="100%">
            <Button type="submit" colorScheme="red" disabled={props.isSubmitting} w="100%">
              Replace invoice address
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
