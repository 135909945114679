import { Link, LinkProps } from "@chakra-ui/react";
import React from "react";
import { Link as RRLink } from "react-router-dom";

interface StyledLinkProps extends Omit<LinkProps, "href"> {
  to: string;
}

export const StyledLink: React.FC<StyledLinkProps> = (props) => {
  return (
    <Link
      as={RRLink}
      transition="none"
      color="teal.500"
      _hover={{
        color: "teal.700",
      }}
      {...props}
    />
  );
};
