import { Badge, BadgeProps } from "@chakra-ui/react";
import * as React from "react";

interface StatusProps {
  active: boolean;
}

export const Status: React.FC<StatusProps> = (props) => {
  const badgeProps: BadgeProps = {
    borderRadius: "full",
    px: 2,
    py: 1,
    textAlign: "center",
    colorScheme: props.active ? "green" : "orange",
    children: props.children ?? (props.active ? "Yes" : "No"),
  };

  return <Badge {...badgeProps} />;
};
