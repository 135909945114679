import { gql } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import currency from "currency.js";
import { Fragment, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { DataField } from "../../components/DataField";
import { FinancialFigure } from "../../components/FinancialFigure";
import { Link as LinkIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { DateCell, FinancialFigureCell, Table } from "../../components/Table";
import {
  buildCollectionNotePagePath,
  buildInvoicePagePath,
  buildSupplierRootPath,
  RouteParams,
} from "../../config/routes";
import { useSupplier } from "../../context/SupplierContext";
import {
  CollectionNoteLine,
  Currency,
  useGetInvoiceCollectionNoteMatchQuery,
  useMatchInvoiceToCollectionNoteLinesMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const INVOICE_COLLECTION_NOTE_PAGE_GET_INVOICE = gql`
  query GetInvoiceCollectionNoteMatch($supplierSlug: String!, $invoiceId: String!) {
    invoice(where: { id: $invoiceId }) {
      id
      reference
      net
      vat
      gross
    }
    collectionNoteLines(where: { supplierSlug: $supplierSlug, invoiced: false }) {
      id
      quantity
      description
      value
      vat
      total
      collectionNote {
        id
        referenceId
        creationDate
      }
    }
  }
`;

export const INVOICE_COLLECTION_NOTE_PAGE_MATCH_INVOICE = gql`
  mutation MatchInvoiceToCollectionNoteLines($input: MatchCollectionNotesToInvoiceInput!) {
    matchCollectionNotesToInvoice(input: $input) {
      id
      completed
    }
  }
`;

export const InvoiceCollectionNoteMatchPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const invoiceId = useSingleParam(RouteParams.InvoiceId, { required: true });

  const { supplier } = useSupplier();

  const { loading, error, data } = useGetInvoiceCollectionNoteMatchQuery({
    variables: { supplierSlug, invoiceId },
  });

  const [matchInvoiceToLines] = useMatchInvoiceToCollectionNoteLinesMutation({
    onCompleted: () => {
      toast({ title: "Invoice matched to Collection Note lines", status: "success" });
    },
  });

  const { toggle: toggleNoteSelected, isSelected: isNoteSelected } = useNoteSelection();

  const instanceProps = useMemo(() => {
    return {
      isNoteSelected,
      toggleNoteSelected,
    };
  }, [isNoteSelected, toggleNoteSelected]);

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the invoice</Text>
      </Stack>
    );
  }

  if (!data || !data.invoice) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  let { collectionNoteLines, invoice } = data;
  collectionNoteLines = collectionNoteLines ?? [];

  const selectedLines = collectionNoteLines
    .filter((line) => isNoteSelected(line.id))
    .map((line) => {
      return {
        id: line.id,
        value: line.value ?? 0,
        vat: line.vat ?? 0,
        total: line.total ?? 0,
      };
    });

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Invoice {invoice.reference} collection note match</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5} maxWidth="1000px">
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>
            Invoice{" "}
            <Text as="span" color="blue.500">
              {invoice.reference}
            </Text>
          </PageTitle>
        </Stack>

        <Panel height="65vh" overflowY="auto" data-testid="invoice-collection-note-match">
          <Table
            data-testid="collection-note-lines"
            data={collectionNoteLines}
            columns={collectionNoteLinesTableColumns}
            instanceProps={instanceProps}
          />
        </Panel>

        <Panel>
          <SelectedLinesSummary
            lines={selectedLines}
            currency={supplier.currency}
            onConfirm={async ({ markInvoiceAsComplete }) => {
              await matchInvoiceToLines({
                variables: {
                  input: {
                    invoiceId: invoiceId,
                    collectionNoteLineIds: selectedLines.map((line) => line.id),
                    markInvoiceAsComplete: markInvoiceAsComplete,
                  },
                },
              });

              navigate(buildInvoicePagePath(supplierSlug, invoiceId));
            }}
          />
        </Panel>
      </Stack>
    </Fragment>
  );
};

const useNoteSelection = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  return {
    selectedIds,
    toggle: (idToToggle: string) => {
      let ids = [];
      let found = false;

      for (let selectedId of selectedIds) {
        if (selectedId === idToToggle) {
          found = true;
          continue;
        }

        ids.push(selectedId);
      }

      if (!found) {
        ids.push(idToToggle);
      }

      setSelectedIds(ids);
    },
    isSelected: (id: string) => {
      return selectedIds.includes(id);
    },
  };
};

interface NoteSelectedCellProps {
  value: { id: string };
  isNoteSelected: (id: string) => boolean;
  toggleNoteSelected: (id: string) => void;
}

const collectionNoteLinesTableColumns = [
  {
    id: "checked",
    Header: "",
    accessor: (line: CollectionNoteLine) => {
      return {
        id: line.id,
      };
    },
    Cell: (props: NoteSelectedCellProps) => {
      return (
        <Checkbox
          isChecked={props.isNoteSelected(props.value.id)}
          onChange={() => {
            props.toggleNoteSelected(props.value.id);
          }}
        />
      );
    },
    width: "5%",
  },
  {
    id: "noteId",
    Header: "Note",
    accessor: (line: CollectionNoteLine) => {
      return {
        id: line.collectionNote.id,
        referenceId: line.collectionNote.referenceId,
      };
    },
    width: "10%",
    Cell: (props: { value: { id: string; referenceId: string } }) => {
      const supplierSlug = useSingleParam(RouteParams.SupplierSlug);

      return (
        <Box>
          <Text as="span">{props.value.referenceId}</Text>

          <Box display="inline" ml="2">
            <Link to={buildCollectionNotePagePath(supplierSlug, props.value.id)}>
              <LinkIcon display="inline" />
            </Link>
          </Box>
        </Box>
      );
    },
  },
  { Header: "Note created", accessor: "collectionNote.creationDate", Cell: DateCell, width: "15%" },
  { Header: "Net", accessor: "value", width: "5%", Cell: FinancialFigureCell },
  { Header: "VAT", accessor: "vat", width: "5%", Cell: FinancialFigureCell },
  { Header: "Gross", accessor: "total", width: "5%", Cell: FinancialFigureCell },
  { Header: "Quantity", accessor: "quantity", width: "20%" },
  { Header: "Description", accessor: "description", width: "35%" },
];

interface SelectedLinesSummaryProps {
  lines: {
    id: string;
    value: number;
    vat: number;
    total: number;
  }[];
  currency: Currency;
  onConfirm: (params: { markInvoiceAsComplete: boolean }) => {};
}

const SelectedLinesSummary = (props: SelectedLinesSummaryProps) => {
  const totals = useMemo(() => {
    let gross = currency(0);
    let vat = currency(0);
    let net = currency(0);

    props.lines.forEach((line) => {
      gross = gross.add(line.total);
      vat = vat.add(line.vat);
      net = net.add(line.value);
    });

    return {
      gross,
      vat,
      net,
    };
  }, [props.lines]);

  return (
    <Box p={6}>
      <Heading size="md" pb={4}>
        Summary
      </Heading>

      <Grid gridTemplateColumns="50% 50%">
        <Grid gridTemplateColumns="33% 33% 33%">
          <DataField title="Net">
            <FinancialFigure value={totals.net.value} currency={props.currency} />
          </DataField>

          <DataField title="VAT">
            <FinancialFigure value={totals.vat.value} currency={props.currency} />
          </DataField>

          <DataField title="Gross">
            <FinancialFigure value={totals.gross.value} currency={props.currency} />
          </DataField>
        </Grid>

        <Flex justifyContent="flex-end">
          <MatchConfirmationButton
            onConfirm={() => {
              props.onConfirm({ markInvoiceAsComplete: false });
            }}
            onConfirmAndComplete={() => {
              props.onConfirm({ markInvoiceAsComplete: true });
            }}
          />
        </Flex>
      </Grid>
    </Box>
  );
};

interface MatchConfirmationButtonProps {
  onConfirm: () => void;
  onConfirmAndComplete: () => void;
}

const MatchConfirmationButton = (props: MatchConfirmationButtonProps) => {
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover initialFocusRef={initialFocusRef}>
      <PopoverTrigger>
        <Button colorScheme="blue">Match to Invoice</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Mark this invoice as complete?
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button colorScheme="green" ref={initialFocusRef} onClick={props.onConfirmAndComplete}>
              Yes
            </Button>
            <Button colorScheme="blue" onClick={props.onConfirm}>
              No
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
