import { useParams } from "react-router-dom";

import { RouteParams } from "../config/routes";
import { UserFacingError } from "../errors/ApplicationErrors";

interface UseSingleParamOptions {
  required?: boolean;
  missingErrorMessage?: string;
}

export const useSingleParam = (param: RouteParams, options?: UseSingleParamOptions): string => {
  const params = useParams();

  const urlParam = (params as any)[param];

  if (!urlParam && options?.required) {
    throw new UserFacingError(
      options.missingErrorMessage ?? `Param ${param} is missing from the url`
    );
  }

  return urlParam;
};
