import { Box, Button, Grid, Stack } from "@chakra-ui/react";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { DatePickerField } from "./form/DatePickerField";
import { TextareaField } from "./form/TextareaField";
import { TextField } from "./form/TextField";

const QuoteFormSchema = yup.object().shape({
  quotedBy: yup.string().required(),
  contact: yup.string().required(),
  creationDate: yup.date().required(),
  notes: yup.string(),
});

interface FormValues {
  quotedBy: string;
  contact: string;
  creationDate: string;
  notes: string;
}

interface QuoteFormProps {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const QuoteForm: React.FC<QuoteFormProps> = (props) => {
  const initialValues = useMemo(() => {
    if (!props.initialValues) {
      return {
        quotedBy: "",
        contact: "",
        creationDate: format(new Date(), "yyyy-LL-dd"),
        notes: "",
      };
    }

    return {
      ...props.initialValues,
      creationDate: format(parseISO(props.initialValues.creationDate), "yyyy-LL-dd"),
    };
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={QuoteFormSchema}
      onSubmit={async (values) => {
        const formattedValues: FormValues = {
          ...values,
          creationDate: formatISO(parseISO(values.creationDate)),
        };

        await props.onSubmit(formattedValues);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Grid gridTemplateColumns="auto auto" gridRowGap={6} gap={6}>
              <Box>
                <TextField name="quotedBy" label="Quoted By" />
              </Box>

              <Box>
                <TextField name="contact" label="Contact" />
              </Box>

              <Box>
                <DatePickerField name="creationDate" label="Creation Date" />
              </Box>
            </Grid>

            <Box>
              <TextareaField name="notes" label="Notes" />
            </Box>

            <Box>
              <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
