import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, EyeIcon, PlusIcon } from "../../components/icons";
import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Pagination } from "../../components/Pagination";
import { Panel } from "../../components/Panel";
import { Table } from "../../components/Table";
import { buildNewAddressPath, RouteParams } from "../../config/routes";
import { useAddressListGetAddressesQuery } from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const ADDRESS_LIST_GET_ADDRESSES = gql`
  query AddressListGetAddresses($slug: String!, $offset: Int, $limit: Int) {
    supplierAddresses(
      where: { supplier: { slug: { equals: $slug } }, effectiveTo: { equals: null } }
      orderBy: [{ type: asc }, { siteName: asc }]
      skip: $offset
      take: $limit
    ) {
      id
      siteName
      type
      building
      street
      district
      town
      county
      postalCode
      country
    }

    supplierAddressCount(slug: $slug)
  }
`;

export const AddressListPage = () => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useAddressListGetAddressesQuery({
    variables: {
      slug: supplierSlug,

      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the addresses</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Address List</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Addresses</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildNewAddressPath(supplierSlug)}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new address
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="address-list"
            data={data?.supplierAddresses ?? []}
            columns={addressTableColumns}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.supplierAddressCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

const addressTableColumns = [
  { Header: "Site Name", accessor: "siteName" },
  { Header: "Type", accessor: "type" },
  { Header: "Building", accessor: "building" },
  { Header: "Street", accessor: "street" },
  { Header: "District", accessor: "district" },
  { Header: "Town", accessor: "town" },
  { Header: "County", accessor: "county" },
  { Header: "Post code", accessor: "postalCode" },
  { Header: "Country", accessor: "country" },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Stack direction="row">
          <Link to={props.value}>
            <IconButton aria-label="View address" icon={<EyeIcon />} />
          </Link>

          <Link to={`${props.value}/edit`}>
            <IconButton colorScheme="green" aria-label="Edit address" icon={<EditIcon />} />
          </Link>
        </Stack>
      );
    },
  },
];
