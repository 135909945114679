import { AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";

import { authConfig } from "./auth/authConfig";

export const msalInstance = new PublicClientApplication(authConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  const payload = event.payload as AuthenticationResult;
  if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const AppProviders: React.FC = ({ children }) => {
  return (
    <IntlProvider locale="en-GB" defaultLocale="en-GB">
      <HelmetProvider>
        <ChakraProvider>
          <Router>
            <MsalProvider instance={msalInstance}>{children}</MsalProvider>
          </Router>
        </ChakraProvider>
      </HelmetProvider>
    </IntlProvider>
  );
};
