import { gql } from "@apollo/client";
import { Button, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { EditIcon, PlusIcon } from "../../../components/icons";
import { PageTitle } from "../../../components/Page";
import { PageSpinner } from "../../../components/PageSpinner";
import { Pagination } from "../../../components/Pagination";
import { Panel } from "../../../components/Panel";
import { StyledLink } from "../../../components/StyledLink";
import { Table } from "../../../components/Table";
import { buildAdminEditContainerPath, buildAdminNewContainerPath } from "../../../config/routes";
import {
  ContainerListGetContainersQuery,
  useContainerListGetContainersQuery,
} from "../../../generated/graphql";

export const CONTAINERS_LIST_GET_CONTAINERS = gql`
  query ContainerListGetContainers($offset: Int, $limit: Int) {
    containers(orderBy: { name: asc }, skip: $offset, take: $limit) {
      id
      name
      type
    }

    containerCount
  }
`;

export const ContainerListPage = () => {
  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  const { loading, error, data } = useContainerListGetContainersQuery({
    variables: {
      offset: (paginationData.currentPage - 1) * paginationData.pageSize,
      limit: paginationData.pageSize,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the containers</Text>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>WMS | Containers List</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between" alignItems="center">
          <PageTitle>Containers</PageTitle>
          <ButtonGroup>
            <Button
              as={Link}
              to={buildAdminNewContainerPath()}
              colorScheme="blue"
              leftIcon={<PlusIcon />}
            >
              Create new container
            </Button>
          </ButtonGroup>
        </Stack>

        <Panel height="65vh" overflowY="auto">
          <Table
            data-testid="containers-list"
            data={data?.containers ?? []}
            columns={containerTableColumns}
          />
        </Panel>
        <Pagination
          currentPage={paginationData.currentPage}
          pageSize={paginationData.pageSize}
          totalCount={data?.containerCount ?? 1}
          onGoToPage={(pageNumber) => {
            setPaginationData({
              ...paginationData,
              currentPage: pageNumber,
            });
          }}
        />
      </Stack>
    </Fragment>
  );
};

type ContainerListPageContainer = ContainerListGetContainersQuery["containers"][0];

const NameAccessor = (container: ContainerListPageContainer) => {
  return {
    id: container.id,
    name: container.name,
  };
};

const containerTableColumns = [
  {
    Header: "Name",
    accessor: NameAccessor,
    Cell: (props: { value: ReturnType<typeof NameAccessor> }) => {
      const { id, name } = props.value;

      return <StyledLink to={buildAdminEditContainerPath(id)}>{name}</StyledLink>;
    },
  },
  { Header: "Type", accessor: "type" },
  {
    id: "actions",
    Header: "",
    accessor: "id",
    Cell: (props: { value: string }) => {
      return (
        <Link to={`${props.value}/edit`}>
          <IconButton colorScheme="green" aria-label="Edit container" icon={<EditIcon />} />
        </Link>
      );
    },
  },
];
