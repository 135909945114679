import { gql } from "@apollo/client";
import { ActorRefFrom } from "xstate";
import { createModel } from "xstate/lib/model";

import { graphQLClient } from "../../api/graphql";
import {
  CollectionNoteIDRangeFilter,
  openReportURL,
  SelectedSupplier,
  SupplierFilter,
} from "../generateReportConfig.machine";

export interface BlankNotesReportActor extends ActorRefFrom<typeof blankNotesReportMachine> {}

export const blankNotesReportModel = createModel(
  {
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
    supplierFilter: undefined as SupplierFilter | undefined,
    collectionNoteIDRangeFilter: undefined as CollectionNoteIDRangeFilter | undefined,
    reportURL: undefined as string | undefined,
    error: undefined as string | undefined,
  },
  {
    events: {
      provideReportDates: (value: { startDate: Date; endDate: Date }) => ({ data: value }),
      provideSupplierFilter: (value: { from: SelectedSupplier; to: SelectedSupplier }) => ({
        data: value,
      }),
      provideCollectionNoteIDRangeFilter: (value: { fromID: number; toID: number }) => ({
        data: value,
      }),
      skipStep: () => ({}),
      goBack: () => ({}),
      generateReport: () => ({}),
      reportGenerationSuccess: (value: string) => ({ data: { reportURL: value } }),
      reportGenerationFailure: (value: string) => ({ data: { error: value } }),
      restart: () => ({}),
    },
  }
);

export const blankNotesReportMachine = blankNotesReportModel.createMachine(
  {
    context: blankNotesReportModel.initialContext,
    initial: "configuringDateRange",
    states: {
      configuringDateRange: {
        on: {
          provideReportDates: {
            actions: blankNotesReportModel.assign({
              dateRange: (_, ev) => {
                return ev.data;
              },
            }),
            target: "configuringSupplierFilter",
          },
        },
      },
      configuringSupplierFilter: {
        on: {
          provideSupplierFilter: {
            actions: blankNotesReportModel.assign({
              supplierFilter: (_, ev) => {
                return ev.data;
              },
            }),
            target: "configuringNoteIDRange",
          },
          skipStep: {
            actions: blankNotesReportModel.assign({
              supplierFilter: () => undefined,
            }),
            target: "configuringNoteIDRange",
          },
          goBack: {
            target: "configuringDateRange",
          },
        },
      },
      configuringNoteIDRange: {
        id: "configuringNoteIDRange",
        on: {
          provideCollectionNoteIDRangeFilter: {
            actions: blankNotesReportModel.assign({
              collectionNoteIDRangeFilter: (_, ev) => {
                return { fromID: ev.data.fromID, toID: ev.data.toID };
              },
            }),
            target: "showingReportConfigSummary",
          },
          skipStep: {
            actions: blankNotesReportModel.assign({
              collectionNoteIDRangeFilter: () => undefined,
            }),
            target: "showingReportConfigSummary",
          },
          goBack: {
            target: "configuringSupplierFilter",
          },
        },
      },
      showingReportConfigSummary: {
        initial: "idle",
        states: {
          idle: {
            on: {
              generateReport: {
                target: "generatingReport",
              },
              goBack: {
                target: "#configuringNoteIDRange",
              },
            },
          },
          generatingReport: {
            invoke: {
              id: "generatingReport",
              src: "generateReport",
              onError: {
                target: "#failure",
              },
            },
            on: {
              reportGenerationSuccess: {
                target: "#reportGenerated",
                actions: blankNotesReportModel.assign({
                  reportURL: (_, ev) => ev.data.reportURL,
                }),
              },
              reportGenerationFailure: {
                target: "#failure",
                actions: blankNotesReportModel.assign({
                  error: (_, ev) => ev.data.error,
                }),
              },
            },
          },
        },
      },
      reportGenerated: {
        id: "reportGenerated",
        entry: "openReportURL",
      },
      failure: {
        id: "failure",
        on: {
          restart: {
            target: "configuringDateRange",
            actions: blankNotesReportModel.assign(() => {
              return blankNotesReportModel.initialContext;
            }),
          },
        },
      },
    },
  },
  {
    actions: {
      openReportURL: async (ctx) => {
        if (!ctx.reportURL) {
          throw new Error("Response does not contain a url");
        }

        openReportURL(ctx.reportURL);
      },
    },
    services: {
      generateReport: (ctx, ev) => (cb) => {
        graphQLClient
          .query({
            query: GENERATE_BLANK_NOTES_REPORT,
            variables: {
              input: {
                startDate: ctx.dateRange.startDate,
                endDate: ctx.dateRange.endDate,
                fromSupplierID: ctx.supplierFilter?.from.internalId,
                toSupplierID: ctx.supplierFilter?.to.internalId,
                fromCollectionNoteID: ctx.collectionNoteIDRangeFilter?.fromID,
                toCollectionNoteID: ctx.collectionNoteIDRangeFilter?.toID,
              },
            },
          })
          .then((res) => {
            const reportUrl = res.data.blankCollectionNotesReport.url;

            cb(blankNotesReportModel.events.reportGenerationSuccess(reportUrl));
          })
          .catch((e) => {
            cb(blankNotesReportModel.events.reportGenerationFailure(e.message));
          });
      },
    },
  }
);

const GENERATE_BLANK_NOTES_REPORT = gql`
  query GetBlankCollectionNotesReportInfo($input: BlankCollectionNotesReportInput!) {
    blankCollectionNotesReport(input: $input) {
      url
    }
  }
`;
