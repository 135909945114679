import { gql } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { EWCCodeForm } from "../../../components/EWCCodeForm";
import { PageTitle } from "../../../components/Page";
import { Panel } from "../../../components/Panel";
import { buildAdminEWCCodeListPath } from "../../../config/routes";
import { useEwcCodeCreationPageCreateEwcCodeMutation } from "../../../generated/graphql";

export const EWC_CODE_CREATION_PAGE_CREATE_EWC_CODE = gql`
  mutation EWCCodeCreationPageCreateEWCCode($input: CreateEWCCodeInput!) {
    createEWCCode(input: $input) {
      id
    }
  }
`;

export const EWCCodeCreationPage = () => {
  const navigate = useNavigate();

  const [createEWCCode] = useEwcCodeCreationPageCreateEwcCodeMutation({
    refetchQueries: ["GetEWCCodes"],
  });

  return (
    <Fragment>
      <Helmet>
        <title>WMS | New EWC Code</title>
      </Helmet>

      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>New EWC Code</PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <EWCCodeForm
              onSubmit={async (values) => {
                await createEWCCode({
                  variables: {
                    input: values,
                  },
                });

                navigate(buildAdminEWCCodeListPath());
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
