import { gql } from "@apollo/client";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import { PageTitle } from "../../components/Page";
import { PageSpinner } from "../../components/PageSpinner";
import { Panel } from "../../components/Panel";
import { QuoteForm } from "../../components/QuoteForm";
import { buildQuotePagePath, buildSupplierRootPath, RouteParams } from "../../config/routes";
import {
  useQuoteEditPageGetQuoteQuery,
  useQuoteEditPageUpdateQuoteMutation,
} from "../../generated/graphql";
import { useSingleParam } from "../../hooks/useSingleParam";

export const QUOTES_PAGE_GET_QUOTE = gql`
  query QuoteEditPageGetQuote($quoteId: String!) {
    quote(where: { id: $quoteId }) {
      id
      quoteReference
      contact
      quotedBy
      creationDate
      closed
      notes
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation QuoteEditPageUpdateQuote($input: UpdateQuoteInput!) {
    updateQuote(input: $input) {
      id
    }
  }
`;

export const QuoteEditPage = () => {
  const navigate = useNavigate();
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug);
  const quoteId = useSingleParam(RouteParams.QuoteId, { required: true });

  const [updateQuote] = useQuoteEditPageUpdateQuoteMutation();

  const { loading, error, data } = useQuoteEditPageGetQuoteQuery({ variables: { quoteId } });

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the quote</Text>
      </Stack>
    );
  }

  if (!data || !data.quote) {
    return <Navigate to={buildSupplierRootPath(supplierSlug)} />;
  }

  const { quote } = data;

  return (
    <Fragment>
      <Helmet>
        <title>{`WMS | Quote ${quote.quoteReference}`}</title>
      </Helmet>
      <Stack spacing={6} px={8} py={5}>
        <Stack width="100%" isInline justifyContent="space-between">
          <PageTitle>
            Edit Quote{" "}
            <Text as="span" color="blue.500">
              {quote.quoteReference}
            </Text>
          </PageTitle>
        </Stack>

        <Panel maxWidth="900px">
          <Box px={8} py={5}>
            <QuoteForm
              initialValues={{
                quotedBy: quote.quotedBy,
                contact: quote.contact,
                creationDate: quote.creationDate,
                notes: quote.notes,
              }}
              onSubmit={async (values) => {
                const { data } = await updateQuote({
                  variables: {
                    input: {
                      ...values,
                      quoteId,
                    },
                  },
                });

                if (data?.updateQuote?.id) {
                  navigate(buildQuotePagePath(supplierSlug, data.updateQuote.id));
                  return;
                }

                console.log("TODO: handle this");
              }}
            />
          </Box>
        </Panel>
      </Stack>
    </Fragment>
  );
};
