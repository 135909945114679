import { gql } from "@apollo/client";
import { Stack, Text } from "@chakra-ui/layout";
import * as React from "react";

import { PageSpinner } from "../components/PageSpinner";
import { RouteParams } from "../config/routes";
import { ApplicationError } from "../errors/ApplicationErrors";
import { Supplier, useAppGetSupplierQuery } from "../generated/graphql";
import { useSingleParam } from "../hooks/useSingleParam";

interface ContextValue {
  supplier: Pick<Supplier, "id" | "name" | "slug" | "vat" | "currency">;
}

const SupplierContext = React.createContext<ContextValue | undefined>(undefined);

export const APP_GET_SUPPLIER = gql`
  query AppGetSupplier($slug: String!) {
    supplier(where: { slug: $slug }) {
      id
      name
      slug
      vat
      currency
    }
  }
`;

export const SupplierProvider: React.FC = (props) => {
  const supplierSlug = useSingleParam(RouteParams.SupplierSlug, { required: true });
  const { loading, error, data } = useAppGetSupplierQuery({
    variables: {
      slug: supplierSlug,
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  if (error || !data || !data.supplier) {
    console.error(error);
    return (
      <Stack spacing={4}>
        <Text>Something went wrong loading the supplier</Text>
      </Stack>
    );
  }

  return (
    <SupplierContext.Provider value={{ supplier: data.supplier }}>
      {props.children}
    </SupplierContext.Provider>
  );
};

export const useSupplier = () => {
  const ctx = React.useContext(SupplierContext);

  if (typeof ctx === "undefined") {
    throw new ApplicationError("Attempted to use useSupplier outside of a SupplierContext");
  }

  return ctx;
};
